import React, {useEffect, useState} from 'react';
import DashboardPageComponent from "../../components/DashboardPageComponent";
import { dashboardActions } from "../../redux-utils/actions/dashboard-actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../custom-hooks/useAuth";
import { useLocation } from "react-router";

const { 
    allParticipantAction
} = dashboardActions;

function DashboardPages(props) {

    const location = useLocation();

    // const selectIsDataLoaded = (state) => !!state.data && state.data.length > 0;

    const { 
        allParticipantData, 
        allParticipantStatus,
        allParticipantDispatchAction,
        participantArchiveStatus,
        allParticipantArchiveData
    } = props;

    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());
    useEffect(() => {
        const allParam = {
            token: loginStorage && loginStorage.loginData.data.token
        }
        allParticipantDispatchAction(allParam);

    }, [])

    useEffect(() => {
  
    }, [participantArchiveStatus, allParticipantArchiveData])

    useEffect(() => {}, [allParticipantData])

    // participantArchiveStatus === "participant_archive_success_stop" ? allParticipantArchiveData : allParticipantData,

    const dashboardCompProps = {
        allParticipantData,
        allParticipantStatus
    }

    return (
        <>
            <DashboardPageComponent {...dashboardCompProps} />
        </>
    )
}

const mapStateToProps = state => ({
    allParticipantData: state.dashboardState.allParticipantData,
    allParticipantStatus: state.dashboardState.status,
    participantArchiveStatus: state.dashboardState.participantArchiveStatus,
    allParticipantArchiveData: state.dashboardState.allParticipantArchiveData,
})
  
const mapDispatchToProps = dispatch => ({
    allParticipantDispatchAction: (param) => dispatch(allParticipantAction(param))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPages);