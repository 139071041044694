import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Card from '../../../components/cards/ParticipantCard';
import PrintableParticipantCard from '../../../components/cards/PrintableCards/ParticipantCards';
import Pagination from '../../../components/Pagination';
import ViewParticipantInfo from '../../../components/modals/ViewParticipantInfo';
import EditParticipantInfo from '../../../components/modals/EditParticipantInfo';
import EmptyState from '../../../components/EmptyState';
import Loader from '../../../components/Loader';
import { jsonToCSV } from '../../../utils/json-to-csv';
import { exportCSV } from '../../../utils/export-csv';
import { connect, useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../redux-utils/actions/dashboard-actions';
import Swal from 'sweetalert2';
import { useAuth } from '../../../custom-hooks/useAuth';
import { encryptFormData } from '../../../utils/crypt-module/encrypt';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';

const { updateAction } = dashboardActions;

function Participants(props) {
    const {
        participantProps,
        participantUpdateStatus,
        participantUpdateDispatchAction,
        participantUpdateSuccessData,
    } = props;

    const {
        allParticipantData,
        allParticipantStatus,
        participantArchiveStatus,
        archiveParticipant,
        archiveType,
        setArchiveType,
        handleViewParticipantOpen,
        handleViewParticipantClose,
        viewParticipantOpen,
        selectedParticipant,
        handleEditParticipantOpen,
        handleEditParticipantClose,
        editParticipantOpen,
        setSearchTerm,
        searchTerm,
        setCurrentDataParticipant,
        handlePerPageChange,
        handlePageChange,
        totalPages,
        currentPage,
        itemsPerPage,
        currentDataParticipant,
        currentDataArchive,
        filteredParticipants,
        handleArchiveUpdate,
        t,
        // participantArchiveSuccessData,
    } = participantProps;

    const dispatch = useDispatch();

    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              return 0;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 500);
    
        return () => {
          clearInterval(timer);
        };
      }, []);

    const participantUpdateData = useSelector(
        (state) =>
            state.dashboardState.participantUpdateSuccessData
                .participantUpdateData
    );

    // --> This space is for edit form handling
    //handle input validations
    const editFormInputObject = {
        is_fee_paid: '',
        fee_amount: '',
        payment_date: '',
        agreement_signed: '',
        media_allowed: '',
        confirmation_email_sent: '',
        tent_information: '',
        tent_supervisor: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        father: '',
        mother: '',
        city: '',
        street: '',
        house_number: '',
        zip_code: '',
        landline: '',
        mobile: '',
        email: '',
        emergency_contact: '',
        fallback_number: '',
        friend_1: '',
        friend_2: '',
        siblings_in_same_tent: '',
        swimming_allowed: '',
        swimming_badge: '',
        swimming_capability: '',
        tools_allowed: '',
        vaccination_fsme: '',
        vaccination_fsme_year: '',
        vaccination_tetanus: '',
        vaccination_tetanus_year: '',
        tick_removal_allowed: '',
        allergies: '',
        vegetarian: '',
        food_intolerance: '',
        medication_allowed: '',
        medication: '',
        additional_details: '',
    };

    // USE STATES FROM EDIT PARTICIPANT INFO MODAL
    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());

    const [updatedParticipantInput, setUpdatedParticipantInput] =
        useState(editFormInputObject);
    const [errors, setErrors] = useState({});
    // const [participantUpdateData, setParticipantUpdateData] = useState({})

    const participantData = useSelector(
        (state) =>
            state.dashboardState.participantArchiveSuccessData.participantData
    );
    const location = useLocation();

    // --> THIS WAS TAKEN FROM THE EDIT PARTICIPANT INFO MODAL
    const required_fields = [
        'first_name',
        'last_name',
        'date_of_birth',
        'gender',
        'father',
        'mother',
        'city',
        'street',
        'house_number',
        'zip_code',
        'landline',
        'mobile',
        'email',
        'fallback_contact',
        'fallback_number',
        'swimming_allowed',
        'swimming_badge',
        'swimming_capability',
        'tools_allowed',
        'vaccination_fsme',
        'vaccination_tetanus',
        'tick_removal_allowed',
        'allergies',
        'vegetarian',
        'food_intolerance',
        'medication_allowed',
        'medication',
    ];

    useEffect(() => {
        setUpdatedParticipantInput(selectedParticipant);
    }, [selectedParticipant]);

    const validateInputs = () => {
        let newErrors = {};

        if (
            updatedParticipantInput.vaccination_fsme === 'yes' &&
            !updatedParticipantInput.vaccination_fsme_year
        ) {
            newErrors.vaccination_fsme_year = `${t(
                'onboarding-form-placeholders.This field is required'
            )}`;
        }

        if (
            updatedParticipantInput.vaccination_tetanus === 'yes' &&
            !updatedParticipantInput.vaccination_tetanus_year
        ) {
            newErrors.vaccination_tetanus_year = `${t(
                'onboarding-form-placeholders.This field is required'
            )}`;
        }

        required_fields.forEach((field) => {
            if (!updatedParticipantInput[field]) {
                newErrors[field] = `${t(
                    'onboarding-form-placeholders.This field is required'
                )}`;
            }
        });

        if (
            updatedParticipantInput.email &&
            !/\S+@\S+\.\S+/.test(updatedParticipantInput.email)
        ) {
            newErrors.email = `${t(
                'onboarding-form-placeholders.Email is invalid'
            )}`;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setUpdatedParticipantInput({
            ...updatedParticipantInput,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateInputs()) {
            return;
        } else {
            if (updatedParticipantInput) {
                const params = {
                    publicKey: process.env.REACT_APP_PUB_KEY_1,
                    formData: updatedParticipantInput,
                };
                //encrypt form data
                encryptFormData(params).then((encryptedData) => {
                    const form_data = {
                        id: updatedParticipantInput.id,
                        token:
                            loginStorage && loginStorage.loginData.data.token,
                        ...encryptedData,
                    };
                    participantUpdateDispatchAction(form_data, 'participant', params.formData);
                });
            }
        }
    };
    // --> END

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: 'Kitchen Cards',
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        outline: '0',
        p: 4,
        zIndex: 5,
    };

    // handle export csv here
    const handleExportCsv = () => {
        const participantData = allParticipantData.filter(
            (currObj) =>
                !currObj.hasOwnProperty('tent_id') &&
                currObj.is_archived === 'no'
        );
        const csvData = jsonToCSV(participantData);

        const params = {
            csvData,
            page: 'Participants',
        };
        exportCSV(params);
    };

    // USE EFFECT FROM VIEW_PARTICIPANT_MODAL

    // --> PARTICIPANT ARCHIVE STATUS
    useEffect(() => {
        if (archiveType) {
            if (participantArchiveStatus === 'participant_archive_success') {
                handleViewParticipantClose();
                dispatch({
                    type: 'PARTICIPANT_ARCHIVE_SUCCESS_STOP',
                    payload: selectedParticipant
                        ? selectedParticipant
                        : participantData,
                    archiveType: 'yes',
                    appUrlPathname: location.pathname,
                    t
                });
            } else if (
                participantArchiveStatus === 'participant_archive_success_stop'
            ) {
                // setArchiveType(null);
            } else if (
                participantArchiveStatus === 'participant_archive_failed'
            ) {
                handleViewParticipantClose();
                // setArchiveType(null);
                Swal.fire({
                    title: `${t('swal-messages.Error')}!`,
                    // text: `There was a problem ${archiveType === 'yes' ? 'archiving' : 'unarchiving'} the participant.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then((result) => {
                    if (result.isConfirmed) {
                        // dispatch({
                        //   type: "PARTICIPANT_ARCHIVE_FAILED_STOP"
                        // })
                    }
                });
            }

            const archiveInfo = {
                archiveStatus: participantArchiveStatus,
                archiveId: selectedParticipant
                    ? selectedParticipant.id
                    : participantData.id,
                archiveData: selectedParticipant,
                archiveType,
            };
            handleArchiveUpdate(archiveInfo);
        }
    }, [selectedParticipant, archiveType, participantArchiveStatus]);

    // USE EFFECT FROM EDIT PARTICIPANT INFO

    useEffect(() => {
        if (participantUpdateStatus === 'participant_update_start') {
          handleEditParticipantClose();
        }
        if (participantUpdateStatus === 'participant_update_success') {
            handleEditParticipantClose();
            Swal.fire({
                title: `${t('swal-messages.Success')}!`,
                // text: 'Participant updated successfully.',
                icon: 'success',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({
                        type: 'PARTICIPANT_UPDATE_SUCCESS_STOP',
                        payload: updatedParticipantInput,
                    });
                }
            });
        }

        if (participantUpdateStatus === 'participant_update_failed') {
            handleEditParticipantClose();
            Swal.fire({
                title: `${t('swal-messages.Error')}!`,
                // text: 'There was a problem updating particpant',
                icon: 'error',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({
                        type: 'PARTICIPANT_UPDATE_FAILED_STOP',
                    });
                }
            });
        }
    }, [participantUpdateStatus]);

    return (
        <section className="w-full bg-[#F9F9F9] px-20 pt-10">
            <>
                <h1 className="text-2xl text-tertiary font-medium">
                    {t('participants-page.Participants')}
                </h1>
                <div className="w-full h-20 bg-white mt-10 rounded-lg flex items-center justify-between px-5">
                    <div className="relative w-[30%]">
                        <svg
                            className="absolute right-5 top-3"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M14.1668 14.1667L17.5001 17.5M2.50012 9.16667C2.50012 10.9348 3.2025 12.6305 4.45274 13.8807C5.70299 15.131 7.39868 15.8333 9.16679 15.8333C10.9349 15.8333 12.6306 15.131 13.8808 13.8807C15.1311 12.6305 15.8335 10.9348 15.8335 9.16667C15.8335 7.39856 15.1311 5.70286 13.8808 4.45262C12.6306 3.20238 10.9349 2.5 9.16679 2.5C7.39868 2.5 5.70299 3.20238 4.45274 4.45262C3.2025 5.70286 2.50012 7.39856 2.50012 9.16667Z"
                                stroke="#2F4858"
                                stroke-opacity="0.6"
                                stroke-width="1.25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <input
                            type="text"
                            placeholder={`${t('participants-page.Search')}...`}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="outline-primary text-secondary bg-[#FFFFFF] border-2 border-[#EFEFEF] h-10 pl-3 rounded-full w-full"
                        />
                    </div>
                    <div className="w-[60%] flex items-center justify-end gap-5">
                        <div className="relative w-[25%] h-10">
                            <button
                                onClick={handleExportCsv}
                                className="w-full pr-5 h-full rounded-full bg-primary outline-none text-white font-medium text-sm"
                            >
                                {t('participants-page.Export CSV')}
                            </button>
                            <svg
                                className="absolute top-2 right-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M10.0054 3.00004C10.4555 2.99684 10.9056 3.18137 11.2491 3.55044L13.3291 5.73712C13.5563 5.98032 13.5563 6.37712 13.3291 6.62032C13.2771 6.67776 13.2137 6.72367 13.1428 6.75509C13.072 6.78651 12.9954 6.80274 12.9179 6.80274C12.8404 6.80274 12.7638 6.78651 12.693 6.75509C12.6222 6.72367 12.5587 6.67776 12.5067 6.62032L10.5334 4.54778V13.0737C10.5334 13.4011 10.2944 13.6668 10 13.6668C9.70563 13.6668 9.4667 13.4011 9.4667 13.0737V4.58298L7.52536 6.62032C7.47336 6.67759 7.40997 6.72335 7.33925 6.75466C7.26852 6.78598 7.19203 6.80215 7.11469 6.80215C7.03734 6.80215 6.96085 6.78598 6.89013 6.75466C6.8194 6.72335 6.75601 6.67759 6.70402 6.62032C6.59421 6.49925 6.53339 6.34164 6.53339 6.17819C6.53339 6.01473 6.59421 5.85713 6.70402 5.73605L8.78403 3.54938C8.93845 3.37961 9.12601 3.24329 9.33515 3.14881C9.54429 3.05433 9.77057 3.0037 10 3.00004H10.0054ZM5.39201 9.40007C4.88748 9.40007 4.45334 9.71367 4.35521 10.1499L3.08694 15.75C3.07336 15.8102 3.06657 15.8717 3.06667 15.9334C3.06667 16.4486 3.54027 16.8668 4.12268 16.8668H15.8774C15.9467 16.8668 16.0161 16.8604 16.0843 16.8486C16.6571 16.7473 17.0283 16.2556 16.9142 15.75L15.6449 10.1499C15.5467 9.71367 15.1126 9.40007 14.6102 9.40007H5.39201ZM7.33335 8.3334V9.40007H12.6667V8.3334H14.6134C15.6225 8.3334 16.4907 8.9798 16.6891 9.87687L17.9585 15.6369C18.1878 16.6769 17.4443 17.6881 16.2987 17.8961C16.1615 17.921 16.0222 17.9335 15.8827 17.9334H4.11734C2.9472 17.9334 2 17.0737 2 16.0134C2 15.8865 2.01387 15.7606 2.0416 15.6369L3.31094 9.87687C3.50827 8.9798 4.37761 8.3334 5.38561 8.3334H7.33335Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div className="relative w-[18%] h-10">
                            <button
                                onClick={handlePrint}
                                className="w-full h-full rounded-full pr-5 bg-primary outline-none text-white font-medium text-sm"
                            >
                                {t('participants-page.Print')}
                            </button>
                            <svg
                                className="absolute top-3 right-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M4.8125 4.40625C4.8125 4.03329 4.96066 3.6756 5.22438 3.41188C5.4881 3.14816 5.84579 3 6.21875 3H12.7812C13.1542 3 13.5119 3.14816 13.7756 3.41188C14.0393 3.6756 14.1875 4.03329 14.1875 4.40625V4.875H14.6562C15.2779 4.875 15.874 5.12193 16.3135 5.56147C16.7531 6.00101 17 6.59715 17 7.21875V11.9062C17 12.2792 16.8518 12.6369 16.5881 12.9006C16.3244 13.1643 15.9667 13.3125 15.5938 13.3125H14.1875V14.7188C14.1875 15.0917 14.0393 15.4494 13.7756 15.7131C13.5119 15.9768 13.1542 16.125 12.7812 16.125H6.21875C5.84579 16.125 5.4881 15.9768 5.22438 15.7131C4.96066 15.4494 4.8125 15.0917 4.8125 14.7188V13.3125H3.40625C3.03329 13.3125 2.6756 13.1643 2.41188 12.9006C2.14816 12.6369 2 12.2792 2 11.9062V7.21875C2 6.59715 2.24693 6.00101 2.68647 5.56147C3.12601 5.12193 3.72215 4.875 4.34375 4.875H4.8125V4.40625ZM13.25 4.40625C13.25 4.28193 13.2006 4.1627 13.1127 4.07479C13.0248 3.98689 12.9056 3.9375 12.7812 3.9375H6.21875C6.09443 3.9375 5.9752 3.98689 5.88729 4.07479C5.79939 4.1627 5.75 4.28193 5.75 4.40625V4.875H13.25V4.40625ZM5.75 10.9688V14.7188C5.75 14.8431 5.79939 14.9623 5.88729 15.0502C5.9752 15.1381 6.09443 15.1875 6.21875 15.1875H12.7812C12.9056 15.1875 13.0248 15.1381 13.1127 15.0502C13.2006 14.9623 13.25 14.8431 13.25 14.7188V10.9688C13.25 10.8444 13.2006 10.7252 13.1127 10.6373C13.0248 10.5494 12.9056 10.5 12.7812 10.5H6.21875C6.09443 10.5 5.9752 10.5494 5.88729 10.6373C5.79939 10.7252 5.75 10.8444 5.75 10.9688Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                {currentDataParticipant && currentDataParticipant.length > 0 ? (
                    <>
                        <div className="mt-10 grid grid-cols-3 auto-rows-fr gap-4 ">
                            {currentDataParticipant.map((data, index) => {
                                if((participantUpdateStatus === "participant_update_start") && (data.id === selectedParticipant.id)) {
                                  return (
                                      <Box sx={{ width: '100%' }}>
                                          <LinearProgress 
                                              variant="determinate" 
                                              value={progress} 
                                              sx={{
                                                  backgroundColor: 'lightgrey',
                                                  '& .MuiLinearProgress-bar': {
                                                    backgroundColor: "#FDB315", // Change the bar color
                                                  },
                                              }}
                                          />
                                          <div
                                              className="cursor-pointer"
                                              key={data.id}
                                              onClick={() =>
                                                handleViewParticipantOpen(data)
                                              }
                                          >
                                              <Card {...data} />
                                          </div>
                                      </Box>
                                  );
                              }
                                return (
                                    <div
                                        className="cursor-pointer"
                                        key={data.id}
                                        onClick={() =>
                                            handleViewParticipantOpen(data)
                                        }
                                    >
                                        <Card {...data} />
                                    </div>
                                );
                            })}
                        </div>
                        {selectedParticipant && (
                            <ViewParticipantInfo
                                buttonLabel="Edit"
                                handleEditParticipantOpen={
                                    handleEditParticipantOpen
                                }
                                handleViewParticipantClose={
                                    handleViewParticipantClose
                                }
                                viewParticipantOpen={viewParticipantOpen}
                                style={style}
                                participant={selectedParticipant}
                                allParticipantStatus={allParticipantStatus}
                                archiveParticipant={archiveParticipant}
                                archiveType={archiveType}
                                setArchiveType={setArchiveType}
                                participantArchiveStatus={
                                    participantArchiveStatus
                                }
                                t={t}
                            />
                        )}
                        {selectedParticipant && (
                            <EditParticipantInfo
                                handleEditParticipantClose={
                                    handleEditParticipantClose
                                }
                                style={style}
                                editParticipantOpen={editParticipantOpen}
                                participant={selectedParticipant}
                                updatedParticipantInput={
                                    updatedParticipantInput
                                }
                                errors={errors}
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                participantUpdateStatus={
                                    participantUpdateStatus
                                }
                                t={t}
                            />
                        )}
                        <Pagination
                            handlePageChange={handlePageChange}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            handlePerPageChange={handlePerPageChange}
                        />
                    </>
                ) : currentDataParticipant &&
                  currentDataParticipant.length > 0 ? (
                    <>
                        <div className="mt-10 grid grid-cols-3 auto-rows-fr gap-4 ">
                            {currentDataParticipant.map((data, index) => {
                                if (
                                    participantUpdateStatus ===
                                    'participant_update_success_stop'
                                ) {
                                    if (data.id === participantUpdateData.id) {
                                        data = {
                                            ...participantUpdateData,
                                        };
                                    }

                                    return (
                                        <div
                                            className="cursor-pointer"
                                            key={index}
                                            onClick={() =>
                                                handleViewParticipantOpen(data)
                                            }
                                        >
                                            <Card {...data} />
                                        </div>
                                    );
                                }
                                return (
                                    <div
                                        className="cursor-pointer"
                                        key={index}
                                        onClick={() =>
                                            handleViewParticipantOpen(data)
                                        }
                                    >
                                        <Card {...data} />
                                    </div>
                                );
                            })}
                        </div>
                        {selectedParticipant && (
                            <ViewParticipantInfo
                                buttonLabel="Edit"
                                handleEditParticipantOpen={
                                    handleEditParticipantOpen
                                }
                                handleViewParticipantClose={
                                    handleViewParticipantClose
                                }
                                viewParticipantOpen={viewParticipantOpen}
                                style={style}
                                participant={selectedParticipant}
                                allParticipantStatus={allParticipantStatus}
                                archiveParticipant={archiveParticipant}
                                archiveType={archiveType}
                                setArchiveType={setArchiveType}
                                participantArchiveStatus={
                                    participantArchiveStatus
                                }
                                t={t}
                            />
                        )}
                        {selectedParticipant && (
                            <EditParticipantInfo
                                handleEditParticipantClose={
                                    handleEditParticipantClose
                                }
                                style={style}
                                editParticipantOpen={editParticipantOpen}
                                participant={selectedParticipant}
                                updatedParticipantInput={
                                    updatedParticipantInput
                                }
                                handleEditParticipantOpen={
                                    handleEditParticipantOpen
                                }
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                participantUpdateStatus={
                                    participantUpdateStatus
                                }
                                t={t}
                            />
                        )}
                        <Pagination
                            handlePageChange={handlePageChange}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            handlePerPageChange={handlePerPageChange}
                        />
                    </>
                ) : (
                    <EmptyState />
                )}
            </>
            <div style={{ display: 'none' }}>
                <PrintableParticipantCard
                    ref={printRef}
                    data={filteredParticipants}
                />
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    participantUpdateStatus: state.dashboardState.participantUpdateStatus,
    participantUpdateSuccessData:
        state.dashboardState.participantUpdateSuccessData,
});

const mapDispatchToProps = (dispatch) => ({
    participantUpdateDispatchAction: (params, subpage, formData) =>
        dispatch(updateAction(params, subpage, formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Participants);
