import React, {useEffect, useState} from 'react';
import verifyKeys from '../utils/verify-keys';
import { useAuth } from '../custom-hooks/useAuth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function KeyInputForm(props) {

    const keyInputObj = {
        privateKey: ""
    }

    const ReactSwal = withReactContent(Swal);

    const { getLogin, setLogin, setPrivateKey } = useAuth();
    const loginStorage = JSON.parse(getLogin());

    const [keyInputVal, setKeyInputVal] = useState(keyInputObj);

    const handleInputChange = (e) => {
        e.preventDefault();

        setKeyInputVal(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmitChange = (e) => {
        e.preventDefault();
        const keyParam = {
            privateKey: keyInputVal.privateKey,
            publicKey: process.env.REACT_APP_PUB_KEY_1
        }
        verifyKeys(keyParam)
        .then(res => {
            if(res === undefined) {
                ReactSwal.fire({
                    title: "Key verification failed",
                    icon: "error",
                    confirmButtonText: 'OK'
                })
            }else if(res.isKeyValid) {
                const loginParams = {
                    ...loginStorage,
                    isKeyVerified: res.isKeyValid
                }
                setLogin(loginParams);
                setPrivateKey(res.privateKeyPEM)
                window.location.assign("/dashboard")
            }
        })
    }

    useEffect(() => {
        
    }, [])

    return (
        <div>
            <div className="mt-10">
                <h1 className="text-3xl font-bold text-secondary">Enter Private Key</h1>

                <form className="mt-10" onSubmit={handleSubmitChange}>
                    <div className="flex flex-col mt-4">
                        <label className="text-[0.85rem] font-semibold text-secondary" htmlFor="key">Key</label>
                        <div className="relative">
                            <svg className="absolute top-4 left-3" xmlns="http://www.w3.org/2000/svg" width="20" height="21"
                                viewBox="0 0 20 21" fill="none">
                                <path
                                    d="M5.34608 17.5C4.97608 17.5 4.65941 17.3681 4.39608 17.1042C4.13219 16.8408 4.00024 16.5242 4.00024 16.1542V8.84583C4.00024 8.47583 4.13219 8.15917 4.39608 7.89583C4.65941 7.63195 4.97608 7.5 5.34608 7.5H6.50024V5.83333C6.50024 4.905 6.82358 4.11722 7.47024 3.47C8.11691 2.82278 8.90469 2.49945 9.83358 2.5C10.7619 2.5 11.5497 2.82333 12.1969 3.47C12.8441 4.11667 13.1675 4.90445 13.1669 5.83333V7.5H14.3211C14.6911 7.5 15.0077 7.63195 15.2711 7.89583C15.535 8.15917 15.6669 8.47583 15.6669 8.84583V16.1542C15.6669 16.5242 15.535 16.8408 15.2711 17.1042C15.0077 17.3681 14.6911 17.5 14.3211 17.5H5.34608ZM9.83358 13.75C10.1852 13.75 10.4814 13.6294 10.7219 13.3883C10.963 13.1478 11.0836 12.8517 11.0836 12.5C11.0836 12.1483 10.963 11.8522 10.7219 11.6117C10.4814 11.3706 10.1852 11.25 9.83358 11.25C9.48191 11.25 9.1858 11.3706 8.94524 11.6117C8.70413 11.8522 8.58358 12.1483 8.58358 12.5C8.58358 12.8517 8.70413 13.1478 8.94524 13.3883C9.1858 13.6294 9.48191 13.75 9.83358 13.75ZM7.33358 7.5H12.3336V5.83333C12.3336 5.13889 12.0905 4.54861 11.6044 4.0625C11.1183 3.57639 10.528 3.33333 9.83358 3.33333C9.13913 3.33333 8.54886 3.57639 8.06274 4.0625C7.57663 4.54861 7.33358 5.13889 7.33358 5.83333V7.5Z"
                                    fill="#2F4858" fill-opacity="0.6" />
                            </svg>

                            <textarea rows="8" className="border rounded-md py-3 mt-2 outline-primary px-10 text-gray-500 w-full" name="privateKey" value={keyInputVal.privateKey} id="key" onChange={handleInputChange} required></textarea>
                            <span className="block mt-2 text-sm text-red-500"></span>
                        </div>
                    </div>

                    <button className="rounded-full text-white bg-primary py-2 mt-5 w-full">Submit</button>
                </form>
            </div>
        </div>
    )
}