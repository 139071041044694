function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const jsonToCSV = (json) => {
    const items = Array.isArray(json) ? json : [json];
    // Replace 'id' with '#' in the headers and capitalize the first letter
    const headers = ['#', ...Object.keys(items[0]).filter(header => header !== 'id')].map(header => capitalizeFirstLetter(header));
    const csvRows = [];

    csvRows.push(headers.join(','));

    items.forEach((item, index) => {
        // Add the row number to the beginning of each row and remove 'id' field
        const row = [index + 1, ...Object.entries(item).filter(([key]) => key !== 'id').map(([_, value]) => value)];
        const csvRow = row.map(value => {
            const escape = ('' + value).replace(/"/g, '\\"');
            return `"${escape}"`;
        });
        csvRows.push(csvRow.join(','));
    });

    return csvRows.join('\n');
}