export const formValidation = {
    login: (params) => {

        const result = [];

        for(let i = 0; i < params.length; i++) {
            let field = params[i].name;
            if(params[i][field].length < 1) {
                let param = {
                    ...params[i],
                    errorMsg: (`The ${field} field is required`),
                }
                result.push(param);
            }
        }
        return result;
    },
    userCreate: (params) => {

        const result = [];

        for(let i = 0; i < params.length; i++) {
            let field = params[i].name;
            if(params[i][field].length < 1) {
                let param = {
                    ...params[i],
                    errorMsg: (`The ${field} field is required`),
                }
                result.push(param);
            }
        }
        return result;
    },
    userUpdate: (params) => {

        const result = [];

        for(let i = 0; i < params.length; i++) {
            let field = params[i].name;
            if(params[i][field].length < 1) {
                let param = {
                    ...params[i],
                    errorMsg: (`The ${field} field is required`),
                }
                result.push(param);
            }
        }
        return result;
    },
    registeration: (params) => {

    }
}