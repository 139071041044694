import React, { useEffect } from "react";
import { useAuth } from "../custom-hooks/useAuth";
import { Routes as ROUTES } from "../routes";

export default function AuthCheck(props) {

    const { children, pageType, ...rest } = props;

    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());

    if(pageType === 'login') {
        if(loginStorage && loginStorage.isLogin && loginStorage.isKeyVerified) {
            window.location.assign("/dashboard");
        }else if(loginStorage && loginStorage.isLogin && !loginStorage.isKeyVerified) {
            window.location.assign("/key-input");
        }

        return (
            <>
                { children }
            </>
        )
    }else if(pageType === 'keyInput') {
        if(loginStorage && !loginStorage.isLogin) {
            window.location.assign("/login");
        }
        if(loginStorage && loginStorage.isLogin && loginStorage.isKeyVerified) {
            window.location.assign("/dashboard");
        }
        if(!loginStorage) {
            window.location.assign("/login")
        }

        return (
            <>
                { children }
            </>
        )
    }else {
       const page = ROUTES.find((route) => route.name === pageType);
       if(page === undefined) {
            window.location.assign("/login")
       }else {
            if(loginStorage && !loginStorage.isLogin) {
                window.location.assign("/login")
            }
            if(loginStorage && loginStorage.isLogin && !loginStorage.isKeyVerified) {
                window.location.assign("/key-input");
            }
            if(!loginStorage) {
                window.location.assign("/login")
            }
            
            return (
                <>
                    { children }
                </>
            )
       }
       return (
        <></>
        )
    }
}