import Archive from './dashboard/subpages/archive';
import Enrollment from './dashboard/subpages/enrollments';
import Media from './dashboard/subpages/media';
import Medical from './dashboard/subpages/medical';
import Participants from './dashboard/subpages/participants';
import Dashboard from './dashboard/subpages/dashboard';
import Kitchen from './dashboard/subpages/kitchen';

export const subpagesData = [
    {
        name: 'dashboard',
        url: '/dashboard',
        component: (props) => <Dashboard {...props} />,
    },
    {
        name: 'participants',
        url: '/participants',
        component: (props) => <Participants {...props} />,
    },
    {
        name: 'enrollments',
        url: '/participants/enrollments',
        component: (props) => <Enrollment {...props} />,
    },
    {
        name: 'kitchen',
        url: '/participants/kitchen',
        component: (props) => <Kitchen {...props} />,
    },
    {
        name: 'medical',
        url: '/participants/medical',
        component: (props) => <Medical {...props} />,
    },
    {
        name: 'media',
        url: '/participants/media',
        component: (props) => <Media {...props} />,
    },
    {
        name: 'tents',
        url: '/tents',
        component: () => <></>,
    },
    {
        name: 'users',
        url: '/users',
        component: () => <></>,
    },
    {
        name: 'archives',
        url: '/participants/archive',
        component: (props) => <Archive {...props} />,
    },
];
