import React from 'react'
import calculateAge from '../../utils/calculateAge';
import { useTranslation } from 'react-i18next';

const Card = ({ first_name, last_name, gender, date_of_birth, city, zip_code, is_fee_paid, agreement_signed, confirmation_email_sent }) => {
    const{t} = useTranslation();
    return (
      <div class="relative rounded-3xl overflow-hidden shadow-md">
        <span className={`print:invisible absolute inline-block inset-0 ${gender == 'Male' ? 'bg-sky-400' : 'bg-rose-400'} z-10`}></span>

        <div className='bg-white rounded-tl-[1.75rem] print:rounded-tr-none py-4 px-5 break-inside-avoid relative z-20'>
          
            <div className='flex justify-between items-center py-4 border-b font-medium'>
              <span className='flex gap-1 text-secondary'>
                  <h1 className='text-tertiary print:text-black'>{first_name}</h1>
                  <h1 className='text-tertiary print:text-black'>{last_name}</h1>
                  <p className='invisible print:visible'>({gender}, {calculateAge(date_of_birth)})</p>
              </span>
              <div className='flex items-center gap-1'>
                <svg className={`${is_fee_paid === 'yes' ? 'fill-teal-700' : 'fill-slate-300'} `} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path d="M15 18.5C13.8112 18.5011 12.6451 18.1751 11.6292 17.5578C10.6132 16.9405 9.7867 16.0556 9.24001 15H14.38C14.76 15 15.11 14.79 15.27 14.45C15.6 13.79 15.12 13 14.38 13H8.58001C8.53001 12.67 8.50001 12.34 8.50001 12C8.50001 11.66 8.53001 11.33 8.58001 11H14.38C14.76 11 15.11 10.79 15.27 10.45C15.3485 10.298 15.3864 10.1282 15.3801 9.95727C15.3738 9.78628 15.3234 9.61982 15.2339 9.474C15.1444 9.32818 15.0188 9.20793 14.8692 9.12489C14.7196 9.04185 14.5511 8.99883 14.38 9H9.24001C9.78767 7.94518 10.6144 7.06098 11.6301 6.4438C12.6458 5.82661 13.8115 5.50015 15 5.5C16.25 5.5 17.42 5.86 18.42 6.47C18.92 6.78 19.57 6.73 19.99 6.31C20.57 5.73 20.44 4.78 19.74 4.35C18.3157 3.46938 16.6746 3.00199 15 3C11.08 3 7.76001 5.51 6.52001 9H3.62001C3.24001 9 2.89001 9.21 2.72001 9.55C2.39001 10.22 2.87001 11 3.62001 11H6.06001C5.97902 11.6642 5.97902 12.3358 6.06001 13H3.62001C3.24001 13 2.89001 13.21 2.73001 13.55C2.39001 14.22 2.87001 15 3.62001 15H6.52001C7.76001 18.49 11.08 21 15 21C16.74 21 18.36 20.51 19.74 19.65C20.43 19.22 20.56 18.26 19.98 17.68C19.56 17.26 18.91 17.21 18.41 17.53C17.42 18.15 16.26 18.5 15 18.5Z"/>
                </svg>
                <svg className={`${agreement_signed === 'yes' ? 'fill-teal-700' : 'fill-slate-300'} `} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_1618_3447)">
                    <path d="M16.2632 3C16.9881 2.99996 17.6856 3.27694 18.2131 3.77427C18.7405 4.2716 19.058 4.95168 19.1005 5.67537L19.1053 5.84211V15.3158H19.8158C20.4287 15.3158 20.9337 15.7819 20.9943 16.3787L21 16.5V18.1579C21 18.8828 20.7231 19.5804 20.2257 20.1078C19.7284 20.6353 19.0483 20.9527 18.3246 20.9953L18.1579 21H8.68421C7.95927 21 7.26172 20.7231 6.73428 20.2257C6.20684 19.7284 5.88937 19.0483 5.84684 18.3246L5.84211 18.1579V8.68421H4.18421C3.89104 8.68434 3.60824 8.57573 3.39054 8.37937C3.17283 8.18302 3.0357 7.91289 3.00568 7.62126L3 7.5V5.84211C2.99996 5.11717 3.27694 4.41962 3.77427 3.89217C4.2716 3.36473 4.95168 3.04727 5.67537 3.00474L5.84211 3H16.2632ZM19.1053 17.2105H10.5789V18.1579C10.5789 18.4895 10.5221 18.8087 10.4179 19.1053H18.1579C18.4092 19.1053 18.6501 19.0055 18.8278 18.8278C19.0055 18.6501 19.1053 18.4092 19.1053 18.1579V17.2105ZM12.4737 11.5263H10.5789C10.3375 11.5266 10.1052 11.619 9.92965 11.7848C9.75407 11.9506 9.64841 12.1771 9.63426 12.4182C9.62011 12.6592 9.69853 12.8966 9.85351 13.0817C10.0085 13.2669 10.2283 13.3859 10.4681 13.4144L10.5789 13.4211H12.4737C12.7151 13.4208 12.9474 13.3283 13.123 13.1626C13.2986 12.9968 13.4042 12.7703 13.4184 12.5292C13.4325 12.2882 13.3541 12.0508 13.1991 11.8656C13.0441 11.6805 12.8243 11.5615 12.5845 11.5329L12.4737 11.5263ZM14.3684 7.73684H10.5789C10.3277 7.73684 10.0867 7.83665 9.90906 8.01432C9.73139 8.19199 9.63158 8.43295 9.63158 8.68421C9.63158 8.93547 9.73139 9.17643 9.90906 9.3541C10.0867 9.53177 10.3277 9.63158 10.5789 9.63158H14.3684C14.6197 9.63158 14.8606 9.53177 15.0383 9.3541C15.216 9.17643 15.3158 8.93547 15.3158 8.68421C15.3158 8.43295 15.216 8.19199 15.0383 8.01432C14.8606 7.83665 14.6197 7.73684 14.3684 7.73684ZM5.84211 4.89474C5.59085 4.89474 5.34988 4.99455 5.17221 5.17221C4.99455 5.34988 4.89474 5.59085 4.89474 5.84211V6.78947H5.84211V4.89474Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1618_3447">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <svg className={`${confirmation_email_sent === 'yes' ? 'fill-teal-700' : 'fill-slate-300'} `} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM19 6L12.5 10.47C12.348 10.5578 12.1755 10.604 12 10.604C11.8245 10.604 11.652 10.5578 11.5 10.47L5 6H19Z"/>
                </svg>
              </div>
            </div>
            <div className='py-3'>
              <div className='flex justify-between items-center mt-2'>
                <h1 className='text-gray-400 text-sm font-medium'>{t('participants-page.Location')}</h1>
                <p className='text-secondary text-sm font-medium'>{zip_code} {city}</p>
              </div>
            </div>
          </div>
      </div>
    );
};

export default Card