import React from 'react';
import { subpagesData as SUBPAGES_DATA } from '../../subpage-data';
import { useLocation } from 'react-router';

export default function DashboardSubpages(props) {
    const location = useLocation();

    const subpageObj = SUBPAGES_DATA.find(
        (subpage) => subpage.url === location.pathname
    );
    const subpageComponent = subpageObj ? (
        subpageObj.component(props)
    ) : (
        <div className="no-users-cover">No Content</div>
    );

    return <>{subpageComponent}</>;
}
