import React, {} from 'react'
import { Box, Button, Typography, Modal } from '@mui/material';
import Loader from '../Loader';

export default function EditEnrollmentInfo (props) {

    const {
        handleEditEnrollmentClose, 
        editEnrollmentOpen,
        enrollmentUpdateStatus,
        style, 
        enrollment,
        handleEnrollmentSubmit,
        handleInputChange,
        updatedEnrollmentInput,
        errors,
        t
    } = props;

    return (
    <>
        <Modal
        open={editEnrollmentOpen}
        onClose={() => handleEditEnrollmentClose("enrollment")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='-z-50 relative'
        >
            <Box sx={style}>
            {/* {enrollmentUpdateStatus === "enrollment_update_start" && <Loader componentType="edit-participant-modal" />} */}
            <form  action="" onSubmit={handleEnrollmentSubmit}>
                <div className="w-full h-[65vh] rounded-lg bg-white p-10 pt-0 overflow-auto relative">
                    <div className="flex justify-between items-center w-auto bg-white h-20 sticky top-0"> 
                        <Typography 
                        variant="h4" 
                        sx={{ 
                            color: '#2F4858', 
                            fontWeight: '500',
                            fontSize: '28px' 
                        }}
                        >
                          {enrollment.first_name} {enrollment.last_name}
                        </Typography>
                        <div className="flex gap-5 items-center">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FDB315',
                                    px: 3,
                                    py: '0.59rem',
                                    borderRadius: '999px',
                                    color: 'white',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    '&:hover': {
                                    backgroundColor: '#FDB315',
                                    color: 'white',
                                    }
                                }}
                            >
                                {t('modals.Update')}
                            </Button>
                            <div onClick={() => handleEditEnrollmentClose("enrollment")} className='cursor-pointer bg-[#EFEFEF] hover:bg-[#eeeeff] w-10 h-10 flex items-center justify-center rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="size-4">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M18 6l-12 12" />
                                    <path d="M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="border rounded-lg p-5">
                            <h1 className="text-[16px] text-tertiary font-medium">{t('modals.Edit')} {t('modals.Enrollment Information')}</h1>
                            <div className="flex gap-5 mt-4">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Email Address')}</label>
                                    <input type="text" name = "email" value={`${updatedEnrollmentInput ? updatedEnrollmentInput.email : 'N/A'}`} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors && <span className="text-red-500 text-xs">{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Mobile')}</label>
                                    <input type="text" name = "mobile" value={`${updatedEnrollmentInput ? updatedEnrollmentInput.mobile : 'N/A'}`} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors && <span className="text-red-500 text-xs">{errors.mobile}</span>}
                                    </div>
                                </div>
                                
                            </div>
                            <div className="flex gap-5 mt-3">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Landline')}</label>
                                    <input type="text" name = "landline" value={`${updatedEnrollmentInput ? updatedEnrollmentInput.landline : 'N/A'}`} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors && <span className="text-red-500 text-xs">{errors.landline}</span>}
                                    </div>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Fee Paid')}</label>
                                    <select name = "is_fee_paid" value={updatedEnrollmentInput ? updatedEnrollmentInput.is_fee_paid : 'N/A'} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedEnrollmentInput ? (updatedEnrollmentInput.is_fee_paid === 'yes') : 'N/A'}>Yes</option>
                                        <option value="no"  selected={updatedEnrollmentInput ? (updatedEnrollmentInput.is_fee_paid === 'no') : 'N/A'}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex gap-5 mt-3">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Agreement Signed')}</label>
                                    <select name = "agreement_signed" value={updatedEnrollmentInput ? updatedEnrollmentInput.agreement_signed : 'N/A'} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedEnrollmentInput ? (updatedEnrollmentInput.agreement_signed === 'yes') : 'N/A'}>Yes</option>
                                        <option value="no"  selected={updatedEnrollmentInput ? (updatedEnrollmentInput.agreement_signed === 'no') : 'N/A'}>No</option>
                                    </select>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Email Confirmation Sent')}</label>
                                    <select name = "confirmation_email_sent" value={updatedEnrollmentInput ? updatedEnrollmentInput.confirmation_email_sent : 'N/A'} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedEnrollmentInput ? (updatedEnrollmentInput.confirmation_email_sent === 'yes') : 'N/A'}>Yes</option>
                                        <option value="no"  selected={updatedEnrollmentInput ? (updatedEnrollmentInput.confirmation_email_sent === 'no') : 'N/A'}>No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </Box>
        </Modal>
    </>
  )
}