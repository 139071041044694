import Pages from "../pages";
import AuthCheck from "../pages/auth-check";

const { Login, KeyInput, Dashboard, Registration, Tents, Users, DashboardPages } = Pages();
// const { Participants, Enrollment, Kitchen, Medical, Media, Archive } = ParticipantPages();

export const Routes = [
    {
        name: "home",
        url: "/",
        component: () => (
            <AuthCheck pageType="login">
                <Login />
            </AuthCheck>
        )
    },
    {
        name: "login",
        url: "/login",
        component: () => (
            <AuthCheck pageType="login">
                <Login />
            </AuthCheck>
        )
    },
    {
        name: "keyInput",
        url: "/key-input",
        component: () => (
            <AuthCheck pageType="keyInput">
                <KeyInput />
            </AuthCheck>
        )
    },
    {
        name: "dashboard",
        url: "/dashboard",
        component: () => (
            <AuthCheck pageType="dashboard">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "participants",
        url: "/participants",
        component: () => (
            <AuthCheck pageType="participants">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "enrollments",
        url: "/participants/enrollments",
        component: () => (
            <AuthCheck pageType="enrollments">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "kitchen",
        url: "/participants/kitchen",
        component: () => (
            <AuthCheck pageType="kitchen">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "medical",
        url: "/participants/medical",
        component: () => (
            <AuthCheck pageType="medical">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "media",
        url: "/participants/media",
        component: () => (
            <AuthCheck pageType="media">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "tents",
        url: "/tents",
        component: () => (
            <AuthCheck pageType="tents">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "users",
        url: "/users",
        component: () => (
            <AuthCheck pageType="users">
                <DashboardPages />
            </AuthCheck>
        )
    },
    {
        name: "archives",
        url: "/participants/archive",
        component: () => (
            <AuthCheck pageType="archives">
                <DashboardPages />
            </AuthCheck>
        )
    },
]