import React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import Loader from '../Loader';

export default function EditParticipantInfo(props) {
    const {
        style,
        participant,
        participantUpdateStatus,
        participantUpdateDispatchAction,
        updatedParticipantInput,
        handleInputChange,
        handleSubmit,
        handleEditParticipantClose,
        editParticipantOpen,
        errors,
        t,
    } = props;

    return (
        <>
            <Modal
                open={editParticipantOpen}
                onClose={handleEditParticipantClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="-z-50 relative"
            >
                <Box sx={style}>
                    {participantUpdateStatus === 'participant_update_start' && (
                        <Loader componentType="edit-participant-modal" />
                    )}
                    <form action="" onSubmit={handleSubmit}>
                        <div className="w-full h-[85vh] rounded-lg bg-white p-10 pt-0 overflow-auto relative">
                            <div className="flex justify-between items-center w-auto bg-white h-20 sticky top-0">
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: '#2F4858',
                                        fontWeight: '500',
                                        fontSize: '28px',
                                    }}
                                >
                                    {participant.first_name}{' '}
                                    {participant.last_name}
                                </Typography>
                                <div className="flex gap-5 items-center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#FDB315',
                                            px: 3,
                                            py: '0.59rem',
                                            borderRadius: '999px',
                                            color: 'white',
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            '&:hover': {
                                                backgroundColor: '#FDB315',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        {t('modals.Update')}
                                    </Button>
                                    <div
                                        onClick={handleEditParticipantClose}
                                        className="cursor-pointer bg-[#EFEFEF] hover:bg-[#eeeeff] w-10 h-10 flex items-center justify-center rounded-full"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="size-4"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path d="M18 6l-12 12" />
                                            <path d="M6 6l12 12" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="border rounded-lg p-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t('modals.Administrative Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Fee Paid')}
                                            </label>
                                            <select
                                                name="is_fee_paid"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.is_fee_paid
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.is_fee_paid ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.is_fee_paid ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Fee Amount')}
                                            </label>
                                            <select
                                                name="fee_amount"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.fee_amount
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="standard"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.fee_amount ===
                                                              'standard'
                                                            : 'N/A'
                                                    }
                                                >
                                                    standard
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-3">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Fee Payment Date')}
                                            </label>
                                            <input
                                                type="date"
                                                onChange={handleInputChange}
                                                name="payment_date"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.payment_date
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Agreement Signed')}{' '}
                                            </label>
                                            <select
                                                name="agreement_signed"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.agreement_signed
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.agreement_signed ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.agreement_signed ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-3">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Media Consent')}
                                            </label>
                                            <select
                                                name="media_allowed"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.media_allowed
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.media_allowed ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.media_allowed ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Email Confirmation Sent'
                                                )}
                                            </label>
                                            <select
                                                name="confirmation_email_sent"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.confirmation_email_sent
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.confirmation_email_sent ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.confirmation_email_sent ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t('modals.Participant Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.First Name')}
                                            </label>
                                            <input
                                                type="text"
                                                name="first_name"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.first_name
                                                        : 'N/A'
                                                }`}
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.first_name && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.first_name}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Last Name')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="last_name"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.last_name
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.last_name && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.last_name}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-5 mt-3">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Date of Birth')}
                                            </label>
                                            <input
                                                type="date"
                                                onChange={handleInputChange}
                                                name="date_of_birth"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.date_of_birth
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.date_of_birth && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.date_of_birth}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Gender')}
                                            </label>
                                            <select
                                                name="gender"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.gender
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="Male"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.gender ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Male
                                                </option>
                                                <option
                                                    value="Female"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.gender ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Female
                                                </option>
                                            </select>
                                            <div>
                                                {errors.gender && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.gender}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t('modals.Guardian Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t("modals.Father's Full Name")}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="father"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.father
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.father && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.father}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t("modals.Mother's Full Name")}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="mother"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.mother
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.mother && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.mother}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.City')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="city"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.city
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.city && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.city}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Street')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="street"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.street
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.street && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.street}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.House Number')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="house_number"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.house_number
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.house_number && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.house_number}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Zip Code')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="zip_code"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.zip_code
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.zip_code && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.zip_code}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Landline')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="landline"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.landline
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.landline && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.landline}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Mobile')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="mobile"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.mobile
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.mobile && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.mobile}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Email Address')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="email"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.email
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.email && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.email}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t(
                                            'modals.Emergency Contact Information'
                                        )}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Full Name')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="fallback_contact"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.fallback_contact
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.fallback_contact && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.fallback_contact
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Mobile')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="fallback_number"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.fallback_number
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.fallback_number && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.fallback_number}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t('modals.Other')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Friend 1')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="friend_1"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.friend_1
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.friend_1 && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.friend_1}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Friend 2')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="friend_2"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.friend_2
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.friend_2 && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.friend_2}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Sibling In Same Tent'
                                                )}
                                            </label>
                                            <select
                                                name="siblings_in_same_tent"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.siblings_in_same_tent
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    Select Tent Information
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.siblings_in_same_tent ===
                                                              'yes'
                                                            : ''
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.siblings_in_same_tent ===
                                                              'no'
                                                            : ''
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.siblings_in_same_tent && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.siblings_in_same_tent
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Swimming Capability'
                                                )}
                                            </label>
                                            <select
                                                name="swimming_capability"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.swimming_capability
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT -
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.swimming_capability ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.swimming_capability ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.swimming_capability && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.swimming_capability
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t('modals.Activity Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Swimming Allowed')}
                                            </label>
                                            <select
                                                name="swimming_allowed"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.swimming_allowed
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    Select Tent Information
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.swimming_allowed ===
                                                              'yes'
                                                            : ''
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.swimming_allowed ===
                                                              'no'
                                                            : ''
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.swimming_allowed && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.swimming_allowed
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Swimming Capability'
                                                )}
                                            </label>
                                            <select
                                                name="swimming_capability"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.swimming_capability
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT -
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.swimming_capability ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.swimming_capability ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.swimming_capability && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.swimming_capability
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Swimming Badge')}
                                            </label>
                                            <select
                                                name="swimming_badge"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.swimming_badge
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option
                                                    value=""
                                                    className="font-normal text-sm text-secondary"
                                                >
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="Fruehschwimmer (Seepferdchen)"
                                                    className="font-normal text-sm text-secondary"
                                                >
                                                    Frühschwimmer (Seepferdchen)
                                                </option>
                                                <option
                                                    value="Freischwimmer (Bronze)"
                                                    className="font-normal text-sm text-secondary"
                                                >
                                                    Freischwimmer (Bronze)
                                                </option>
                                                <option
                                                    value="Silber"
                                                    className="font-normal text-sm text-secondary"
                                                >
                                                    Silber
                                                </option>
                                                <option
                                                    value="Gold"
                                                    className="font-normal text-sm text-secondary"
                                                >
                                                    Gold
                                                </option>
                                                <option
                                                    value="Keines davon"
                                                    className="font-normal text-sm text-secondary"
                                                >
                                                    Keines davon
                                                </option>
                                            </select>
                                            <div>
                                                {errors.swimming_badge && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.swimming_badge}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Tools Allowed')}
                                            </label>
                                            <select
                                                name="tools_allowed"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.tools_allowed
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.tools_allowed ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.tools_allowed ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.tools_allowed && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.tools_allowed}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography className="text-[16px] text-tertiary font-medium">
                                        {t('modals.Health Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Vaccination Tetanus'
                                                )}
                                            </label>
                                            <select
                                                name="vaccination_tetanus"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.vaccination_tetanus
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.vaccination_tetanus ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.vaccination_tetanus ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.vaccination_tetanus && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.vaccination_tetanus
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Vaccination Tetanus Year'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="vaccination_tetanus_year"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.vaccination_tetanus_year
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.vaccination_tetanus_year && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.vaccination_tetanus_year
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Vaccination FSME')}
                                            </label>
                                            <select
                                                name="vaccination_fsme"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.vaccination_fsme
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.vaccination_tetanus ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.vaccination_tetanus ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.vaccination_fsme && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.vaccination_fsme
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Vaccination FSME Year'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="vaccination_fsme_year"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.vaccination_fsme_year
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.vaccination_fsme_year && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.vaccination_fsme_year
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Tick Removal Allowed'
                                                )}
                                            </label>
                                            <select
                                                name="tick_removal_allowed"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.tick_removal_allowed
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.tick_removal_allowed ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.tick_removal_allowed ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.tick_removal_allowed && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.tick_removal_allowed
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Allergies')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="allergies"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.allergies
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.allergies && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.allergies}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Vegetarian')}
                                            </label>

                                            <select
                                                name="vegetarian"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.vegetarian
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.vegetarian ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.vegetarian ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.vegetarian && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.vegetarian}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Food Intolerance')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="food_intolerance"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.food_intolerance
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.food_intolerance && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.food_intolerance
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Medication Allowed')}
                                            </label>

                                            <select
                                                name="medication_allowed"
                                                value={
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.medication_allowed
                                                        : ''
                                                }
                                                onChange={handleInputChange}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            >
                                                <option value="">
                                                    - SELECT-
                                                </option>
                                                <option
                                                    value="yes"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.medication_allowed ===
                                                              'yes'
                                                            : 'N/A'
                                                    }
                                                >
                                                    Yes
                                                </option>
                                                <option
                                                    value="no"
                                                    selected={
                                                        updatedParticipantInput
                                                            ? updatedParticipantInput.medication_allowed ===
                                                              'no'
                                                            : 'N/A'
                                                    }
                                                >
                                                    No
                                                </option>
                                            </select>
                                            <div>
                                                {errors.medication_allowed && (
                                                    <span className="text-red-500 text-xs">
                                                        {
                                                            errors.medication_allowed
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Medication')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="medication"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.medication
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                            <div>
                                                {errors.medication && (
                                                    <span className="text-red-500 text-xs">
                                                        {errors.medication}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-full">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Additional Details')}
                                            </label>
                                            <input
                                                type="text"
                                                onChange={handleInputChange}
                                                name="additional_details"
                                                value={`${
                                                    updatedParticipantInput
                                                        ? updatedParticipantInput.additional_details
                                                        : 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}
