import React, { useEffect } from 'react';
import KeyInputForm from '../components/KeyInputForm';
import AuthLayout from './auth-layout';

export default function KeyInput(props) {
    return (
        <AuthLayout>
            <KeyInputForm />
        </AuthLayout>
    );
}
