import React, { useEffect, useState } from 'react';
import EmptyState from '../../../components/EmptyState';

export default function Dashboard(props) {
    const { dashboardProps } = props;

    const {
        allParticipantData,
        allParticipantStatus,
        t,
    } = dashboardProps;

    const verifiedParticipants = allParticipantData.filter(
        (participant) =>
            participant.is_fee_paid === 'yes' &&
            participant.confirmation_email_sent === 'yes' &&
            participant.agreement_signed === 'yes' &&
            participant.is_archived === 'no'
    ).length;

    const activeParticipants = allParticipantData.filter(
        (participant) => participant.is_archived === 'no'
    ).length;

    useEffect(() => {

    }, [allParticipantStatus]);

    return (
        allParticipantData && allParticipantData.length > 0 ? (
            <section className="w-full bg-[#F9F9F9]">
            <div className="flex flex-col px-20 pt-10">
                <h1 className="text-2xl text-tertiary font-medium">
                    {t('dashboard-page.Dashboard')}
                </h1>
                <div className="flex items-center gap-10 mt-10">
                    <div className="flex flex-col rounded-lg shadow-md px-6 py-4 gap-2">
                        <h1 className="text-tertiary font-medium">
                            {t('dashboard-page.Total Participants')}
                        </h1>
                        <h1 className="text-2xl text-secondary font-medium">
                            {activeParticipants}
                        </h1>
                    </div>
                    <div className="flex flex-col rounded-lg shadow-md px-6 py-4 gap-2">
                        <h1 className="text-tertiary font-medium">
                            {t('dashboard-page.Completed Registrations')}
                        </h1>
                        <h1 className="text-2xl text-secondary font-medium">
                            {verifiedParticipants}
                        </h1>
                    </div>
                </div>
            </div>
            </section> ) : (
            <EmptyState /> 
        )
    );
}
