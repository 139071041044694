import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Modal } from '@mui/material';
import { formValidation } from '../../utils/form-validation';
import { userActions } from '../../redux-utils/actions/user-actions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAuth } from '../../custom-hooks/useAuth';
import { useTranslation } from 'react-i18next';

const { updateUserAction } = userActions;


function ResetPasswordModal({
    handleUpdateUserModalClose, 
    updateUserModalOpen, 
    setUpdateUserModalOpen,
    userInfo,
    style, 
    updateUserDispatchAction,
    updateUserResult
}) {

const ReactSwal = withReactContent(Swal);
const { t } = useTranslation();

const { getLogin } = useAuth();
const loginStorage = JSON.parse(getLogin());

const userInputObj = {
    fullname: userInfo.name,
    username: userInfo.username,
    password: "",
    confirmPassword: ""
}

const userInputValidateArr = [
    {
        fullname: userInfo.name,
        errorMsg: "",
        name: "fullname"
    },
    // {
    //     email: "",
    //     errorMsg: "",
    //     name: "email"
    // },
    {
        username: userInfo.username,
        errorMsg: "",
        name: "username"
    },
    {
        password: "",
        errorMsg: "",
        name: "password"
    },
    {
        confirmPassword: "",
        errorMsg: "",
        name: "confirmPassword"
    }
]

const [userInputVal, setUserInputVal] = useState(userInputObj);
const [userInputValidate, setUserInputValidate] = useState(userInputValidateArr);
const [userInputError, setUserInputError] = useState({
    fullnameErrMsg: "",
    // emailErrMsg: "",
    usernameErrMsg: "",
    passwordErrMsg: "",
    confirmPasswordErrMsg: ""
});

const handleInputChange = (e) => {
    e.preventDefault();

    setUserInputVal(prev => {
        return {
            ...prev,
            [e.target.name]: e.target.value
        }
    })

    setUserInputValidate(prev => {
        const userInputValidateObj = prev.find((inputValidate) => e.target.name === inputValidate.name);
        userInputValidateObj[e.target.name] = e.target.value;
        return [
            ...prev,
            userInputValidateObj
        ]
    })
}

const handleUpdateUser = (e) => {
    e.preventDefault();

    // form validation here
    userInputValidate.length = 4;
    const errRes = formValidation.userUpdate(userInputValidate);

    // check if there's error in the returned array
    if(errRes.length > 0) {
        const fullnameErrObj = errRes.find(field => field.name === "fullname");
        // const emailErrObj = errRes.find(field => field.name === "email");
        const usernameErrObj = errRes.find(field => field.name === "username");
        const passwordErrObj = errRes.find(field => field.name === "password");
        const confirmPasswordErrObj = errRes.find(field => field.name === "confirmPassword");

        setUserInputError(prev => {
            return {
                ...prev,
                fullnameErrMsg: fullnameErrObj && fullnameErrObj.errorMsg,
                usernameErrMsg: usernameErrObj && usernameErrObj.errorMsg,
                passwordErrMsg: (userInputVal.password !== userInputVal.confirmPassword) ? "The password fields do not match" : (passwordErrObj && passwordErrObj.errorMsg),
                confirmPasswordErrMsg: (userInputVal.password !== userInputVal.confirmPassword) ? "The password fields do not match" : (confirmPasswordErrObj && confirmPasswordErrObj.errorMsg)
            }
        });
    }else if((errRes.length <= 0) && 
    userInputVal.fullname !== "" &&
    userInputVal.username !== "" &&
    userInputVal.password !== "" &&
    userInputVal.confirmPassword !== "" &&
    (userInputVal.password === userInputVal.confirmPassword
    )) {
        const params = {
            user: {
                id: userInfo.updateId,
                name: userInputVal.fullname,
                email: "ojayeme@mail.com",
                username: userInputVal.username,
                password: userInputVal.password,
                password_confirmation: userInputVal.confirmPassword
            },
            token: loginStorage && loginStorage.loginData.data.token
        }
        updateUserDispatchAction(params);
    }
}

useEffect(() => {

    if(updateUserResult.status === "user_update_success") {
        setUpdateUserModalOpen(false);
        ReactSwal.fire({
            title: `${t('swal-messages.Success')}!`,
            text: `${t('swal-messages.You have successfully updated your password')}!`,
            icon: 'success',
            confirmButtonText: 'OK'
        })
        .then(result => result.isConfirmed && window.location.assign("/users"))
    }

}, [updateUserResult.status])

  return (
    <>
        <Modal
        open={updateUserModalOpen}
        onClose={handleUpdateUserModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            {/* <Box sx={style}> */}
            <form className="" onSubmit={handleUpdateUser}>
                <div className="w-full h-[85vh] rounded-lg bg-white p-10 pt-0 overflow-auto relative z-50" style={style}>
                    <div className="flex justify-between items-center w-auto bg-white h-20 sticky top-0"> 
                        <Typography 
                        variant="h4" 
                        sx={{ 
                            color: '#2F4858', 
                            fontWeight: '500',
                            fontSize: '28px' 
                        }}
                        >
                        Reset Password
                        </Typography>
                        <div className="flex gap-5 items-center">
                            <Button
                                type="submit"
                                variant="outlined"
                                sx={{
                                    borderColor: '#FDB315',
                                    color: '#FDB315',
                                    px: 2,
                                    py: '0.59rem',
                                    borderRadius: '999px',
                                    fontWeight: '500',
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={handleUpdateUserModalClose}
                                variant="outlined"
                                sx={{
                                    borderColor: '#FDB315',
                                    color: '#FDB315',
                                    px: 2,
                                    py: '0.59rem',
                                    borderRadius: '999px',
                                    fontWeight: '500',
                                }}
                            >
                                X
                            </Button>
                        </div>
                    </div>
                    <div className="mt-5">
                        {/* <form className=""> */}
                            <div className="border rounded-lg p-5">
                                <div className="flex gap-5 mt-4">
                                    <div className="flex flex-col w-[100%]">
                                        <label className="font-medium text-[14px]">Name</label>
                                        <input type="text" name="fullname" value={userInputVal.fullname} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none" onChange={handleInputChange} />
                                        <span className="block mt-2 text-sm text-red-500">{userInputError.fullnameErrMsg}</span>
                                    </div>
                                </div>
                                {/* <div className="flex gap-5 mt-3">
                                    <div className="flex flex-col w-[100%]">
                                        <label className="font-medium text-[14px]">Email</label>
                                        <input type="email" name="email" value={userInputVal.email} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none" onChange={handleInputChange} />
                                        <span className="block mt-2 text-sm text-red-500">{userInputError.emailErrMsg}</span>
                                    </div>
                                </div> */}
                                <div className="flex gap-5 mt-3">
                                    <div className="flex flex-col w-[100%]">
                                        <label className="font-medium text-[14px]">Username</label>
                                        <input type="text" name="username" value={userInputVal.username} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none" onChange={handleInputChange} />
                                        <span className="block mt-2 text-sm text-red-500">{userInputError.usernameErrMsg}</span>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-3">
                                    <div className="flex flex-col w-[100%]">
                                        <label className="font-medium text-[14px]">Password</label>
                                        <input type="password" name="password" value={userInputVal.password} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none" onChange={handleInputChange} />
                                        <span className="block mt-2 text-sm text-red-500">{userInputError.passwordErrMsg}</span>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-3">
                                    <div className="flex flex-col w-[100%]">
                                        <label className="font-medium text-[14px]">Confirm Password</label>
                                        <input type="password" name="confirmPassword" value={userInputVal.confirmPassword} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none" onChange={handleInputChange} />
                                        <span className="block mt-2 text-sm text-red-500">{userInputError.confirmPasswordErrMsg}</span>
                                    </div>
                                </div>
                            </div>
                        {/* </form> */}
                    </div>
                </div>
            </form>
            {/* </Box> */}
        </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
    updateUserResult: state.userState
})

const mapDispatchToProps = (dispatch) => ({
    updateUserDispatchAction: (params) => dispatch(updateUserAction(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal)