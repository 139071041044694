import React, {} from 'react'
import { Box, Button, Typography, Modal } from '@mui/material';
import Loader from '../Loader';


export default function EditMediaInfo (props) {

    const {
        handleEditMediaClose, 
        editMediaOpen,
        mediaUpdateStatus,
        style, 
        media,
        handleMediaSubmit,
        handleInputChange,
        updatedMediaInput,
        errors,
        t
    } = props;

  return (
    <>
        <Modal
        open={editMediaOpen}
        onClose={() => handleEditMediaClose("media")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='-z-50 relative'
        >
            <Box sx={style}>
            {/* {mediaUpdateStatus === "media_update_start" && <Loader componentType="edit-participant-modal" />} */}
            <form  action="" onSubmit={handleMediaSubmit}>
                <div className="w-full h-[65vh] rounded-lg bg-white p-10 pt-0 overflow-auto relative">
                    <div className="flex justify-between items-center w-auto bg-white h-20 sticky top-0"> 
                        <Typography 
                        variant="h4" 
                        sx={{ 
                            color: '#2F4858', 
                            fontWeight: '500',
                            fontSize: '28px' 
                        }}
                        >
                          {media.first_name} {media.last_name}
                        </Typography>
                        <div className="flex gap-5 items-center">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FDB315',
                                    px: 3,
                                    py: '0.59rem',
                                    borderRadius: '999px',
                                    color: 'white',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    '&:hover': {
                                    backgroundColor: '#FDB315',
                                    color: 'white',
                                    }
                                }}
                            >
                                {t('modals.Update')}
                            </Button>
                            <div onClick={() => handleEditMediaClose("media")} className='cursor-pointer bg-[#EFEFEF] hover:bg-[#eeeeff] w-10 h-10 flex items-center justify-center rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="size-4">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M18 6l-12 12" />
                                    <path d="M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="border rounded-lg p-5">
                            <h1 className="text-[16px] text-tertiary font-medium">{t('modals.Edit')} {t('modals.Media Information')}</h1>
                            <div className="flex gap-5 mt-4">
                                <div className="flex flex-col w-full">
                                    <label className="font-medium text-[14px]">{t('modals.Media Allowed')} </label>
                                    <select name = "media_allowed" value={updatedMediaInput ? updatedMediaInput.media_allowed : ""} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedMediaInput ? (updatedMediaInput.media_allowed === 'yes') : "N/A"}>Yes</option>
                                        <option value="no"  selected={updatedMediaInput ? (updatedMediaInput.media_allowed === 'no') : "N/A"}>No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </Box>
        </Modal>
    </>
  )
}