export const useAuth = () => {
    // Local storage private key
    function setPrivateKey(privateKey) {
        localStorage.setItem('privateKey', privateKey);
    }

    function getPrivateKey() {
        return localStorage.getItem('privateKey');
    }

    // Local storage logins
    function setLogin(param) {
        localStorage.setItem('loginStorage', JSON.stringify(param));
    }

    function getLogin() {
        return localStorage.getItem('loginStorage');
    }

    function Logout() {
        localStorage.removeItem('loginStorage');
        localStorage.removeItem('sidebarState');
        localStorage.removeItem('privateKey');
        window.location.assign('/login');
    }

    return {
        setLogin,
        getLogin,
        Logout,
        setPrivateKey,
        getPrivateKey,
    };
};
