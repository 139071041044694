import Swal from 'sweetalert2';

export const exportCSV = (params) => {
    const { csvData, page } = params;
    if (csvData) {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `${page}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        Swal.fire({
            title: 'Error!',
            text: `No CSV data available to download.`,
            icon: 'error',
            confirmButtonText: 'OK',
        });
    }
}