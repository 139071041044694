import React, {useEffect, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formValidation } from '../utils/form-validation';
import { loginAction } from '../redux-utils/actions/login-action';

function LoginForm(props) {

    const { loginDispatchAction } = props;

    const dispatch = useDispatch();
    const { loginState } = useSelector((loginState) => loginState);

    const loginInputObj = {
        credential: "",
        password: ""
    }

    const loginInputValidateArr = [
        {
            credential: "",
            errorMsg: "",
            name: "credential"
        },
        {
            password: "",
            errorMsg: "",
            name: "password"
        }
    ]

    const [loginInputVal, setLoginInputVal] = useState(loginInputObj);
    const [loginInputValidate, setLoginInputValidate] = useState(loginInputValidateArr);
    const [loginInputError, setLoginInputError] = useState({
        credentialErrMsg: "",
        passwordErrMsg: ""
    });

    const handleInputChange = (e) => {
        e.preventDefault();

        setLoginInputVal(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })

        setLoginInputValidate(prev => {
            const loginInputValidateObj = prev.find((inputValidate) => e.target.name === inputValidate.name);
            loginInputValidateObj[e.target.name] = e.target.value;
            return [
                ...prev,
                loginInputValidateObj
            ]
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // form validation here
        loginInputValidate.length = 2;
        const res = formValidation.login(loginInputValidate);

        // check if there's error in the returned array
        if(res.length > 0) {
            const credentialErrObj = res.find(field => field.name === "credential");
            const passwordErrObj = res.find(field => field.name === "password");

            setLoginInputError(prev => {
                return {
                    ...prev,
                    credentialErrMsg: credentialErrObj && credentialErrObj.errorMsg,
                    passwordErrMsg: passwordErrObj && passwordErrObj.errorMsg
                }
            });
        }else {
            loginDispatchAction(loginInputVal);
        }
    }

    useEffect(() => {

        if(loginState.status === "login_success") {
            window.location.assign("/key-input")
        }

    }, [loginState.status])

    return (
        <div>
            <div className="mt-10">
                <h1 className="text-3xl font-bold text-secondary">Log in</h1>
                <form className="mt-10" onSubmit={handleFormSubmit}>
                    <div class="flex flex-col">
                        <label className="text-[0.85rem] font-semibold text-secondary" htmlFor="username">Username</label>
                        <div className="relative">
                            <svg className="absolute top-5 left-3" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                viewBox="0 0 20 21" fill="none">
                                <path
                                    d="M3.84583 16.3337C3.4625 16.3337 3.1425 16.2053 2.88583 15.9487C2.62861 15.6914 2.5 15.3712 2.5 14.9878V6.01283C2.5 5.62949 2.62861 5.30949 2.88583 5.05283C3.1425 4.7956 3.4625 4.66699 3.84583 4.66699H16.1542C16.5375 4.66699 16.8575 4.7956 17.1142 5.05283C17.3714 5.30949 17.5 5.62949 17.5 6.01283V14.9878C17.5 15.3712 17.3717 15.6912 17.115 15.9478C16.8578 16.205 16.5375 16.3337 16.1542 16.3337H3.84583ZM10 10.5962L16.6667 6.23783L16.41 5.50033L10 9.66699L3.59 5.50033L3.33333 6.23783L10 10.5962Z"
                                    fill="#2F4858" fill-opacity="0.6" />
                            </svg>
                            <input className="border h-10 rounded-md py-3 mt-2 outline-primary px-10 text-gray-500 w-full" placeholder="Username" type="text" name="credential" value={loginInputVal.username} onChange={handleInputChange} />
                            <span className="block mt-2 text-sm text-red-500">{loginInputError.usernameErrMsg}</span>
                        </div>
                    </div>
                    <div class="flex flex-col mt-4">
                        <label className="text-[0.85rem] font-semibold text-secondary" htmlFor="password">Password</label>
                        <div className="relative">
                            <svg class="absolute top-4 left-3" xmlns="http://www.w3.org/2000/svg" width="20" height="21"
                                viewBox="0 0 20 21" fill="none">
                                <path
                                    d="M5.34608 17.5C4.97608 17.5 4.65941 17.3681 4.39608 17.1042C4.13219 16.8408 4.00024 16.5242 4.00024 16.1542V8.84583C4.00024 8.47583 4.13219 8.15917 4.39608 7.89583C4.65941 7.63195 4.97608 7.5 5.34608 7.5H6.50024V5.83333C6.50024 4.905 6.82358 4.11722 7.47024 3.47C8.11691 2.82278 8.90469 2.49945 9.83358 2.5C10.7619 2.5 11.5497 2.82333 12.1969 3.47C12.8441 4.11667 13.1675 4.90445 13.1669 5.83333V7.5H14.3211C14.6911 7.5 15.0077 7.63195 15.2711 7.89583C15.535 8.15917 15.6669 8.47583 15.6669 8.84583V16.1542C15.6669 16.5242 15.535 16.8408 15.2711 17.1042C15.0077 17.3681 14.6911 17.5 14.3211 17.5H5.34608ZM9.83358 13.75C10.1852 13.75 10.4814 13.6294 10.7219 13.3883C10.963 13.1478 11.0836 12.8517 11.0836 12.5C11.0836 12.1483 10.963 11.8522 10.7219 11.6117C10.4814 11.3706 10.1852 11.25 9.83358 11.25C9.48191 11.25 9.1858 11.3706 8.94524 11.6117C8.70413 11.8522 8.58358 12.1483 8.58358 12.5C8.58358 12.8517 8.70413 13.1478 8.94524 13.3883C9.1858 13.6294 9.48191 13.75 9.83358 13.75ZM7.33358 7.5H12.3336V5.83333C12.3336 5.13889 12.0905 4.54861 11.6044 4.0625C11.1183 3.57639 10.528 3.33333 9.83358 3.33333C9.13913 3.33333 8.54886 3.57639 8.06274 4.0625C7.57663 4.54861 7.33358 5.13889 7.33358 5.83333V7.5Z"
                                    fill="#2F4858" fill-opacity="0.6" />
                            </svg>

                            <input className="border h-10 rounded-md py-3 mt-2 outline-primary px-10 text-gray-500 w-full" placeholder="••••••••" type="password" id="password" name="password" value={loginInputVal.password} onChange={handleInputChange} autocomplete="current-password" />
                            <span className="block mt-2 text-sm text-red-500">{loginInputError.passwordErrMsg}</span>
                            <svg className="absolute right-5 top-6 cursor-pointer " xmlns="http://www.w3.org/2000/svg"
                                width="18" height="11" viewBox="0 0 18 11" fill="none">
                                <path d="M1.5 6.33301C4.5 -0.333659 13.5 -0.333659 16.5 6.33301" stroke="#2F4858"
                                    stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M9 9.66602C8.6717 9.66602 8.34661 9.60135 8.04329 9.47571C7.73998 9.35008 7.46438 9.16593 7.23223 8.93378C7.00009 8.70164 6.81594 8.42604 6.6903 8.12272C6.56466 7.81941 6.5 7.49432 6.5 7.16602C6.5 6.83771 6.56466 6.51262 6.6903 6.20931C6.81594 5.90599 7.00009 5.63039 7.23223 5.39825C7.46438 5.1661 7.73998 4.98195 8.04329 4.85632C8.34661 4.73068 8.6717 4.66602 9 4.66602C9.66304 4.66602 10.2989 4.92941 10.7678 5.39825C11.2366 5.86709 11.5 6.50297 11.5 7.16602C11.5 7.82906 11.2366 8.46494 10.7678 8.93378C10.2989 9.40262 9.66304 9.66602 9 9.66602Z"
                                    stroke="#2F4858" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>

                    <button className="rounded-full text-white bg-primary py-2 mt-5 w-full" id="login-btn">Login</button>
                </form>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    loginDispatchAction: (params) => dispatch(loginAction(params)),
})

export default connect(null, mapDispatchToProps)(LoginForm)