import React, { useEffect } from 'react';
import LoginForm from '../components/LoginForm';
import AuthLayout from './auth-layout';

export default function Login(props) {

    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    )
}