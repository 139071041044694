import React, { useEffect, useState } from 'react';
import SideDrawer from './SideDrawer';
import DashboardSubpages from '../pages/dashboard/subpages';
import { dashboardActions } from '../redux-utils/actions/dashboard-actions';
import { connect, useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { encryptUtility } from '../utils/crypt-module/encrypt';
import { useAuth } from '../custom-hooks/useAuth';
import { subpagesData as SUBPAGES_DATA } from '../pages/subpage-data';
import { useLocation } from 'react-router';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import EmptyState from './EmptyState';
import ErrorState from './ErrorState';

const { participantArchiveAction, participantDeleteAction } = dashboardActions;

function DashboardPageComponentData(props) {
    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        allParticipantData,
        allParticipantStatus,
        participantArchiveStatus,
        participantDeleteStatus,
        participantArchiveDispatchAction,
        participantDeleteDispatchAction,
    } = props;

    const location = useLocation();
    const subpagesObj = SUBPAGES_DATA.find(
        (subpage) => subpage.url === location.pathname
    );

    const [isOpened, setIsOpened] = useState(true);

    const [pageName, setPageName] = useState("jhjjkk")

    const [archiveType, setArchiveType] = useState(null);
    const [viewParticipantOpen, setViewParticipantOpen] = useState(false);
    const [editParticipantOpen, setEditParticipantOpen] = useState(false);
    const [editEnrollmentOpen, setEditEnrollmentOpen] = useState(false);
    const [editMediaOpen, setEditMediaOpen] = useState(false);
    const [editMedicalOpen, setEditMedicalOpen] = useState(false);
    const [editKitchenOpen, setEditKitchenOpen] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState({});
    const [selectedEnrollment, setSelectedEnrollment] = useState({});
    const [selectedMedia, setSelectedMedia] = useState({});
    const [selectedMedical, setSelectedMedical] = useState({});
    const [selectedKitchen, setSelectedKitchen] = useState({});
    const [archiveInfo, setArchiveInfo] = useState({
        archiveStatus: '',
        archiveId: null,
        archiveData: {},
        archiveType: '',
    });

    useEffect(() => {
    }, [pageName])

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredParticipants, setFilteredParticipants] =
        useState(allParticipantData);
    const [currentDataParticipant, setCurrentDataParticipant] = useState([]);
    const [currentDataArchive, setCurrentDataArchive] = useState([]);

    const toggleSidebar = () => {
        setIsOpened(!isOpened);
    };

    const handleViewParticipantOpen = (participant) => {
        setSelectedParticipant(participant);
        setViewParticipantOpen(true);
    };

    const handleViewParticipantClose = () => {
        setViewParticipantOpen(false);
        setSelectedParticipant(null);
    };

    const handleEditParticipantOpen = () => {
        setViewParticipantOpen(false);
        setEditParticipantOpen(true);
    };

    const handleEditParticipantClose = () => {
        setEditParticipantOpen(false);
    };

    const handleEditSubpageOpen = (subpage, data) => {
        switch (subpage) {
            case 'enrollment':
                setSelectedEnrollment(data);
                setEditEnrollmentOpen(true);
                break;
            case 'media':
                setSelectedMedia(data);
                setEditMediaOpen(true);
                break;
            case 'medical':
                setSelectedMedical(data);
                setEditMedicalOpen(true);
                break;
            case 'kitchen':
                setSelectedKitchen(data);
                setEditKitchenOpen(true);
                break;
            default:
                return null;
        }
    };
    const handleEditSubpageClose = (subpage) => {
        switch (subpage) {
            case 'enrollment':
                setEditEnrollmentOpen(false);
                break;
            case 'media':
                setEditMediaOpen(false);
                break;
            case 'medical':
                setEditMedicalOpen(false);
                break;
            case 'kitchen':
                setEditKitchenOpen(false);
                break;
            default:
                return null;
        }
    };

    useEffect(() => {}, [selectedParticipant]);
    useEffect(() => {}, [selectedEnrollment]);
    useEffect(() => {}, [selectedMedia]);
    useEffect(() => {}, [selectedMedical]);

    useEffect(() => {}, [allParticipantStatus]);

    const handleArchiveUpdate = (archiveData) => {
        setArchiveInfo((prev) => ({
            ...prev,
            ...archiveData,
        }));
    };

    // USE EFFECT WORK HERE TAKEN FROM PARTICIPANT PAGE
    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = allParticipantData.filter(
            (data) =>
                data.first_name.toLowerCase().includes(lowercasedFilter) ||
                data.last_name.toLowerCase().includes(lowercasedFilter)
        );
    
        let extractedData = [];
    
        if (
            subpagesObj.name === 'participants' ||
            subpagesObj.name === 'dashboard'
        ) {
            extractedData = filteredData.filter((item) => item.is_archived === 'no');
        } else if (
            subpagesObj.name === 'archives' ||
            subpagesObj.name === 'dashboard'
        ) {
            extractedData = filteredData.filter((item) => item.is_archived === 'yes');
        }
    
        if (
            archiveInfo &&
            archiveInfo.archiveId &&
            participantArchiveStatus === 'participant_archive_success'
        ) {
            setTimeout(() => {
                setFilteredParticipants((prev) =>
                    prev.filter((item) => item.id !== archiveInfo.archiveId)
                );
            }, 2000);
        } else {
            setFilteredParticipants(extractedData);
        }
    
        if (currentPage > Math.ceil(extractedData.length / itemsPerPage)) {
            setCurrentPage(1);
        }
    }, [
        searchTerm,
        allParticipantData,
        archiveInfo.archiveId,
        participantArchiveStatus,
        allParticipantStatus,
        subpagesObj.name,  // Adding this to the dependencies
        currentPage,  // Adding this to the dependencies
        itemsPerPage  // Adding this to the dependencies
    ]);
    

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentPageData = filteredParticipants.slice(
            startIndex,
            endIndex
        );
        if (
            subpagesObj.name === 'participants' ||
            subpagesObj.name === 'dashboard'
        ) {
            setCurrentDataParticipant(
                currentPageData.filter((item) => item.is_archived === 'no')
            );
        } else if (
            subpagesObj.name === 'archives' ||
            subpagesObj.name === 'dashboard'
        ) {
            setCurrentDataArchive(
                currentPageData.filter((item) => item.is_archived === 'yes')
            );
        }
    }, [
        currentPage,
        itemsPerPage,
        filteredParticipants,
        archiveInfo,
        participantArchiveStatus,
    ]);

    const handlePerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const totalItems = filteredParticipants.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    // --> END

    // Archive and Delete participant record method here
    const archiveParticipant = (type) => {
        setArchiveType(type);
        Swal.fire({
            title: `${t('swal-messages.Are you sure')}?`,
            text: `${t('swal-messages.Do you really want to')} ${
                type === 'yes'
                    ? `${t('swal-messages.Archive')}`
                    : `${t('swal-messages.Unarchive')}`
            } ${t('swal-messages.This participant')}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `${t('swal-messages.Yes, do it')}!`,
            cancelButtonText: `${t('swal-messages.No, cancel')}!`,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#FDB315',
            customClass: {
                confirmButton: 'custom-confirm-button-class',
                cancelButton: 'custom-cancel-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const params = {
                    publicKey: process.env.REACT_APP_PUB_KEY_1,
                    formData: {
                        is_archived: type,
                    },
                };

                encryptUtility(params).then((encryptedData) => {
                    const form_values = {
                        id: selectedParticipant && selectedParticipant.id,
                        token:
                            loginStorage && loginStorage.loginData.data.token,
                        ...encryptedData,
                    };

                    participantArchiveDispatchAction(
                        form_values,
                        selectedParticipant
                    );
                });
            }
        });
    };

    const deleteParticipant = (e) => {
        e.preventDefault();
        Swal.fire({
            title: `${t('swal-messages.Are you sure')}?`,
            text: `${t('swal-messages.Do you really want to')} ${t(
                'swal-messages.Delete'
            )} ${t('swal-messages.This participant')}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `${t('swal-messages.Yes, do it')}!`,
            cancelButtonText: `${t('swal-messages.No, cancel')}!`,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#FDB315',
            customClass: {
                confirmButton: 'custom-confirm-button-class',
                cancelButton: 'custom-cancel-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const form_values = {
                    id: selectedParticipant && selectedParticipant.id,
                    token: loginStorage && loginStorage.loginData.data.token,
                };
                participantDeleteDispatchAction(form_values);
            }
        });
    };

    const handlePageClick = (pageName) => {
        switch(pageName) {
            case "dashboard":
                window.location.assign("/dashboard");
            break;
            case "participants":
                window.location.assign("/participants");
            break;
            case "enrollments":
                window.location.assign("/participants/enrollments");
            break;
            case "kitchen":
                window.location.assign("/participants/kitchen");
            break;
            case "media":
                window.location.assign("/participants/media");
            break;
            case "medical":
                window.location.assign("/participants/medical");
            break;
            case "archive":
                window.location.assign("/participants/archive");
            break;
            default: 
            break;
        }
    }

    const dashboardSubpageProps = {
        archiveProps: {
            allParticipantData,
            allParticipantStatus,
            participantArchiveStatus,
            participantDeleteStatus,
            archiveParticipant,
            deleteParticipant,
            archiveType,
            setArchiveType,
            handleViewParticipantOpen,
            handleViewParticipantClose,
            viewParticipantOpen,
            selectedParticipant,
            setSearchTerm,
            searchTerm,
            setCurrentDataArchive,
            setCurrentDataParticipant,
            handlePerPageChange,
            handlePageChange,
            totalPages,
            currentDataArchive,
            currentDataParticipant,
            filteredParticipants,
            handleArchiveUpdate,
            itemsPerPage,
            currentPage,
            t,
            // participantArchiveSuccessData
        },
        participantProps: {
            allParticipantData,
            allParticipantStatus,
            participantArchiveStatus,
            archiveParticipant,
            archiveType,
            setArchiveType,
            handleViewParticipantOpen,
            handleViewParticipantClose,
            viewParticipantOpen,
            selectedParticipant,
            handleEditParticipantOpen,
            handleEditParticipantClose,
            editParticipantOpen,
            setSearchTerm,
            searchTerm,
            setCurrentDataParticipant,
            setCurrentDataArchive,
            handlePerPageChange,
            handlePageChange,
            totalPages,
            currentDataParticipant,
            currentDataArchive,
            filteredParticipants,
            handleArchiveUpdate,
            itemsPerPage,
            currentPage,
            t,
            // participantArchiveSuccessData
        },
        dashboardProps: {
            allParticipantData,
            allParticipantStatus,
            currentDataParticipant,
            archiveType,
            t,
        },
        enrollmentProps: {
            allParticipantData,
            allParticipantStatus,
            t,
            handleEditSubpageOpen,
            handleEditSubpageClose,
            editEnrollmentOpen,
            selectedEnrollment,
            pageName,
        },
        kitchenProps: {
            allParticipantData,
            allParticipantStatus,
            t,
            handleEditSubpageOpen,
            handleEditSubpageClose,
            editKitchenOpen,
            selectedKitchen,
            pageName,
        },
        medicalProps: {
            allParticipantData,
            allParticipantStatus,
            t,
            handleEditSubpageOpen,
            handleEditSubpageClose,
            editMedicalOpen,
            selectedMedical,
            pageName,
        },
        mediaProps: {
            allParticipantData,
            allParticipantStatus,
            t,
            handleEditSubpageOpen,
            handleEditSubpageClose,
            editMediaOpen,
            selectedMedia,
            pageName,
        },
    };

    if (allParticipantStatus === "all_participant_fetch_start") {
        return (
            <main className="bg-[#F9F9F9] font-sans flex relative min-h-screen">
                <SideDrawer isOpened={isOpened} toggleSidebar={toggleSidebar} handlePageClick={handlePageClick} />
                <Loader allParticipantStatus={allParticipantStatus} />
            </main>
        );
    }

    if (allParticipantStatus === "all_participant_fetch_failed" ) {
        return (
            <main className="bg-[#F9F9F9] font-sans flex relative min-h-screen">
                <SideDrawer isOpened={isOpened} toggleSidebar={toggleSidebar} handlePageClick={handlePageClick} />
                <ErrorState />
            </main>
        );
    }
    
    return (
        <main className="bg-[#F9F9F9] font-sans flex">
            <SideDrawer isOpened={isOpened} toggleSidebar={toggleSidebar} handlePageClick={handlePageClick} />
            <DashboardSubpages {...dashboardSubpageProps} />
        </main>
    );
}

const areEqual = (prevProps, nextProps) => {
    return (
        (prevProps.allParticipantData === nextProps.allParticipantData) &&
        (prevProps.participantArchiveStatus === nextProps.participantArchiveStatus) &&
        (prevProps.participantDeleteStatus === nextProps.participantDeleteStatus) &&
        (prevProps.allParticipantStatus === nextProps.allParticipantStatus)
    );
};

const DashboardCompMemo = React.memo(DashboardPageComponentData, areEqual);

function DashboardPageComponent(props) {
    const {
        allParticipantData,
        allParticipantStatus,
        participantArchiveStatus,
        participantDeleteStatus,
        participantArchiveDispatchAction,
        participantDeleteDispatchAction,
    } = props;

    return (
        <>
            <DashboardCompMemo
                allParticipantData={allParticipantData}
                allParticipantStatus={allParticipantStatus}
                participantArchiveStatus={participantArchiveStatus}
                participantDeleteStatus={participantDeleteStatus}
                participantArchiveDispatchAction={
                    participantArchiveDispatchAction
                }
                participantDeleteDispatchAction={
                    participantDeleteDispatchAction
                }
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    participantArchiveStatus: state.dashboardState.participantArchiveStatus,
    participantDeleteStatus: state.dashboardState.participantDeleteStatus,
});

const mapDispatchToProps = (dispatch) => ({
    participantArchiveDispatchAction: (params, selectedParticipant) =>
        dispatch(participantArchiveAction(params, selectedParticipant)),
    participantDeleteDispatchAction: (params) =>
        dispatch(participantDeleteAction(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPageComponent);
