import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormatDateString } from '../../utils/FormatDate';

const Card = ({ first_name, last_name, gender, is_fee_paid, agreement_signed, email, mobile, landline, date_of_birth, confirmation_email_sent}) => {
  const {t} = useTranslation();

    return (
      <div className=' bg-white rounded-3xl shadow-md px-5 flex justify-between break-inside-avoid'>
        <div className='flex mr-1 flex-col py-4 gap-2 w-[32%]'>
          <h1 className='text-tertiary print:text-black font-medium text-[1.05rem]'>
            {first_name} {last_name}
          </h1>
          <div className='flex flex-col gap-1'>
            <div className='flex items-center gap-3'>
              <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Gender')}</h3>
              <p className='text-secondary text-[0.95rem] font-medium'>{gender}</p>
            </div>
            <div className='flex items-center gap-3'>
              <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Date of birth')}</h3>
              <p className='text-secondary text-[0.95rem] font-medium'>{FormatDateString(date_of_birth)}</p>
            </div>
          </div>
        </div>
        <div className='py-4 h-full border-r-[1.5px] border-l-[1.5px] px-4 border-r-gray-100 border-l-gray-100 flex flex-col gap-2 w-[34%]'>
          <div className='flex items-center gap-x-3 flex-wrap'>
            <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Email')}</h3>
            <p className='text-secondary text-[0.95rem] font-medium max-w-full break-all'> {email} </p>
          </div>
          <div className='flex items-center gap-3'>
            <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Mobile')}</h3>
            <p className='text-secondary text-[0.95rem] font-medium'>{mobile}</p>
          </div>
          <div className='flex items-center gap-3'>
            <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Landline')}</h3>
            <p className='text-secondary text-[0.95rem] font-medium'>{landline}</p>
          </div>
        </div>
        <div className='h-full ml-4 py-4 w-[33%] flex flex-col gap-2'>
          <div className='flex items-center gap-3'>
            <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Fee Paid')}</h3>
            <div>
              {is_fee_paid === "yes" ?
                (<svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon" clip-path="url(#clip0_878_15569)">
                  <path id="Vector" d="M19 9.7002C19 12.2198 17.9991 14.6361 16.2175 16.4177C14.4359 18.1993 12.0196 19.2002 9.5 19.2002C6.98044 19.2002 4.56408 18.1993 2.78249 16.4177C1.00089 14.6361 0 12.2198 0 9.7002C0 7.18064 1.00089 4.76428 2.78249 2.98268C4.56408 1.20109 6.98044 0.200195 9.5 0.200195C12.0196 0.200195 14.4359 1.20109 16.2175 2.98268C17.9991 4.76428 19 7.18064 19 9.7002ZM14.2856 6.10207C14.2008 6.01754 14.0998 5.95099 13.9887 5.90639C13.8775 5.86179 13.7585 5.84006 13.6388 5.8425C13.5191 5.84494 13.4011 5.87149 13.2919 5.92058C13.1826 5.96967 13.0844 6.04028 13.0031 6.1282L8.87894 11.3829L6.3935 8.89626C6.22467 8.73894 6.00136 8.65329 5.77063 8.65736C5.5399 8.66143 5.31975 8.7549 5.15657 8.91808C4.9934 9.08126 4.89993 9.3014 4.89585 9.53214C4.89178 9.76287 4.97743 9.98618 5.13475 10.155L8.27687 13.2983C8.36152 13.3828 8.46232 13.4494 8.57326 13.4941C8.68419 13.5388 8.80299 13.5607 8.92257 13.5585C9.04215 13.5562 9.16006 13.53 9.26926 13.4812C9.37847 13.4324 9.47673 13.3621 9.55819 13.2746L14.2987 7.34895C14.4603 7.18091 14.5496 6.95621 14.5474 6.72308C14.5451 6.48996 14.4516 6.26699 14.2868 6.10207H14.2856Z" fill="#007462"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_878_15569">
                  <rect width="19.2" height="19.2" fill="white" transform="translate(0 0.200195)"/>
                  </clipPath>
                  </defs>
                </svg>) :
                (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                  <path d="M9.60083 1.7998C14.0008 1.7998 17.6008 5.3998 17.6008 9.7998C17.6008 14.1998 14.0008 17.7998 9.60083 17.7998C5.20083 17.7998 1.60083 14.1998 1.60083 9.7998C1.60083 5.3998 5.20083 1.7998 9.60083 1.7998ZM9.60083 3.3998C8.08083 3.3998 6.72083 3.8798 5.68083 4.7598L14.6408 13.7198C15.4408 12.5998 16.0008 11.2398 16.0008 9.7998C16.0008 6.2798 13.1208 3.3998 9.60083 3.3998ZM13.5208 14.8398L4.56083 5.8798C3.68083 6.9198 3.20083 8.2798 3.20083 9.7998C3.20083 13.3198 6.08083 16.1998 9.60083 16.1998C11.1208 16.1998 12.4808 15.7198 13.5208 14.8398Z" fill="#EF4444"/>
                </svg>
              )}
            </div>
          </div>
          <div className='flex items-center gap-3'>
            <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Agreement Signed')}</h3>
            <div>
              {agreement_signed === "yes" ?
                (<svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon" clip-path="url(#clip0_878_15569)">
                  <path id="Vector" d="M19 9.7002C19 12.2198 17.9991 14.6361 16.2175 16.4177C14.4359 18.1993 12.0196 19.2002 9.5 19.2002C6.98044 19.2002 4.56408 18.1993 2.78249 16.4177C1.00089 14.6361 0 12.2198 0 9.7002C0 7.18064 1.00089 4.76428 2.78249 2.98268C4.56408 1.20109 6.98044 0.200195 9.5 0.200195C12.0196 0.200195 14.4359 1.20109 16.2175 2.98268C17.9991 4.76428 19 7.18064 19 9.7002ZM14.2856 6.10207C14.2008 6.01754 14.0998 5.95099 13.9887 5.90639C13.8775 5.86179 13.7585 5.84006 13.6388 5.8425C13.5191 5.84494 13.4011 5.87149 13.2919 5.92058C13.1826 5.96967 13.0844 6.04028 13.0031 6.1282L8.87894 11.3829L6.3935 8.89626C6.22467 8.73894 6.00136 8.65329 5.77063 8.65736C5.5399 8.66143 5.31975 8.7549 5.15657 8.91808C4.9934 9.08126 4.89993 9.3014 4.89585 9.53214C4.89178 9.76287 4.97743 9.98618 5.13475 10.155L8.27687 13.2983C8.36152 13.3828 8.46232 13.4494 8.57326 13.4941C8.68419 13.5388 8.80299 13.5607 8.92257 13.5585C9.04215 13.5562 9.16006 13.53 9.26926 13.4812C9.37847 13.4324 9.47673 13.3621 9.55819 13.2746L14.2987 7.34895C14.4603 7.18091 14.5496 6.95621 14.5474 6.72308C14.5451 6.48996 14.4516 6.26699 14.2868 6.10207H14.2856Z" fill="#007462"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_878_15569">
                  <rect width="19.2" height="19.2" fill="white" transform="translate(0 0.200195)"/>
                  </clipPath>
                  </defs>
                </svg>) :
                (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                  <path d="M9.60083 1.7998C14.0008 1.7998 17.6008 5.3998 17.6008 9.7998C17.6008 14.1998 14.0008 17.7998 9.60083 17.7998C5.20083 17.7998 1.60083 14.1998 1.60083 9.7998C1.60083 5.3998 5.20083 1.7998 9.60083 1.7998ZM9.60083 3.3998C8.08083 3.3998 6.72083 3.8798 5.68083 4.7598L14.6408 13.7198C15.4408 12.5998 16.0008 11.2398 16.0008 9.7998C16.0008 6.2798 13.1208 3.3998 9.60083 3.3998ZM13.5208 14.8398L4.56083 5.8798C3.68083 6.9198 3.20083 8.2798 3.20083 9.7998C3.20083 13.3198 6.08083 16.1998 9.60083 16.1998C11.1208 16.1998 12.4808 15.7198 13.5208 14.8398Z" fill="#EF4444"/>
                </svg>
              )}
            </div>
          </div>
          <div className='flex items-center gap-3'>
            <h3 className='text-gray-400 font-medium text-[0.9rem]'>{t('enrollments-page.Email Confirmation Sent')}</h3>
            <div>
              {confirmation_email_sent === "yes" ?
                (<svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon" clip-path="url(#clip0_878_15569)">
                  <path id="Vector" d="M19 9.7002C19 12.2198 17.9991 14.6361 16.2175 16.4177C14.4359 18.1993 12.0196 19.2002 9.5 19.2002C6.98044 19.2002 4.56408 18.1993 2.78249 16.4177C1.00089 14.6361 0 12.2198 0 9.7002C0 7.18064 1.00089 4.76428 2.78249 2.98268C4.56408 1.20109 6.98044 0.200195 9.5 0.200195C12.0196 0.200195 14.4359 1.20109 16.2175 2.98268C17.9991 4.76428 19 7.18064 19 9.7002ZM14.2856 6.10207C14.2008 6.01754 14.0998 5.95099 13.9887 5.90639C13.8775 5.86179 13.7585 5.84006 13.6388 5.8425C13.5191 5.84494 13.4011 5.87149 13.2919 5.92058C13.1826 5.96967 13.0844 6.04028 13.0031 6.1282L8.87894 11.3829L6.3935 8.89626C6.22467 8.73894 6.00136 8.65329 5.77063 8.65736C5.5399 8.66143 5.31975 8.7549 5.15657 8.91808C4.9934 9.08126 4.89993 9.3014 4.89585 9.53214C4.89178 9.76287 4.97743 9.98618 5.13475 10.155L8.27687 13.2983C8.36152 13.3828 8.46232 13.4494 8.57326 13.4941C8.68419 13.5388 8.80299 13.5607 8.92257 13.5585C9.04215 13.5562 9.16006 13.53 9.26926 13.4812C9.37847 13.4324 9.47673 13.3621 9.55819 13.2746L14.2987 7.34895C14.4603 7.18091 14.5496 6.95621 14.5474 6.72308C14.5451 6.48996 14.4516 6.26699 14.2868 6.10207H14.2856Z" fill="#007462"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_878_15569">
                  <rect width="19.2" height="19.2" fill="white" transform="translate(0 0.200195)"/>
                  </clipPath>
                  </defs>
                </svg>) :
                (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                  <path d="M9.60083 1.7998C14.0008 1.7998 17.6008 5.3998 17.6008 9.7998C17.6008 14.1998 14.0008 17.7998 9.60083 17.7998C5.20083 17.7998 1.60083 14.1998 1.60083 9.7998C1.60083 5.3998 5.20083 1.7998 9.60083 1.7998ZM9.60083 3.3998C8.08083 3.3998 6.72083 3.8798 5.68083 4.7598L14.6408 13.7198C15.4408 12.5998 16.0008 11.2398 16.0008 9.7998C16.0008 6.2798 13.1208 3.3998 9.60083 3.3998ZM13.5208 14.8398L4.56083 5.8798C3.68083 6.9198 3.20083 8.2798 3.20083 9.7998C3.20083 13.3198 6.08083 16.1998 9.60083 16.1998C11.1208 16.1998 12.4808 15.7198 13.5208 14.8398Z" fill="#EF4444"/>
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Card