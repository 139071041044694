import React from 'react'
import emptyDashboardImage from "../images/empty-dashboard-image.png"
import { useTranslation } from 'react-i18next'

function EmptyState() {
  const { t } = useTranslation()
  return (
    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 items-center'>
        <img src={emptyDashboardImage} alt="No participants" />
        <h1 className="font-semibold text-xl text-primary">{t('dashboard-page.No data found')}</h1>
        {/* <p className="text-secondary font-medium">Create some records to view them here</p> */}
    </div>
  )
}

export default EmptyState
