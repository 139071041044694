import React from 'react';
import Login from './login';

export default function AuthLayout(props) {

    const { children, ...rest } = props;

    return (
        <section className="w-full h-screen flex">
            <img className="w-[48%] border-black h-auto object-cover object-bottom" src="/assets/images/login-image.png" />

            <div className="w-[50%] h-full flex items-center justify-center">
                <div className="h-full w-[55%] flex items-center justify-center">
                    <div className="w-full">
                        <span class="flex items-center gap-5 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 44 44"
                                fill="none">
                                <path
                                    d="M37.1112 41.25H6.88875C5.79179 41.2493 4.73992 40.8134 3.96399 40.038C3.18806 39.2625 2.75146 38.211 2.75 37.114V26.1222C2.75 24.6125 3.97375 23.375 5.5 23.375H38.5C39.2291 23.375 39.9284 23.6645 40.4441 24.18C40.9597 24.6954 41.2496 25.3945 41.25 26.1236V37.114C41.2464 38.2103 40.8091 39.2606 40.0336 40.0356C39.2582 40.8105 38.2075 41.2471 37.1112 41.25Z"
                                    fill="#608842" />
                                <path
                                    d="M34.1055 20.418H30.5318C30.38 20.418 30.2568 20.5411 30.2568 20.693V25.8423C30.2568 25.9942 30.38 26.1173 30.5318 26.1173H34.1055C34.2573 26.1173 34.3805 25.9942 34.3805 25.8423V20.693C34.3805 20.5411 34.2573 20.418 34.1055 20.418Z"
                                    fill="#8C5543" />
                                <path
                                    d="M36.3893 6.64982C36.32 5.58654 35.8428 4.59108 35.0573 3.87119C34.2717 3.15129 33.2385 2.76258 32.1732 2.78617C31.108 2.80976 30.093 3.24384 29.34 3.99781C28.5871 4.75178 28.1545 5.76739 28.1324 6.83269C28.109 6.99769 28.0664 7.15169 27.9124 7.22457C27.3251 7.34512 26.771 7.592 26.2887 7.94808C25.8063 8.30417 25.4072 8.76092 25.119 9.28667C24.8308 9.81241 24.6605 10.3946 24.6198 10.9927C24.5791 11.5909 24.669 12.1907 24.8833 12.7507C24.886 12.7644 24.8901 12.7782 24.897 12.7947C24.9338 12.8955 24.9431 13.0042 24.9241 13.1098C24.905 13.2154 24.8581 13.314 24.7884 13.3956C24.1588 13.9448 23.7105 14.672 23.5027 15.4812C23.2949 16.2904 23.3372 17.1436 23.6242 17.9283C23.9111 18.7129 24.4292 19.3922 25.1101 19.8764C25.791 20.3605 26.6026 20.627 27.438 20.6404H37.2294C38.0748 20.6339 38.8978 20.3682 39.5875 19.8792C40.2771 19.3902 40.8001 18.7014 41.086 17.9057C41.3718 17.1101 41.4067 16.2459 41.186 15.4298C40.9652 14.6137 40.4995 13.885 39.8515 13.3419C39.6178 13.0986 39.6755 12.7727 39.7429 12.5348C39.7543 12.4997 39.7653 12.4644 39.7759 12.4289L39.7828 12.4097C39.7946 12.3862 39.8012 12.3604 39.802 12.3341C39.9427 11.8012 39.9756 11.2455 39.8987 10.6997C39.8218 10.1539 39.6366 9.62899 39.354 9.15574C39.0715 8.68248 38.6973 8.27043 38.2533 7.94376C37.8094 7.6171 37.3046 7.3824 36.7688 7.25344C36.5281 7.17507 36.4044 6.83957 36.3893 6.64982Z"
                                    fill="#96C34A" />
                                <path
                                    d="M10.7347 11.8859C11.7371 11.8859 12.5497 11.0733 12.5497 10.0709C12.5497 9.06846 11.7371 8.25586 10.7347 8.25586C9.73228 8.25586 8.91968 9.06846 8.91968 10.0709C8.91968 11.0733 9.73228 11.8859 10.7347 11.8859Z"
                                    fill="#D3D3D3" />
                                <path
                                    d="M11.3863 8.85813C10.9339 9.57863 10.6479 9.61438 10.1213 9.41226C9.83531 9.34076 9.57681 9.20051 8.92231 9.20051C7.39331 9.20051 6.07056 10.493 6.07056 12.2365C6.07056 13.9814 7.25168 15.1804 9.57681 15.1804H15.1249C17.4047 15.1804 19.0299 13.2801 19.0299 10.8615C19.0299 8.44151 16.8052 6.91113 14.8389 6.91113C12.8727 6.91113 11.8387 8.13901 11.3863 8.85813Z"
                                    fill="#E6E6E6" />
                                <path
                                    d="M6.2576 33.6151C5.52197 35.4769 6.6041 35.7794 7.42772 35.7794H35.4722C36.476 35.7794 36.9132 34.9626 36.432 33.7361C36.1735 33.0761 32.0801 22.8695 30.1812 17.8535H13.1587C12.4795 17.8535 12.3186 18.321 12.0436 18.9851C11.7686 19.6493 6.99322 31.7534 6.2576 33.6151Z"
                                    fill="#F9C23C" />
                                <path
                                    d="M17.926 32.9829C16.4553 29.3622 14.9941 25.7377 13.5425 22.1094V35.781H20.654C19.1112 35.781 18.7111 34.8859 17.926 32.9829Z"
                                    fill="#433B6B" />
                                <path
                                    d="M13.5575 22.1099C13.1734 21.1683 12.7957 20.2241 12.4245 19.2774C12.3241 19.0024 12.188 18.6257 12.3378 18.3424V18.3369C12.2396 18.5181 12.1532 18.7055 12.0793 18.8979L12.0436 18.9845C11.8263 19.5084 8.80822 27.152 7.18297 31.2715L6.2576 33.6145C5.52197 35.4763 6.6041 35.7788 7.42772 35.7788H13.1188V23.5578L13.5575 22.1099Z"
                                    fill="#ED9200" />
                                <path
                                    d="M13.5769 35.7777V22.1006C11.9324 26.6518 8.87574 34.8771 8.75062 35.2071C8.62687 35.5385 8.44812 35.7255 8.37524 35.7777H13.5769Z"
                                    fill="#FF6723" />
                                <path
                                    d="M22.5128 22.0088C21.377 22.0088 21.0126 22.6647 21.366 23.6327C21.4898 24.0617 21.8349 25.1438 22.1773 26.0664C22.6063 27.2214 23.2264 27.517 24.2508 27.517H26.5965C27.7323 27.517 27.8932 26.7195 27.6512 26.0664C27.4092 25.4147 26.9815 24.2418 26.646 23.3673C26.3105 22.4928 25.6588 22.0088 24.6344 22.0088H22.5128Z"
                                    fill="#FF822D" />
                            </svg>
                            <a href="/">
                                <h5 className="font-bold text-[#FF6723] text-2xl">Camp Management</h5>
                            </a>
                        </span>
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}