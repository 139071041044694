import React, { useState } from 'react'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useAuth } from '../../custom-hooks/useAuth';

const Card = ({ id, name, email, username, company, deleteUserDispatchAction, handleUpdateUserModalOpen }) => {

    const ReactSwal = withReactContent(Swal);
  
    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());
  
    const [isMenuVisible, setIsMenuVisible] = useState(false);
  
    const toggleMenuVisibility = () => {
      setIsMenuVisible(!isMenuVisible);
    };
  
    const handleDeleteUser = (e, id) => {
      e.preventDefault();
  
      ReactSwal.fire({
        title: 'Wait!',
        text: 'Do you really want to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      .then(result => {
        if(result.isConfirmed) {
          const params = {
            id,
            token: loginStorage && loginStorage.loginData.data.token
          }
          deleteUserDispatchAction(params);
        }else if(result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your action has been cancelled.',
            'error'
          )
        }
      })
    }
  
    return (
      <div className='relative bg-white rounded-2xl py-4 px-5'>
        <div className='flex justify-between items-center py-4 border-b font-medium'>
          <span className='flex items-center gap-1'>
            <h1 className='text-tertiary text-[1.05rem]'>{name}</h1>
          </span>
          <svg
            className='cursor-pointer'
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={toggleMenuVisibility}
          >
            <path d="M10 4.625C9.83424 4.625 9.67527 4.69085 9.55806 4.80806C9.44085 4.92527 9.375 5.08424 9.375 5.25C9.375 5.41576 9.44085 5.57473 9.55806 5.69194C9.67527 5.80915 9.83424 5.875 10 5.875C10.1658 5.875 10.3247 5.80915 10.4419 5.69194C10.5592 5.57473 10.625 5.41576 10.625 5.25C10.625 5.08424 10.5592 4.92527 10.4419 4.80806C10.3247 4.69085 10.1658 4.625 10 4.625ZM10 9C9.83424 9 9.67527 9.06585 9.55806 9.18306C9.44085 9.30027 9.375 9.45924 9.375 9.625C9.375 9.79076 9.44085 9.94973 9.55806 10.0669C9.67527 10.1842 9.83424 10.25 10 10.25C10.1658 10.25 10.3247 10.1842 10.4419 10.0669C10.5592 9.94973 10.625 9.79076 10.625 9.625C10.625 9.45924 10.5592 9.30027 10.4419 9.18306C10.3247 9.06585 10.1658 9 10 9ZM10 13.375C9.83424 13.375 9.67527 13.4408 9.55806 13.5581C9.44085 13.6753 9.375 13.8342 9.375 14C9.375 14.1658 9.44085 14.3247 9.55806 14.4419C9.67527 14.5592 9.83424 14.625 10 14.625C10.1658 14.625 10.3247 14.5592 10.4419 14.4419C10.5592 14.3247 10.625 14.1658 10.625 14C10.625 13.8342 10.5592 13.6753 10.4419 13.5581C10.3247 13.4408 10.1658 13.375 10 13.375Z" stroke="#2F4858" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div>
          <div className='flex flex-col py-4 border-b gap-3'>
            <span className='flex justify-between items-center flex-wrap'>
              <h1 className='text-[#2F485899] font-medium'>Email Address</h1>
              <p className='text-secondary font-medium'>{email}</p>
            </span>
            <span className='flex justify-between items-center'>
              <h1 className='text-[#2F485899] font-medium'>Company</h1>
              <p className='text-secondary line-clamp-1 font-medium'>{company}</p>
            </span>
          </div>
        </div>
        <div className='py-4 flex items-center justify-center'>
          <button className='bg-none text-sm border-2 border-primary rounded-full h-10 w-[90%] text-primary font-medium' onClick={(userInfo) => handleUpdateUserModalOpen({
            updateId: id,
            name,
            username,
            email
          })}>
            Reset Password
          </button>
          {/* <button className='bg-primary rounded-full h-10 w-[48%] text-white font-medium text-sm'>
            Permissions
          </button> */}
        </div>
        {isMenuVisible && (
          <div className='absolute px-2 top-14 right-5 bg-primary text-white h-24 w-36 rounded-lg flex flex-col items-center justify-center'>
            <span className='w-full flex items-center gap-2 py-2 cursor-pointer'>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                <path d="M16.25 10C16.25 9.83424 16.3158 9.67527 16.4331 9.55806C16.5503 9.44085 16.7092 9.375 16.875 9.375C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H3.125C2.95924 17.5 2.80027 17.4342 2.68306 17.3169C2.56585 17.1997 2.5 17.0408 2.5 16.875V3.125C2.5 2.95924 2.56585 2.80027 2.68306 2.68306C2.80027 2.56585 2.95924 2.5 3.125 2.5H10C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125C10.625 3.29076 10.5592 3.44973 10.4419 3.56694C10.3247 3.68415 10.1658 3.75 10 3.75H3.75V16.25H16.25V10Z" fill="white"/>
                <path d="M9.17873 10.8251L10.21 10.6776L16.545 4.34382C16.6047 4.28616 16.6523 4.2172 16.685 4.14095C16.7178 4.06469 16.735 3.98268 16.7358 3.89969C16.7365 3.81671 16.7207 3.73441 16.6892 3.6576C16.6578 3.58079 16.6114 3.511 16.5527 3.45232C16.494 3.39364 16.4243 3.34723 16.3475 3.3158C16.2706 3.28438 16.1883 3.26857 16.1054 3.26929C16.0224 3.27001 15.9404 3.28725 15.8641 3.32001C15.7878 3.35276 15.7189 3.40037 15.6612 3.46007L9.32498 9.79382L9.17748 10.8251H9.17873ZM17.4287 2.57507C17.603 2.74919 17.7412 2.95595 17.8355 3.18351C17.9298 3.41107 17.9783 3.65499 17.9783 3.90132C17.9783 4.14765 17.9298 4.39156 17.8355 4.61913C17.7412 4.84669 17.603 5.05344 17.4287 5.22757L10.9475 11.7088C10.8519 11.8047 10.7278 11.867 10.5937 11.8863L8.53123 12.1813C8.43511 12.1951 8.3371 12.1863 8.24496 12.1557C8.15283 12.125 8.06911 12.0733 8.00044 12.0046C7.93178 11.9359 7.88006 11.8522 7.84938 11.7601C7.81871 11.668 7.80993 11.5699 7.82373 11.4738L8.11873 9.41132C8.13767 9.27744 8.19951 9.15332 8.29498 9.05757L14.7775 2.57632C15.1291 2.22481 15.6059 2.02734 16.1031 2.02734C16.6003 2.02734 17.0771 2.22481 17.4287 2.57632V2.57507Z" fill="white"/>
              </svg>
              <p className='text-sm'>Edit User</p>
            </span>
            <span className='w-full flex items-center gap-2 py-2 border-t cursor-pointer border-[#2F485833]'>
               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                 <path d="M17.1538 4.46154H14.0769V3.84615C14.0769 3.35652 13.8824 2.88695 13.5362 2.54073C13.19 2.19451 12.7204 2 12.2308 2H8.53846C8.04883 2 7.57925 2.19451 7.23303 2.54073C6.88681 2.88695 6.69231 3.35652 6.69231 3.84615V4.46154H3.61538C3.45217 4.46154 3.29565 4.52637 3.18024 4.64178C3.06484 4.75719 3 4.91371 3 5.07692C3 5.24013 3.06484 5.39666 3.18024 5.51207C3.29565 5.62747 3.45217 5.69231 3.61538 5.69231H4.23077V16.7692C4.23077 17.0957 4.36044 17.4087 4.59125 17.6395C4.82207 17.8703 5.13512 18 5.46154 18H15.3077C15.6341 18 15.9472 17.8703 16.178 17.6395C16.4088 17.4087 16.5385 17.0957 16.5385 16.7692V5.69231H17.1538C17.3171 5.69231 17.4736 5.62747 17.589 5.51207C17.7044 5.39666 17.7692 5.24013 17.7692 5.07692C17.7692 4.91371 17.7044 4.75719 17.589 4.64178C17.4736 4.52637 17.3171 4.46154 17.1538 4.46154ZM9.15385 13.6923C9.15385 13.8555 9.08901 14.012 8.9736 14.1275C8.8582 14.2429 8.70167 14.3077 8.53846 14.3077C8.37525 14.3077 8.21873 14.2429 8.10332 14.1275C7.98791 14.012 7.92308 13.8555 7.92308 13.6923V8.76923C7.92308 8.60602 7.98791 8.4495 8.10332 8.33409C8.21873 8.21868 8.37525 8.15385 8.53846 8.15385C8.70167 8.15385 8.8582 8.21868 8.9736 8.33409C9.08901 8.4495 9.15385 8.60602 9.15385 8.76923V13.6923ZM12.8462 13.6923C12.8462 13.8555 12.7813 14.012 12.6659 14.1275C12.5505 14.2429 12.394 14.3077 12.2308 14.3077C12.0676 14.3077 11.911 14.2429 11.7956 14.1275C11.6802 14.012 11.6154 13.8555 11.6154 13.6923V8.76923C11.6154 8.60602 11.6802 8.4495 11.7956 8.33409C11.911 8.21868 12.0676 8.15385 12.2308 8.15385C12.394 8.15385 12.5505 8.21868 12.6659 8.33409C12.7813 8.4495 12.8462 8.60602 12.8462 8.76923V13.6923ZM12.8462 4.46154H7.92308V3.84615C7.92308 3.68294 7.98791 3.52642 8.10332 3.41101C8.21873 3.2956 8.37525 3.23077 8.53846 3.23077H12.2308C12.394 3.23077 12.5505 3.2956 12.6659 3.41101C12.7813 3.52642 12.8462 3.68294 12.8462 3.84615V4.46154Z" fill="white"/>
               </svg>
              <button className='text-sm' onClick={(e) => handleDeleteUser(e, id)}>Remove User</button>
            </span>
          </div>
        )}
      </div>
    );
  };

export default Card