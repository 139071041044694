import { Types as TYPES } from "../types";
import { Endpoints as ENDPOINTS } from "../endpoints";
import Swal from 'sweetalert2';

const actionType = TYPES.find(type => type.actionName === "userAction");
const { 
    ALL_USER_FETCH_START, 
    ALL_USER_FETCH_SUCCESS, 
    ALL_USER_FETCH_FAILED, 
    ALL_USER_FETCH_STOP,

    SINGLE_USER_FETCH_START, 
    SINGLE_USER_FETCH_SUCCESS, 
    SINGLE_USER_FETCH_FAILED, 
    SINGLE_USER_FETCH_STOP,

    USER_CREATE_START, 
    USER_CREATE_SUCCESS, 
    USER_CREATE_FAILED, 
    USER_CREATE_STOP,

    USER_UPDATE_START, 
    USER_UPDATE_SUCCESS, 
    USER_UPDATE_FAILED, 
    USER_UPDATE_STOP,

    USER_DELETE_START, 
    USER_DELETE_SUCCESS, 
    USER_DELETE_FAILED, 
    USER_DELETE_STOP,
} = actionType.types;

const { baseUrl, routes } = ENDPOINTS;


export const userActions = {
    
    allUserAction: (param) => dispatch => {
        dispatch({
            type: ALL_USER_FETCH_START
        })

        const { token } = param;
        
        const allUserUrl = `${baseUrl}${routes.get.user}`;
    
        fetch(allUserUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(res => {
            dispatch({
                type: ALL_USER_FETCH_SUCCESS,
                payload: res.data
            })
        })
        .catch(e => {
            dispatch({
                type: ALL_USER_FETCH_FAILED
            })
        })
    },
    singleUserAction: (params) => dispatch => {
        dispatch({
            type: SINGLE_USER_FETCH_START
        })

        const { token, id } = params;
        const singleUserUrl = `${baseUrl}${routes.get.user}/${id}`;
    
        fetch(singleUserUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(res => {
            dispatch({
                type: SINGLE_USER_FETCH_SUCCESS,
                payload: res.data
            })
        })
        .catch(e => {
            dispatch({
                type: SINGLE_USER_FETCH_FAILED
            })
        })
    },
    createUserAction: (params) => dispatch => {
        dispatch({
            type: USER_CREATE_START
        })

        const { user, token } = params;
        const createUserUrl = `${baseUrl}${routes.post.user}`;
    
        fetch(createUserUrl, {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(user),
            redirect: "follow",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(res => {
            dispatch({
                type: USER_CREATE_SUCCESS,
                payload: res.data
            })
        })
        .catch(e => {
            dispatch({
                type: USER_CREATE_FAILED
            })
        })
    },
    updateUserAction: (params) => dispatch => {
        dispatch({
            type: USER_UPDATE_START
        })

        const { user, id, token } = params;
        const updateUserUrl = `${baseUrl}${routes.update.user}`;
    
        fetch(updateUserUrl, {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(user),
            redirect: "follow",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(res => {
            dispatch({
                type: USER_UPDATE_SUCCESS,
                payload: res.data
            })
        })
        .catch(e => {
            dispatch({
                type: USER_UPDATE_FAILED
            })
        })
    },
    deleteUserAction: (params) => dispatch => {
        dispatch({
            type: USER_DELETE_START
        })

        const { id, token } = params;
        const deleteUserUrl = `${baseUrl}${routes.delete.user}/${id}`;
    
        fetch(deleteUserUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(res => {
            dispatch({
                type: USER_DELETE_SUCCESS,
                payload: res.data
            })
        })
        .catch(e => {
            dispatch({
                type: USER_DELETE_FAILED
            })
        })
    }
}