import Swal from "sweetalert2";

async function verifyKeys(keyParam) {
  try {
    const { privateKey, publicKey } = keyParam;

    const data = generateRandomText(10); // Assuming Random.generate() generates some data

    // Load private key
    const privateKeyPEM = `${privateKey}`;
    const privateKeyImported = await crypto.subtle.importKey(
      "pkcs8",
      base64ToUint8Array(privateKeyPEM),
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      true,
      ["decrypt"] // Updated key usage
    );

    // Load public key
    const publicKeyPEM = `${publicKey}`;
    const publicKeyImported = await crypto.subtle.importKey(
      "spki",
      base64ToUint8Array(publicKeyPEM),
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      true,
      ["encrypt"] // Updated key usage
    );

    // Test encryption and decryption

    const encrypted = await crypto.subtle.encrypt(
      { name: "RSA-OAEP", hash: { name: "SHA-256" } },
      publicKeyImported,
      str2ab(data)
    );

    // If encryption fails, keys don't match
    if (!encrypted) {
      throw new Error("Failed to encrypt data");
    }

    const decrypted = await crypto.subtle.decrypt(
      { name: "RSA-OAEP", hash: { name: "SHA-256" } },
      privateKeyImported,
      encrypted
    );
    const decryptedString = ab2str(decrypted);

    // Compare key details
    if (decryptedString === data) {
      return {
        privateKeyPEM,
        isKeyValid: true
      };
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error verifying keys:", error);
    Swal.fire({
      title: "Key verification failed",
      text: error,
      icon: "error",
    });
  }
}

// Utility functions to convert between string and ArrayBuffer
function str2ab(str) {
  const encoder = new TextEncoder();
  return encoder.encode(str);
}

function ab2str(buf) {
  const decoder = new TextDecoder();
  return decoder.decode(buf);
}

function stringToUint8Array(data) {
  return Uint8Array.from(data, (c) => c.charCodeAt(0));
}

function Uint8ArrayToString(data) {
  return String.fromCharCode.apply(null, data);
}

function binaryToBase64(data) {
  return btoa(data);
}

function base64ToBinary(data) {
  return atob(data);
}

function encodeData(data) {
  return new TextEncoder("utf-8").encode(data);
}

function decodeData(data) {
  return new TextDecoder("utf-8").decode(data);
}

function base64ToUint8Array(key) {
  const binaryKey = base64ToBinary(key);
  const Uint8ArrayKey = stringToUint8Array(binaryKey);
  return Uint8ArrayKey;
}

function generateRandomText(length) {
  const charset = "abcdefghijklmnopqrstuvwxyz0123456789"; // Lowercase letters and digits only
  let randomText = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomText += charset[randomIndex];
  }
  return randomText;
}

export default verifyKeys;
