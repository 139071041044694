import React, {} from 'react'
import { Box, Button, Typography, Modal } from '@mui/material';
import Loader from '../Loader';


export default function EditMedicalInfo (props) {

    const {
        handleEditMedicalClose, 
        editMedicalOpen,
        medicalUpdateStatus,
        style, 
        medical,
        handleMedicalSubmit,
        handleInputChange,
        updatedMedicalInput,
        errors,
        t
    } = props;


  return (
    <>
        <Modal
        open={editMedicalOpen}
        onClose={() => handleEditMedicalClose("medical")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='-z-50 relative'
        >
            <Box sx={style}>
            {/* {medicalUpdateStatus === "medical_update_start" && <Loader componentType="edit-participant-modal" />} */}
            <form  action="" onSubmit={handleMedicalSubmit}>
                <div className="w-full h-[65vh] rounded-lg bg-white p-10 pt-0 overflow-auto relative">
                    <div className="flex justify-between items-center w-auto bg-white h-20 sticky top-0"> 
                        <Typography 
                        variant="h4" 
                        sx={{ 
                            color: '#2F4858', 
                            fontWeight: '500',
                            fontSize: '28px' 
                        }}
                        >
                          {medical.first_name} {medical.last_name}
                        </Typography>
                        <div className="flex gap-5 items-center">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FDB315',
                                    px: 3,
                                    py: '0.59rem',
                                    borderRadius: '999px',
                                    color: 'white',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    '&:hover': {
                                    backgroundColor: '#FDB315',
                                    color: 'white',
                                    }
                                }}
                            >
                                {t('modals.Update')}
                            </Button>
                            <div onClick={() => handleEditMedicalClose("medical")} className='cursor-pointer bg-[#EFEFEF] hover:bg-[#eeeeff] w-10 h-10 flex items-center justify-center rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="size-4">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M18 6l-12 12" />
                                    <path d="M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="border rounded-lg p-5">
                            <h1 className="text-[16px] text-tertiary font-medium">{t('modals.Edit')} {t('modals.Medical Information')}</h1>
                            <div className="flex gap-5 mt-4">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Medication Allowed')}</label>
                                    <select name = "medication_allowed" value={updatedMedicalInput ? updatedMedicalInput.medication_allowed : ""} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedMedicalInput ? (updatedMedicalInput.medication_allowed === 'yes') : "N/A"}>Yes</option>
                                        <option value="no"  selected={updatedMedicalInput ? (updatedMedicalInput.medication_allowed === 'no') : "N/A"}>No</option>
                                    </select>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Tick Removal Allowed')}</label>
                                    <select name = "tick_removal_allowed" value={updatedMedicalInput ? updatedMedicalInput.tick_removal_allowed : ""} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedMedicalInput ? (updatedMedicalInput.tick_removal_allowed === 'yes') : "N/A"}>Yes</option>
                                        <option value="no"  selected={updatedMedicalInput ? (updatedMedicalInput.tick_removal_allowed === 'no') : "N/A"}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex gap-5 mt-3">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Vaccination Tetanus')}</label>
                                    <select name = "vaccination_tetanus" value={updatedMedicalInput ? updatedMedicalInput.vaccination_tetanus : ""} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedMedicalInput ? (updatedMedicalInput.vaccination_tetanus === 'yes') : "N/A"}>Yes</option>
                                        <option value="no"  selected={updatedMedicalInput ? (updatedMedicalInput.vaccination_tetanus === 'no') : "N/A"}>No</option>
                                    </select>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Vaccination Tetanus Year')}</label>
                                    <input type="text" onChange={handleInputChange}   name = "vaccination_tetanus_year" value={`${updatedMedicalInput ? updatedMedicalInput.vaccination_tetanus_year : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors.vaccination_tetanus_year && <span className="text-red-500 text-xs">{errors.vaccination_tetanus_year}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-5 mt-3">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Vaccination FSME')}</label>
                                    <select name = "vaccination_fsme" value={updatedMedicalInput ? updatedMedicalInput.vaccination_fsme : ""} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] ">
                                        <option value="">- SELECT-</option>
                                        <option value="yes" selected = {updatedMedicalInput ? (updatedMedicalInput.vaccination_fsme === 'yes') : "N/A"}>Yes</option>
                                        <option value="no"  selected={updatedMedicalInput ? (updatedMedicalInput.vaccination_fsme === 'no') : "N/A"}>No</option>
                                    </select>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Vaccination FSME Year')}</label>
                                    <input type="text" onChange={handleInputChange}   name = "vaccination_fsme_year" value={`${updatedMedicalInput ? updatedMedicalInput.vaccination_fsme_year : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors.vaccination_fsme_year && <span className="text-red-500 text-xs">{errors.vaccination_fsme_year}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-5 mt-4">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Father\'s Full Name')}</label>
                                    <input type="text" name = "father" value={`${updatedMedicalInput ? updatedMedicalInput.father : 'N/A'}`} onChange={handleInputChange} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors.father && <span className="text-red-500 text-xs">{errors.father}</span>}
                                    </div>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Mother\'s Full Name')}</label>
                                    <input type="text" onChange={handleInputChange} name  = "mother" value={`${updatedMedicalInput ? updatedMedicalInput.mother : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors.mother && <span className="text-red-500 text-xs">{errors.mother}</span>}
                                    </div>
                                </div>
                            </div>                               
                            <div className="flex gap-5 mt-3">
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Landline')}</label>
                                    <input type="text" onChange={handleInputChange}  name = "landline" value={`${updatedMedicalInput ? updatedMedicalInput.landline : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors.landline && <span className="text-red-500 text-xs">{errors.landline}</span>}
                                    </div>
                                </div>
                                <div className="flex flex-col w-[50%]">
                                    <label className="font-medium text-[14px]">{t('modals.Mobile')}</label>
                                    <input type="text" onChange={handleInputChange}  name = "mobile" value={`${updatedMedicalInput ? updatedMedicalInput.mobile : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                    <div>
                                        {errors.mobile && <span className="text-red-500 text-xs">{errors.mobile}</span>}
                                    </div>
                                </div>
                            </div> 
                            <div className="flex gap-5 mt-4">
                                    <div className="flex flex-col w-[50%]">
                                        <label className="font-medium text-[14px]">{t('modals.Emergency Contact')}</label>
                                        <input type="text" onChange={handleInputChange}  name = "father" value={`${updatedMedicalInput ? updatedMedicalInput.fallback_contact : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                        <div>
                                            {errors.father && <span className="text-red-500 text-xs">{errors.father}</span>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-[50%]">
                                        <label className="font-medium text-[14px]">{t('modals.Emergency Mobile')}</label>
                                        <input type="text" onChange={handleInputChange}  name = "fallback_number" value={`${updatedMedicalInput ? updatedMedicalInput.fallback_number : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                        <div>
                                            {errors.fallback_number && <span className="text-red-500 text-xs">{errors.fallback_number}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <div className="flex flex-col w-[50%]">
                                        <label className="font-medium text-[14px]">{t('modals.Medication')}</label>
                                        <input type="text" onChange={handleInputChange}  name = "medication" value={`${updatedMedicalInput ? updatedMedicalInput.medication : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                        <div>
                                            {errors.medication && <span className="text-red-500 text-xs">{errors.medication}</span>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-[50%]">
                                        <label className="font-medium text-[14px]">{t('modals.Allergies')}</label>
                                        <input type="text" onChange={handleInputChange}  name = "allergies" value={`${updatedMedicalInput ? updatedMedicalInput.allergies : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                        <div>
                                            {errors.allergies && <span className="text-red-500 text-xs">{errors.allergies}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <div className="flex flex-col w-[50%]">
                                        <label className="font-medium text-[14px]">{t('modals.Food Intolerance')}</label>
                                        <input type="text" onChange={handleInputChange}  name = "food_intolerance" value={`${updatedMedicalInput ? updatedMedicalInput.food_intolerance : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                        <div>
                                            {errors.food_intolerance && <span className="text-red-500 text-xs">{errors.food_intolerance}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className=" mt-4">
                                    <div className="flex flex-col w-full">
                                        <label className="font-medium text-[14px] mt-2">{t('modals.Additional Details')}</label>
                                        <input type="text" onChange={handleInputChange}  name = "additional_details" value={`${updatedMedicalInput ? updatedMedicalInput.additional_details : 'N/A'}`} className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg outline-[#FDB315] "/>
                                        <div>
                                            {errors.additional_details && <span className="text-red-500 text-xs">{errors.additional_details}</span>}
                                        </div>
                                    </div>
                                </div> 
                        </div>
                    </div>
                </div>
            </form>
            </Box>
        </Modal>
    </>
  )
}