import { Types as TYPES } from "../types";
import { States as STATES } from "../states";

const actionType = TYPES.find(type => type.actionName === "loginAction");
const { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_STOP} = actionType.types;

const reducerState = STATES.find(state => state.reducerName === "loginReducer");
const { initialState } = reducerState;

export default function loginReducer(state = initialState, action) {

    switch(action.type) {
        case LOGIN_START:
            return {
                ...state,
                status: "login_start"
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                status: "login_success"
            }
        case LOGIN_FAILED:
            return {
                ...state,
                status: "login_failed"
            }
        default:
            return {
                ...state
            }
    }
}