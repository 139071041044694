import React from 'react';
import { format, isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export function FormatDateString(date_of_birth = '') {
    const { t } = useTranslation();
    const dateFormat = t('participants-page.DateFormat', {
        defaultValue: 'dd.MM.yyyy',
    });

    if (!date_of_birth) {
        console.error('Empty date_of_birth:', date_of_birth);
        return date_of_birth;
    }

    const parsedDate = parseISO(date_of_birth);

    if (!isValid(parsedDate)) {
        console.error('Invalid date:', date_of_birth);
        return date_of_birth;
    }

    try {
        return format(parsedDate, dateFormat);
    } catch (error) {
        console.error('Date formatting error:', error);
        return date_of_birth;
    }
}
