import { Types as TYPES } from '../types';
import { States as STATES } from '../states';

const actionType = TYPES.find((type) => type.actionName === 'sidebarAction');

const { OPEN_SIDEBAR, CLOSE_SIDEBAR, TOGGLE_SIDEBAR, RESET_SIDEBAR } =
    actionType.types;

const reducerState = STATES.find(
    (state) => state.reducerName === 'sidebarReducer'
);
const { initialState } = reducerState;

export default function sidebarReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_SIDEBAR:
            return { ...state, isOpen: true };
        case CLOSE_SIDEBAR:
            return { ...state, isOpen: false };
        case TOGGLE_SIDEBAR:
            return { ...state, isOpen: !state.isOpen };
        case RESET_SIDEBAR:
            return initialState;
        default:
            return state;
    }
}
