import React from 'react';
import RegistrationForm from '../components/RegistrationForm';

export default function Registration(props) {

    return (
        <div class="max-w-full min-h-max bg-base-background  flex flex-col justify-center items-center">
            <div class="bg-white md:w-[75%] w-full md:p-20 flex rounded-md">
                <RegistrationForm />
            </div>
        </div>
    )
}