import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../custom-hooks/useAuth';
import { useDispatch } from 'react-redux';
import { sidebarActions } from '../../redux-utils/actions/sidebar-action';
import LanguageSwitch from '../extra/LanguageSwitch';
import { useTranslation } from 'react-i18next';


function OpenedSidebar( {toggleSidebar, handlePageClick} ) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { Logout, getLogin } = useAuth();
    const{t} = useTranslation();

    const loginStorage = JSON.parse(getLogin());
    const user = loginStorage && loginStorage.loginData.data.user;
    const { name, email } = user;

  return (
    <div className='xs:w-[20%] bg-[#F9F9F9] lg:w-[20%] h-auto'>
        <div className="fixed h-full bg-primary w-[16%] flex flex-col justify-between items-center gap-2 text-white font-sans">
            <div className="bg-primary flex flex-col mt-5 mb-3 w-full items-start px-2 gap-3 ">
                <div onClick={() => dispatch(sidebarActions.toggleSidebar())} className='cursor-pointer w-full pl-2 flex items-center gap-2'>
                    <svg className='' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M3.33333 15H16.6667C17.125 15 17.5 14.625 17.5 14.1667C17.5 13.7083 17.125 13.3333 16.6667 13.3333H3.33333C2.875 13.3333 2.5 13.7083 2.5 14.1667C2.5 14.625 2.875 15 3.33333 15ZM3.33333 10.8333H16.6667C17.125 10.8333 17.5 10.4583 17.5 10C17.5 9.54167 17.125 9.16667 16.6667 9.16667H3.33333C2.875 9.16667 2.5 9.54167 2.5 10C2.5 10.4583 2.875 10.8333 3.33333 10.8333ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333Z" fill="white"/>
                    </svg>
                    <h1>{t('sidebar.Menu')}</h1>
                </div>
                <div className='w-full py-3 my-2 border-t border-b pl-2 border-[#FFFFFF33] flex items-center gap-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 44 44" fill="none">
                        <path d="M37.1112 41.25H6.88875C5.79179 41.2493 4.73992 40.8134 3.96399 40.038C3.18806 39.2625 2.75146 38.211 2.75 37.114V26.1222C2.75 24.6125 3.97375 23.375 5.5 23.375H38.5C39.2291 23.375 39.9284 23.6645 40.4441 24.18C40.9597 24.6954 41.2496 25.3945 41.25 26.1236V37.114C41.2464 38.2103 40.8091 39.2606 40.0336 40.0356C39.2582 40.8105 38.2075 41.2471 37.1112 41.25Z" fill="#608842"/>
                        <path d="M34.1055 20.418H30.5319C30.38 20.418 30.2569 20.5411 30.2569 20.693V25.8423C30.2569 25.9942 30.38 26.1173 30.5319 26.1173H34.1055C34.2574 26.1173 34.3805 25.9942 34.3805 25.8423V20.693C34.3805 20.5411 34.2574 20.418 34.1055 20.418Z" fill="#8C5543"/>
                        <path d="M36.3894 6.64982C36.3201 5.58654 35.8429 4.59108 35.0574 3.87119C34.2718 3.15129 33.2386 2.76258 32.1733 2.78617C31.1081 2.80976 30.0931 3.24384 29.3402 3.99781C28.5872 4.75178 28.1546 5.76739 28.1325 6.83269C28.1091 6.99769 28.0665 7.15169 27.9125 7.22457C27.3252 7.34512 26.7712 7.592 26.2888 7.94808C25.8064 8.30417 25.4073 8.76092 25.1192 9.28667C24.831 9.81241 24.6606 10.3946 24.6199 10.9927C24.5792 11.5909 24.6691 12.1907 24.8834 12.7507C24.8861 12.7644 24.8903 12.7782 24.8971 12.7947C24.9339 12.8955 24.9433 13.0042 24.9242 13.1098C24.9051 13.2154 24.8583 13.314 24.7885 13.3956C24.1589 13.9448 23.7107 14.672 23.5028 15.4812C23.295 16.2904 23.3373 17.1436 23.6243 17.9283C23.9113 18.7129 24.4294 19.3922 25.1102 19.8764C25.7911 20.3605 26.6028 20.627 27.4381 20.6404H37.2295C38.0749 20.6339 38.8979 20.3682 39.5876 19.8792C40.2772 19.3902 40.8002 18.7014 41.0861 17.9057C41.3719 17.1101 41.4068 16.2459 41.1861 15.4298C40.9653 14.6137 40.4996 13.885 39.8516 13.3419C39.6179 13.0986 39.6756 12.7727 39.743 12.5348C39.7544 12.4997 39.7654 12.4644 39.776 12.4289L39.7829 12.4097C39.7947 12.3862 39.8013 12.3604 39.8021 12.3341C39.9429 11.8012 39.9757 11.2455 39.8988 10.6997C39.8219 10.1539 39.6367 9.62899 39.3542 9.15574C39.0716 8.68248 38.6974 8.27043 38.2534 7.94376C37.8095 7.6171 37.3048 7.3824 36.7689 7.25344C36.5283 7.17507 36.4045 6.83957 36.3894 6.64982Z" fill="#96C34A"/>
                        <path d="M10.7346 11.8859C11.737 11.8859 12.5496 11.0733 12.5496 10.0709C12.5496 9.06846 11.737 8.25586 10.7346 8.25586C9.73222 8.25586 8.91962 9.06846 8.91962 10.0709C8.91962 11.0733 9.73222 11.8859 10.7346 11.8859Z" fill="#D3D3D3"/>
                        <path d="M11.3864 8.85813C10.934 9.57863 10.648 9.61438 10.1214 9.41226C9.83537 9.34076 9.57687 9.20051 8.92237 9.20051C7.39337 9.20051 6.07062 10.493 6.07062 12.2365C6.07062 13.9814 7.25174 15.1804 9.57687 15.1804H15.125C17.4047 15.1804 19.03 13.2801 19.03 10.8615C19.03 8.44151 16.8052 6.91113 14.839 6.91113C12.8727 6.91113 11.8387 8.13901 11.3864 8.85813Z" fill="#E6E6E6"/>
                        <path d="M6.2576 33.6151C5.52197 35.4769 6.6041 35.7794 7.42772 35.7794H35.4722C36.476 35.7794 36.9132 34.9626 36.432 33.7361C36.1735 33.0761 32.0801 22.8695 30.1812 17.8535H13.1587C12.4795 17.8535 12.3186 18.321 12.0436 18.9851C11.7686 19.6493 6.99322 31.7534 6.2576 33.6151Z" fill="#F9C23C"/>
                        <path d="M17.9259 32.9829C16.4552 29.3622 14.994 25.7377 13.5424 22.1094V35.781H20.6539C19.1111 35.781 18.711 34.8859 17.9259 32.9829Z" fill="#433B6B"/>
                        <path d="M13.5575 22.1099C13.1734 21.1683 12.7957 20.2241 12.4245 19.2774C12.3241 19.0024 12.188 18.6257 12.3378 18.3424V18.3369C12.2396 18.5181 12.1532 18.7055 12.0793 18.8979L12.0436 18.9845C11.8263 19.5084 8.80822 27.152 7.18297 31.2715L6.2576 33.6145C5.52197 35.4763 6.6041 35.7788 7.42772 35.7788H13.1188V23.5578L13.5575 22.1099Z" fill="#ED9200"/>
                        <path d="M13.5767 35.7777V22.1006C11.9322 26.6518 8.87562 34.8771 8.7505 35.2071C8.62675 35.5385 8.448 35.7255 8.37512 35.7777H13.5767Z" fill="#FF6723"/>
                        <path d="M22.5129 22.0088C21.3771 22.0088 21.0128 22.6647 21.3661 23.6327C21.4899 24.0617 21.835 25.1438 22.1774 26.0664C22.6064 27.2214 23.2265 27.517 24.2509 27.517H26.5966C27.7324 27.517 27.8933 26.7195 27.6513 26.0664C27.4093 25.4147 26.9816 24.2418 26.6461 23.3673C26.3106 22.4928 25.6589 22.0088 24.6345 22.0088H22.5129Z" fill="#FF822D"/>
                    </svg>
                    <h1 className='font-semibold'>Camp management</h1>
                </div>
                <Link to={'/dashboard'} 
                    className={`w-full ${location.pathname === '/dashboard' ? 'pointer-events-none cursor-default' : ''}`}>
                    <div className={`${location.pathname === '/dashboard' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("dashboard")}>
                        <svg className={`${location.pathname === '/dashboard' ? 'fill-current' : ''} group-hover:fill-current`} fill='#fff' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z" />
                        </svg>
                        <h1>{t('sidebar.Dashboard')}</h1>
                    </div>
                </Link>
                <Link to={'/participants'} 
                    className={`w-full ${location.pathname === '/participants' ? 'pointer-events-none cursor-default' : ''}`}>        
                    <div className={`${location.pathname === '/participants' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("participants")}>
                        <svg className={`${location.pathname === '/participants' ? 'fill-current' : ''} group-hover:fill-current`} fill="#fff" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm-5.478 2A6.47 6.47 0 0 0 11 17.5c0 1.63.6 3.12 1.592 4.261-.796.16-1.66.24-2.592.24-3.42 0-5.944-1.072-7.486-3.237a2.75 2.75 0 0 1-.51-1.595v-.92a2.249 2.249 0 0 1 2.249-2.25h7.77Zm2.832 3.146a.5.5 0 0 0-.707.707l2 2a.5.5 0 0 0 .707 0l4-4a.5.5 0 0 0-.707-.707L16.5 18.793l-1.646-1.647ZM10 2.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z" />
                        </svg>
                        <h1>{t('sidebar.Participants')}</h1>
                    </div>
                </Link>
                <Link to={'/participants/enrollments'} className={`w-full ${location.pathname === '/participants/enrollments' ? 'pointer-events-none cursor-default' : ''}`}>        
                    <div className={`${location.pathname === '/participants/enrollments' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("enrollments")}>
                        <svg className={`${location.pathname === '/participants/enrollments' ? 'fill-current' : ''} group-hover:fill-current`} fill="#fff" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2v6a2 2 0 0 0 2 2h6v10a2 2 0 0 1-2 2h-6.81A6.5 6.5 0 0 0 4 11.498V4a2 2 0 0 1 2-2h6Zm1.5.5V8a.5.5 0 0 0 .5.5h5.5l-6-6Zm-1.5 15a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Zm-2.146-2.354a.5.5 0 0 0-.708 0L5.5 18.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4a.5.5 0 0 0 0-.708Z" />
                        </svg>
                        <h1>{t('sidebar.Enrollments')}</h1>
                    </div>
                </Link>
                <Link to={'/participants/kitchen'} 
                    className={`w-full ${location.pathname === '/participants/kitchen' ? 'pointer-events-none cursor-default' : ''}`}>               
                    <div className={`${location.pathname === '/participants/kitchen' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("kitchen")}>
                        <svg className={`${location.pathname === '/participants/kitchen' ? 'fill-current' : ''} group-hover:fill-current`} fill="#fff" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" /></svg>
                        <h1>{t('sidebar.Kitchen')}</h1>
                    </div>
                </Link>
                <Link to={'/participants/medical'} 
                    className={`w-full ${location.pathname === '/participants/medical' ? 'pointer-events-none cursor-default' : ''}`}>
                    <div className={`${location.pathname === '/participants/medical' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("medical")}>
                        <svg className={`${location.pathname === '/participants/medical' ? 'fill-current' : ''} group-hover:fill-current`} fill="#fff" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16 3.75a.75.75 0 0 0-.75-.75h-6.5l-.102.007A.75.75 0 0 0 8 3.75V7H6.25A3.25 3.25 0 0 0 3 10.25v6.5A3.25 3.25 0 0 0 6.25 20h11.5A3.25 3.25 0 0 0 21 16.75v-6.5A3.25 3.25 0 0 0 17.75 7H16V3.75Zm-6.5.75h5V7h-5V4.5Zm2 11.25V14H9.75a.75.75 0 0 1 0-1.5h1.75v-1.75a.75.75 0 0 1 1.5 0v1.75h1.75a.75.75 0 0 1 0 1.5H13v1.75a.75.75 0 0 1-1.5 0Z"/></svg>
                        <h1>{t('sidebar.Medical')}</h1>
                    </div>
                </Link>
                <Link to={'/participants/media'} 
                    className={`w-full ${location.pathname === '/participants/media' ? 'pointer-events-none cursor-default' : ''}`}>
                    <div className={`${location.pathname === '/participants/media' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("media")}>
                        <svg className={`${location.pathname === '/participants/media' ? 'fill-current' : ''} group-hover:fill-current`} fill="#fff" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.475 13.718.083-.071a.75.75 0 0 1 .874-.007l.093.078 6.928 6.8A3.235 3.235 0 0 1 17.75 21H6.25a3.235 3.235 0 0 1-1.703-.481l6.928-6.801.083-.071-.083.07ZM17.75 3A3.25 3.25 0 0 1 21 6.25v11.5c0 .627-.178 1.213-.485 1.71l-6.939-6.813-.128-.116a2.25 2.25 0 0 0-2.889-.006l-.135.123-6.939 6.811A3.235 3.235 0 0 1 3 17.75V6.25A3.25 3.25 0 0 1 6.25 3h11.5Zm-1.998 3a2.252 2.252 0 1 0 0 4.504 2.252 2.252 0 0 0 0-4.504Zm0 1.5a.752.752 0 1 1 0 1.504.752.752 0 0 1 0-1.504Z"/></svg>
                        <h1>{t('sidebar.Media Consent')}</h1>
                    </div>
                </Link>
                <Link to={'/participants/archive'} 
                    className={`w-full ${location.pathname === '/participants/archive' ? 'pointer-events-none cursor-default' : ''}`}>
                    <div className={`${location.pathname === '/participants/archive' ? 'bg-white font-medium text-primary' : ''} hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg px-2 w-full flex items-center text-sm gap-3 group`} onClick={() => handlePageClick("archive")}>
                        <svg className={`${location.pathname === '/participants/archive' ? 'fill-current' : ''} group-hover:fill-current`} fill="#fff" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20 8v11.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 19.5V8h16Zm-6 3.5h-4a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5ZM20 3a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16Z"/></svg>
                        <h1>{t('sidebar.Archive')}</h1>
                    </div>
                </Link>
            </div>
            <div className='border-t border-white/35 h-[28%] py-4 px-4 w-full flex flex-col items-start justify-center gap-2'>
                <div className='bg-white/35 rounded-lg w-full px-1 py-2 flex items-center gap-2'>
                    <div className='bg-tertiary rounded-full text-center w-8 h-8 flex items-center justify-center'>
                        {name ? name.split(' ').map((el) => el[0]).join('').slice(0, 2) : '--'}
                    </div>
                    <div className=''>
                        <h1 className=''>{name !== "" ? name: "User"}</h1>
                        <p className='text-xs'>{email !== "" ? email: "Email"}</p>
                    </div>
                </div>
                <div className='hover:bg-white hover:font-medium cursor-pointer hover:text-primary h-10 rounded-lg w-full px-2 flex items-center gap-2 group' onClick={ () => Logout() }>
                    <svg className='group-hover:fill-current' width="20" height="20" fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.354v6.651l7.442-.001L17.72 9.28a.75.75 0 0 1-.073-.976l.073-.084a.75.75 0 0 1 .976-.073l.084.073 2.997 2.997a.75.75 0 0 1 .073.976l-.073.084-2.996 3.004a.75.75 0 0 1-1.134-.975l.072-.085 1.713-1.717-7.431.001L12 19.25a.75.75 0 0 1-.88.739l-8.5-1.502A.75.75 0 0 1 2 17.75V5.75a.75.75 0 0 1 .628-.74l8.5-1.396a.75.75 0 0 1 .872.74ZM8.502 11.5a1.002 1.002 0 1 0 0 2.004 1.002 1.002 0 0 0 0-2.004Z" /><path d="M13 18.501h.765l.102-.006a.75.75 0 0 0 .648-.745l-.007-4.25H13v5.001ZM13.002 10 13 8.725V5h.745a.75.75 0 0 1 .743.647l.007.102.007 4.251h-1.5Z" fill="#fff"/></svg>
                    <h1>{t('sidebar.Logout')}</h1>
                </div>
               <LanguageSwitch bgColor={`white/35`} />
            </div>
        </div>
    </div>
  )
}

export default OpenedSidebar