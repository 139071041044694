import React from 'react';
import { useTranslation } from 'react-i18next';

function Pagination({ handlePageChange, totalPages, currentPage, itemsPerPage, handlePerPageChange }) {
  const{t} = useTranslation();

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 7;
    const halfMaxPageNumbers = Math.floor(maxPageNumbers / 2);

    let startPage = Math.max(currentPage - halfMaxPageNumbers, 1);
    let endPage = Math.min(currentPage + halfMaxPageNumbers, totalPages);

    if (totalPages > maxPageNumbers) {
      if (currentPage <= halfMaxPageNumbers) {
        endPage = maxPageNumbers;
      } else if (currentPage + halfMaxPageNumbers >= totalPages) {
        startPage = totalPages - maxPageNumbers + 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className='flex items-center justify-end mb-10'>
      <div className='mt-5 mb-5 w-full flex items-center justify-between'>
        <div className='flex items-center gap-3'>
          {currentPage > 1 && (
            <svg onClick={() => handlePageChange(Math.max(1, currentPage - 1))} className='hover:bg-gray-200 w-7 h-7 hover:text-white flex items-center justify-center hover:rounded-full' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M11.8095 14L8 10.1905L11.8095 6.38095" stroke="#2F4858" stroke-opacity="0.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          )}
          <div className='flex text-secondary font-medium items-center gap-2'>
            {pageNumbers[0] > 1 && (
              <>
                <h1 onClick={() => handlePageChange(1)} className={`cursor-pointer ${currentPage === 1 ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} w-7 h-7 flex items-center justify-center rounded-full`}>1</h1>
                <span>...</span>
              </>
            )}
            {pageNumbers.map(pageIndex => (
              <h1 key={pageIndex} onClick={() => handlePageChange(pageIndex)} className={`cursor-pointer ${currentPage === pageIndex ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} w-7 h-7 flex items-center justify-center rounded-full`}>
                {pageIndex}
              </h1>
            ))}
            {pageNumbers[pageNumbers.length - 1] < totalPages && (
              <>
                <span>...</span>
                <h1 onClick={() => handlePageChange(totalPages)} className={`cursor-pointer ${currentPage === totalPages ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} w-7 h-7 flex items-center justify-center rounded-full`}>{totalPages}</h1>
              </>
            )}
          </div>
          {currentPage < totalPages && (
            <svg onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))} className='hover:bg-gray-200 w-7 h-7 hover:text-white flex items-center justify-center hover:rounded-full' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M8.19043 6L12 9.80952L8.19043 13.619" stroke="#2F4858" stroke-opacity="0.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          )}
        </div>
        <div className='flex items-center gap-3'>
          <h1 className='text-secondary'>{t('participants-page.Per page')}</h1>
          <select value={itemsPerPage} onChange={handlePerPageChange} className='border-2 h-8 px-2 w-20 text-center outline-primary rounded-full'>
            <option value={6}>6</option>
            <option value={12}>12</option>
            <option value={24}>24</option>
            <option value={48}>48</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Pagination;