import { combineReducers } from 'redux';
import loginReducer from './login-reducer';
import dashboardReducer from './dashboard-reducer';
import userReducer from './user-reducer';
import sidebarReducer from './sidebar-reducer';

export const rootReducers = combineReducers({
    loginState: loginReducer,
    dashboardState: dashboardReducer,
    userState: userReducer,
    sidebarState: sidebarReducer,
});
