import { Types as TYPES } from "../types";
import { saveState} from "../../utils/localstorage";

const actionType = TYPES.find((type) => type.actionName === "sidebarAction");

const {
    OPEN_SIDEBAR,
    CLOSE_SIDEBAR,
    TOGGLE_SIDEBAR,
    RESET_SIDEBAR
}  = actionType.types;


export const sidebarActions = {

    openSidebar: () => (dispatch, getState) => {
        dispatch({
            type: OPEN_SIDEBAR
        })
        saveState('sidebarState', getState().sidebarState);
    },

    closeSidebar: () => (dispatch, getState) => {
        dispatch({
            type: CLOSE_SIDEBAR
        })
        saveState('sidebarState', getState().sidebarState); 
    },

    toggleSidebar:() => (dispatch, getState) =>{
        dispatch({
            type: TOGGLE_SIDEBAR
        })
        saveState('sidebarState', getState().sidebarState);
    },

    resetSidebar:() => (dispatch, getState) => {
        dispatch({
            type: RESET_SIDEBAR
        })
        saveState('sidebarState', getState().sidebarState);
    }
}