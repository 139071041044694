import { Routes, Route, BrowserRouter as Router, Navigate } from "react-router-dom";
import { Routes as ROUTES } from "./routes";
import store from "./redux-utils/store";
import './index.css'
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={store} >
      <div className="app ">
        <Router>
          <Routes>
            {
              ROUTES.map((route, index) => {
                return <Route path={route.url} element={<route.component />} key={index} />
              })
            }
            {/* <Route path="/participants" element={<Navigate to="/dashboard" />} /> */}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
