import { Types as TYPES } from '../types';
import { Endpoints as ENDPOINTS } from '../endpoints';
import {
    decryptAllFormData,
    decryptSingleFormData,
} from '../../utils/crypt-module/decrypt';
import { useAuth as Auth_Util } from '../../custom-hooks/useAuth';
import Swal from 'sweetalert2';

const { getPrivateKey } = Auth_Util();

const actionType = TYPES.find((type) => type.actionName === 'dashboardAction');
const {
    ALL_PARTICIPANT_FETCH_START,
    ALL_PARTICIPANT_FETCH_SUCCESS,
    ALL_PARTICIPANT_FETCH_FAILED,
    ALL_PARTICIPANT_FETCH_STOP,

    SINGLE_PARTICIPANT_FETCH_START,
    SINGLE_PARTICIPANT_FETCH_SUCCESS,
    SINGLE_PARTICIPANT_FETCH_FAILED,
    SINGLE_PARTICIPANT_FETCH_STOP,

    PARTICIPANT_CREATE_START,
    PARTICIPANT_CREATE_SUCCESS,
    PARTICIPANT_CREATE_FAILED,
    PARTICIPANT_CREATE_STOP,

    PARTICIPANT_UPDATE_START,
    PARTICIPANT_UPDATE_SUCCESS,
    PARTICIPANT_UPDATE_FAILED,
    PARTICIPANT_UPDATE_STOP,

    ENROLLMENT_UPDATE_START,
    ENROLLMENT_UPDATE_SUCCESS,
    ENROLLMENT_UPDATE_FAILED,
    ENROLLMENT_UPDATE_STOP,

    MEDIA_UPDATE_START,
    MEDIA_UPDATE_SUCCESS,
    MEDIA_UPDATE_FAILED,
    MEDIA_UPDATE_STOP,

    MEDICAL_UPDATE_START,
    MEDICAL_UPDATE_SUCCESS,
    MEDICAL_UPDATE_FAILED,
    MEDICAL_UPDATE_STOP,

    KITCHEN_UPDATE_START,
    KITCHEN_UPDATE_SUCCESS,
    KITCHEN_UPDATE_FAILED,
    KITCHEN_UPDATE_STOP,

    PARTICIPANT_DELETE_START,
    PARTICIPANT_DELETE_SUCCESS,
    PARTICIPANT_DELETE_FAILED,
    PARTICIPANT_DELETE_STOP,

    PARTICIPANT_ARCHIVE_START,
    PARTICIPANT_ARCHIVE_FAILED,
    PARTICIPANT_ARCHIVE_SUCCESS,
    PARTICIPANT_ARCHIVE_STOP,

    PARTICIPANT_UNARCHIVE_START,
    PARTICIPANT_UNARCHIVE_FAILED,
    PARTICIPANT_UNARCHIVE_STOP,
    PARTICIPANT_UNARCHIVE_SUCCESS,
} = actionType.types;

const { baseUrl, routes } = ENDPOINTS;

export const dashboardActions = {
    allParticipantAction: (param) => (dispatch) => {
        dispatch({
            type: ALL_PARTICIPANT_FETCH_START,
        });

        const { token } = param;

        const allParticipantUrl = `${baseUrl}${routes.get.participant}`;

        fetch(allParticipantUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        })
            .then((resp) => {
                if ((!resp.ok) && (resp.status === 401)) {
                    return resp.json();
                }else if((resp.ok) && (resp.status === 200)) {
                    return resp.json();
                }else{
                    throw new Error('participant fetch failed');
                }
            })
            .then((res) => {
                if(res.message && res.message === "Unauthenticated.") {
                    dispatch({
                        type: ALL_PARTICIPANT_FETCH_FAILED,
                        error: "Unauthenticated"
                    });
                }
                if (res && res.data.length > 0) {
                    const privateKey = getPrivateKey();

                    decryptAllFormData(res.data, privateKey).then(
                        (decryptedData) => {
                            if (
                                decryptedData &&
                                decryptedData.isDecrypted === undefined
                            ) {
                                dispatch({
                                    type: ALL_PARTICIPANT_FETCH_SUCCESS,
                                    payload: decryptedData,
                                });
                            } else if (
                                decryptedData &&
                                decryptedData.isDecrypted !== undefined &&
                                !decryptedData.isDecrypted
                            ) {
                                Swal.fire({
                                    title: 'Decryption failed',
                                    text: 'Error decrypting data',
                                    icon: 'error',
                                });
                            }
                        }
                    );
                }else if (res && res.data.length === 0) {
                    dispatch({
                        type: ALL_PARTICIPANT_FETCH_SUCCESS,
                        payload: res.data
                    });
                }else {
                    dispatch({
                        type: ALL_PARTICIPANT_FETCH_FAILED,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: ALL_PARTICIPANT_FETCH_FAILED,
                });
            });
    },
    singleParticipantAction: (params) => (dispatch) => {
        dispatch({
            type: SINGLE_PARTICIPANT_FETCH_START,
        });

        const { token, id } = params;
        const singleParticipantUrl = `${baseUrl}${routes.get.participant}/${id}`;

        fetch(singleParticipantUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((resp) => {
                if (resp.status !== 200) {
                    throw new Error('participant show failed');
                }
                return resp.json();
            })
            .then((res) => {
                const privateKey = getPrivateKey();

                decryptSingleFormData(res.data, privateKey).then(
                    (decryptedData) => {
                        if (
                            decryptedData &&
                            decryptedData.isDecrypted === undefined
                        ) {
                            dispatch({
                                type: SINGLE_PARTICIPANT_FETCH_SUCCESS,
                                payload: decryptedData,
                            });
                        } else if (
                            decryptedData &&
                            decryptedData.isDecrypted !== undefined &&
                            !decryptedData.isDecrypted
                        ) {
                            Swal.fire({
                                title: 'Decryption failed',
                                text: 'Error decrypting data',
                                icon: 'error',
                            });
                        }
                    }
                );
            })
            .catch((e) => {
                dispatch({
                    type: SINGLE_PARTICIPANT_FETCH_FAILED,
                });
            });
    },
    participantCreateAction: (params) => (dispatch) => {
        dispatch({
            type: PARTICIPANT_CREATE_START,
        });

        const participantCreateUrl = `${baseUrl}${routes.post.participant}`;

        fetch(participantCreateUrl, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => {
                if (resp.status !== 200) {
                    throw new Error('participant create failed');
                }
                return resp.json();
            })
            .then((res) => {
                dispatch({
                    type: PARTICIPANT_CREATE_SUCCESS,
                    payload: res,
                });
            })
            .catch((e) => {
                dispatch({
                    type: PARTICIPANT_CREATE_FAILED,
                });
            });
    },
    updateAction: (params, subpage, formData) => (dispatch) => {
        switch (subpage) {
            case 'participant':
                if (!params || !params.id || !params.token) {
                    dispatch({
                        type: PARTICIPANT_UPDATE_FAILED,
                        error: 'Invalid parameters',
                    });
                    return;
                }

                dispatch({ type: PARTICIPANT_UPDATE_START });

                const participantUpdateUrl = `${baseUrl}${routes.update.participant}/${params.id}`;

                fetch(participantUpdateUrl, {
                    method: 'PUT',
                    credentials: 'same-origin',
                    body: JSON.stringify(params),
                    redirect: 'follow',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${params.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then((resp) => {
                        if (resp.status !== 200) {
                            throw new Error('Participant update failed');
                        }
                        return resp.json();
                    })
                    .then((res) => {
                        dispatch({
                            type: PARTICIPANT_UPDATE_SUCCESS,
                            payload: formData,
                        });
                        // const privateKey = getPrivateKey();

                        // decryptSingleFormData(res.data, privateKey).then(
                        //     (decryptedData) => {
                        //         if (
                        //             decryptedData &&
                        //             decryptedData.isDecrypted === undefined
                        //         ) {
                        //             dispatch({
                        //                 type: PARTICIPANT_UPDATE_SUCCESS,
                        //                 payload: decryptedData,
                        //             });
                        //         } else if (
                        //             decryptedData &&
                        //             decryptedData.isDecrypted !== undefined &&
                        //             !decryptedData.isDecrypted
                        //         ) {
                        //             Swal.fire({
                        //                 title: 'Decryption failed',
                        //                 text: 'Error decrypting data',
                        //                 icon: 'error',
                        //             });
                        //         }
                        //     }
                        // );
                    })
                    .catch((error) => {
                        dispatch({
                            type: PARTICIPANT_UPDATE_FAILED,
                            error: error.message || 'Unknown error',
                        });
                    });
                break;

            case 'enrollment':
                if (!params || !params.id || !params.token) {
                    dispatch({
                        type: ENROLLMENT_UPDATE_FAILED,
                        error: 'Invalid parameters',
                    });
                    return;
                }

                dispatch({ type: ENROLLMENT_UPDATE_START });

                const enrollmentUpdateUrl = `${baseUrl}${routes.update.participant}/${params.id}`;

                fetch(enrollmentUpdateUrl, {
                    method: 'PUT',
                    credentials: 'same-origin',
                    body: JSON.stringify(params),
                    redirect: 'follow',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${params.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then((resp) => {
                        if (resp.status !== 200) {
                            throw new Error('Enrollment update failed');
                        }
                        return resp.json();
                    })
                    .then((res) => {
                        dispatch({
                            type: ENROLLMENT_UPDATE_SUCCESS,
                            payload: formData,
                        });
                        // const privateKey = getPrivateKey();

                        // decryptSingleFormData(res.data, privateKey).then(
                        //     (decryptedData) => {
                        //         if (
                        //             decryptedData &&
                        //             decryptedData.isDecrypted === undefined
                        //         ) {
                        //             dispatch({
                        //                 type: ENROLLMENT_UPDATE_SUCCESS,
                        //                 payload: formData,
                        //             });
                        //         } else if (
                        //             decryptedData &&
                        //             decryptedData.isDecrypted !== undefined &&
                        //             !decryptedData.isDecrypted
                        //         ) {
                        //             Swal.fire({
                        //                 title: 'Decryption failed',
                        //                 text: 'Error decrypting data',
                        //                 icon: 'error',
                        //             });
                        //         }
                        //     }
                        // );
                    })
                    .catch((error) => {
                        dispatch({
                            type: ENROLLMENT_UPDATE_FAILED,
                            error: error.message || 'Unknown error',
                        });
                    });
                break;
            case 'media':
                if (!params || !params.id || !params.token) {
                    dispatch({
                        type: MEDIA_UPDATE_FAILED,
                        error: 'Invalid parameters',
                    });
                    return;
                }

                dispatch({ type: MEDIA_UPDATE_START });

                const mediaUpdateUrl = `${baseUrl}${routes.update.participant}/${params.id}`;

                fetch(mediaUpdateUrl, {
                    method: 'PUT',
                    credentials: 'same-origin',
                    body: JSON.stringify(params),
                    redirect: 'follow',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${params.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then((resp) => {
                        if (resp.status !== 200) {
                            throw new Error('Media update failed');
                        }
                        return resp.json();
                    })
                    .then((res) => {
                        dispatch({
                            type: MEDIA_UPDATE_SUCCESS,
                            payload: formData,
                        });
                        // const privateKey = getPrivateKey();

                        // decryptSingleFormData(res.data, privateKey).then(
                        //     (decryptedData) => {
                        //         if (
                        //             decryptedData &&
                        //             decryptedData.isDecrypted === undefined
                        //         ) {
                        //             dispatch({
                        //                 type: MEDIA_UPDATE_SUCCESS,
                        //                 payload: decryptedData,
                        //             });
                        //         } else if (
                        //             decryptedData &&
                        //             decryptedData.isDecrypted !== undefined &&
                        //             !decryptedData.isDecrypted
                        //         ) {
                        //             Swal.fire({
                        //                 title: 'Decryption failed',
                        //                 text: 'Error decrypting data',
                        //                 icon: 'error',
                        //             });
                        //         }
                        //     }
                        // );
                    })
                    .catch((error) => {
                        dispatch({
                            type: MEDIA_UPDATE_FAILED,
                            error: error.message || 'Unknown error',
                        });
                    });
                    break;
                case 'medical':
                    if (!params || !params.id || !params.token) {
                        dispatch({
                            type: MEDICAL_UPDATE_FAILED,
                            error: 'Invalid parameters',
                        });
                        return;
                    }
    
                    dispatch({ type: MEDICAL_UPDATE_START });
    
                    const medicalUpdateUrl = `${baseUrl}${routes.update.participant}/${params.id}`;
    
                    fetch(medicalUpdateUrl, {
                        method: 'PUT',
                        credentials: 'same-origin',
                        body: JSON.stringify(params),
                        redirect: 'follow',
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${params.token}`,
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((resp) => {
                            if (resp.status !== 200) {
                                throw new Error('Medical update failed');
                            }
                            return resp.json();
                        })
                        .then((res) => {
                            dispatch({
                                type: MEDICAL_UPDATE_SUCCESS,
                                payload: formData,
                            });
                            // const privateKey = getPrivateKey();
    
                            // decryptSingleFormData(res.data, privateKey).then(
                            //     (decryptedData) => {
                            //         if (
                            //             decryptedData &&
                            //             decryptedData.isDecrypted === undefined
                            //         ) {
                            //             dispatch({
                            //                 type: MEDICAL_UPDATE_SUCCESS,
                            //                 payload: decryptedData,
                            //             });
                            //         } else if (
                            //             decryptedData &&
                            //             decryptedData.isDecrypted !== undefined &&
                            //             !decryptedData.isDecrypted
                            //         ) {
                            //             Swal.fire({
                            //                 title: 'Decryption failed',
                            //                 text: 'Error decrypting data',
                            //                 icon: 'error',
                            //             });
                            //         }
                            //     }
                            // );
                        })
                        .catch((error) => {
                            dispatch({
                                type: MEDICAL_UPDATE_FAILED,
                                error: error.message || 'Unknown error',
                            });
                        });
                    break;
                case 'kitchen':
                    if (!params || !params.id || !params.token) {
                        dispatch({
                            type: KITCHEN_UPDATE_FAILED,
                            error: 'Invalid parameters',
                        });
                        return;
                    }
    
                    dispatch({ type: KITCHEN_UPDATE_START });
    
                    const kitchenUpdateUrl = `${baseUrl}${routes.update.participant}/${params.id}`;
    
                    fetch(kitchenUpdateUrl, {
                        method: 'PUT',
                        credentials: 'same-origin',
                        body: JSON.stringify(params),
                        redirect: 'follow',
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${params.token}`,
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((resp) => {
                            if (resp.status !== 200) {
                                throw new Error('Kitchen update failed');
                            }
                            return resp.json();
                        })
                        .then((res) => {
                            dispatch({
                                type: KITCHEN_UPDATE_SUCCESS,
                                payload: formData,
                            });
                            // const privateKey = getPrivateKey();
    
                            // decryptSingleFormData(res.data, privateKey).then(
                            //     (decryptedData) => {
                            //         if (
                            //             decryptedData &&
                            //             decryptedData.isDecrypted === undefined
                            //         ) {
                            //             dispatch({
                            //                 type: KITCHEN_UPDATE_SUCCESS,
                            //                 payload: decryptedData,
                            //             });
                            //         } else if (
                            //             decryptedData &&
                            //             decryptedData.isDecrypted !== undefined &&
                            //             !decryptedData.isDecrypted
                            //         ) {
                            //             Swal.fire({
                            //                 title: 'Decryption failed',
                            //                 text: 'Error decrypting data',
                            //                 icon: 'error',
                            //             });
                            //         }
                            //     }
                            // );
                        })
                        .catch((error) => {
                            dispatch({
                                type: KITCHEN_UPDATE_FAILED,
                                error: error.message || 'Unknown error',
                            });
                        });
                        break;

            default:
                break;
        }
    },
    participantArchiveAction: (params, selectedParticipant) => (dispatch) => {
        if (!params || !params.id || !params.token) {
            dispatch({
                type: PARTICIPANT_ARCHIVE_FAILED,
                error: 'Invalid parameters',
            });
            return;
        }

        dispatch({ type: PARTICIPANT_ARCHIVE_START });

        const { token } = params;

        const archiveParticipantUrl = `${baseUrl}${routes.others.archive}`;

        fetch(archiveParticipantUrl, {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(params),
            redirect: 'follow',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((resp) => {
            if (resp.status !== 200) {
                throw new Error('failed archived');
            }
            return resp.json();
        })
        .then((res) => {
            dispatch({
                type: PARTICIPANT_ARCHIVE_SUCCESS,
                payload: {
                    res,
                    selectedParticipant,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: PARTICIPANT_ARCHIVE_FAILED,
                error: error.message || 'Unknown error',
            });
        });
    },

    participantDeleteAction: (params) => (dispatch) => {
        dispatch({ type: PARTICIPANT_DELETE_START });

        const { id, token } = params;
        const deleteUserUrl = `${baseUrl}${routes.delete.participant}/${id}`;

        fetch(deleteUserUrl, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => {
                if (resp.status !== 200) {
                    throw new Error('delete participat failed');
                }
                return resp.json();
            })
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: PARTICIPANT_DELETE_SUCCESS,
                        payload: res.data,
                    });
                } else {
                    dispatch({
                        type: PARTICIPANT_DELETE_FAILED,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: PARTICIPANT_DELETE_FAILED,
                });
            });
    },
};
