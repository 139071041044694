import React, { useEffect, useState } from 'react';
import { useAuth } from '../custom-hooks/useAuth';
// import ClosedSidebar from '../components/closedSidebar'
// import OpenedSidebar from "../components/openedSidebar"
import Navbar from '../components/navbar';
import { connect } from 'react-redux';
import { dashboardActions } from '../redux-utils/actions/dashboard-actions';
// import Participants from './subpages/participants';
import Loader from '../components/Loader';
import EmptyState from '../components/EmptyState';
import { useSelector } from 'react-redux';

const { allParticipantAction, singleParticipantAction } = dashboardActions;

function DashboardData({
    allParticipantDispatchAction,
    singleParticipantDispatchAction,
    allParticipantResult,
}) {
    const [isOpened, setIsOpened] = useState(true);

    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());

    useEffect(() => {
        const allParam = {
            token: loginStorage && loginStorage.loginData.data.token,
        };
        allParticipantDispatchAction(allParam);
    }, []);

    const verifiedParticipants = allParticipantResult.allParticipantData.filter(
        (participant) =>
            participant.is_fee_paid === 'yes' &&
            participant.confirmation_email_sent === 'yes' &&
            participant.agreement_signed === 'yes' &&
            participant.is_archived === 'no'
    ).length;

    const totalParticipants = useSelector(
        (state) => state.dashboardState.archiveOperationInfo.totalParticipants
    );

    const activeParticipants = allParticipantResult.allParticipantData.filter(
        (participant) => participant.is_archived === 'no'
    ).length;

    const toggleSidebar = () => {
        setIsOpened(!isOpened);
    };

    if (
        allParticipantResult.allParticipantData &&
        allParticipantResult.allParticipantData.length > 0
    ) {
        return (
            <main className="h-screen font-sans flex">
                {/* {isOpened ? (
            <OpenedSidebar toggleSidebar={toggleSidebar} />
          ) : (
            <ClosedSidebar toggleSidebar={toggleSidebar} />
          )} */}
                <section className="w-full bg-[#F9F9F9]">
                    <div className="flex flex-col px-20 pt-10">
                        <h1 className="text-2xl text-tertiary font-medium">
                            Dashboard
                        </h1>
                        <div className="flex items-center gap-10 mt-10">
                            <div className="flex flex-col rounded-lg shadow-md px-6 py-4 gap-2">
                                <h1 className="text-tertiary font-medium">
                                    Total Participants
                                </h1>
                                <h1 className="text-2xl text-secondary font-medium">
                                    {totalParticipants !== ''
                                        ? totalParticipants
                                        : activeParticipants}
                                </h1>
                            </div>
                            <div className="flex flex-col rounded-lg shadow-md px-6 py-4 gap-2">
                                <h1 className="text-tertiary font-medium">
                                    Completed Registrations
                                </h1>
                                <h1 className="text-2xl text-secondary font-medium">
                                    {verifiedParticipants}
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    return (
        <main className="h-screen font-sans flex">
            {/* {isOpened ? (
        <OpenedSidebar toggleSidebar={toggleSidebar} />
      ) : (
        <ClosedSidebar toggleSidebar={toggleSidebar} />
      )} */}
            <section className="w-full bg-[#F9F9F9]">
                {allParticipantResult.status === '' ||
                allParticipantResult.status ===
                    'all_participant_fetch_start' ? (
                    <Loader />
                ) : allParticipantResult.status ===
                      'all_participant_fetch_success' &&
                  allParticipantResult.allParticipantData &&
                  allParticipantResult.allParticipantData.length > 0 ? (
                    <section className="w-full bg-[#F9F9F9]">
                        <div className="flex flex-col px-20 pt-10">
                            <h1 className="text-2xl text-tertiary font-medium">
                                Dashboard
                            </h1>
                            <div className="flex items-center gap-10 mt-10">
                                <div className="flex flex-col rounded-lg shadow-md px-6 py-4 gap-2">
                                    <h1 className="text-tertiary font-medium">
                                        Total Participants
                                    </h1>
                                    <h1 className="text-2xl text-secondary font-medium">
                                        {totalParticipants !== ''
                                            ? totalParticipants
                                            : activeParticipants}
                                    </h1>
                                </div>
                                <div className="flex flex-col rounded-lg shadow-md px-6 py-4 gap-2">
                                    <h1 className="text-tertiary font-medium">
                                        Completed Registrations
                                    </h1>
                                    <h1 className="text-2xl text-secondary font-medium">
                                        {verifiedParticipants}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <EmptyState />
                )}
            </section>
        </main>
    );
}

const areEqual = (prevProps, nextProps) => {
    // Check if the data prop is equal
    return (
        prevProps.allParticipantResult.allParticipantData ===
        nextProps.allParticipantResult.allParticipantData
    );
};

const DashboardMemo = React.memo(DashboardData, areEqual);

function Dashboard(props) {
    const {
        allParticipantDispatchAction,
        singleParticipantDispatchAction,
        allParticipantResult,
    } = props;

    const { getLogin } = useAuth();
    const loginStorage = JSON.parse(getLogin());

    useEffect(() => {
        const allParam = {
            token: loginStorage && loginStorage.loginData.data.token,
        };
        allParticipantDispatchAction(allParam);
    }, []);

    return (
        <>
            <DashboardMemo
                allParticipantDispatchAction={allParticipantDispatchAction}
                singleParticipantDispatchAction={
                    singleParticipantDispatchAction
                }
                allParticipantResult={allParticipantResult}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    allParticipantResult: state.dashboardState,
});

const mapDispatchToProps = (dispatch) => ({
    allParticipantDispatchAction: (param) =>
        dispatch(allParticipantAction(param)),
    singleParticipantDispatchAction: (param) =>
        dispatch(singleParticipantAction(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
