import React, {} from "react";
import OpenedSidebar from "./openedSidebar";
import ClosedSidebar from "./closedSidebar";

export default function SideDrawer(props) {

    const { isOpened, toggleSidebar, handlePageClick } = props;

    return (
        <>
            {isOpened ? (
                <OpenedSidebar toggleSidebar={toggleSidebar} handlePageClick={handlePageClick} />
            ) : (
                <ClosedSidebar toggleSidebar={toggleSidebar} />
            )}
        </>
    )
}