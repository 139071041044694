import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import calculateAge from '../../utils/calculateAge';
import { encryptUtility } from '../../utils/crypt-module/encrypt';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../../swal.css';
import Loader from '../Loader';
import { FormatDateString } from '../../utils/FormatDate';

export default function ViewParticipantInfo(props) {
    const {
        handleViewParticipantClose,
        handleEditParticipantOpen,
        viewParticipantOpen,
        style,
        participant,
        buttonLabel,
        archiveParticipant,
        deleteParticipant,
        archiveType,
        setArchiveType,
        participantArchiveStatus,
        participantDeleteStatus,
        handleArchiveUpdate,
        t,
    } = props;

    useEffect(() => {}, [participantArchiveStatus, participantDeleteStatus]);

    return (
        <>
            <Modal
                open={viewParticipantOpen}
                onClose={handleViewParticipantClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="-z-50 relative"
            >
                <Box sx={style}>
                    {participantArchiveStatus ===
                        'participant_archive_start' && (
                        <Loader componentType="edit-participant-modal" />
                    )}
                    {participantDeleteStatus === 'participant_delete_start' && (
                        <Loader componentType="edit-participant-modal" />
                    )}
                    <form>
                        <div className="w-full h-[85vh] rounded-lg bg-white p-10 pt-0 overflow-auto relative">
                            <div className="flex justify-between items-center w-auto bg-white h-20 sticky top-0">
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: '#2F4858',
                                        fontWeight: '500',
                                        fontSize: '28px',
                                    }}
                                >
                                    {participant.first_name}{' '}
                                    {participant.last_name}
                                </Typography>
                                <div className="flex gap-5 items-center">
                                    <Button
                                        onClick={() =>
                                            participant.is_archived === 'yes'
                                                ? archiveParticipant('no')
                                                : archiveParticipant('yes')
                                        }
                                        variant="outlined"
                                        sx={{
                                            color: '#FDB315',
                                            px: 2,
                                            py: '0.39rem',
                                            borderRadius: '999px',
                                            border: '2px solid #FDB315',
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            '&:hover': {
                                                border: '2px solid #FDB315',
                                                backgroundColor: 'transparent',
                                                color: '#FDB315',
                                            },
                                        }}
                                    >
                                        {participant.is_archived === 'yes'
                                            ? `${t('modals.Unarchive')}`
                                            : `${t('modals.Archive')}`}
                                    </Button>
                                    <Button
                                        onClick={
                                            buttonLabel
                                                ? handleEditParticipantOpen
                                                : deleteParticipant
                                        }
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#FDB315',
                                            px: 3,
                                            py: '0.59rem',
                                            borderRadius: '999px',
                                            color: 'white',
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            '&:hover': {
                                                backgroundColor: '#FDB315',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        {buttonLabel
                                            ? `${t('modals.Edit')}`
                                            : `${t('modals.Delete')}`}
                                    </Button>
                                    <div
                                        onClick={handleViewParticipantClose}
                                        className="cursor-pointer bg-[#EFEFEF] hover:bg-[#eeeeff] w-10 h-10 flex items-center justify-center rounded-full"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="size-4"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path d="M18 6l-12 12" />
                                            <path d="M6 6l12 12" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className="border shadow-sm rounded-lg p-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('modals.Administrative Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Fee Paid')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.is_fee_paid}`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Fee Amount')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.fee_amount}`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-3">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Fee Payment Date')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${
                                                    FormatDateString(
                                                        participant.payment_date
                                                    ) ?? 'N/A'
                                                }`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Agreement Signed')}{' '}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.agreement_signed}`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-3">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Media Consent')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.media_allowed}`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Email Confirmation Sent'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.confirmation_email_sent}`}
                                                className="border px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('modals.Participant Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Full Name')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.first_name} ${participant.last_name}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Gender')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.gender}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-3">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Date of Birth')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${FormatDateString(
                                                    participant.date_of_birth
                                                )}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Age')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${calculateAge(
                                                    participant.date_of_birth
                                                )}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('modals.Guardian Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t("modals.Father's Full Name")}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.father}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t("modals.Mother's Full Name")}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.mother}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.City')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.city}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Street')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.street}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.House Number')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.house_number}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Zip Code')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.zip_code}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Landline')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.landline}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Mobile')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.mobile}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Email Address')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.email}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t(
                                            'modals.Emergency Contact Information'
                                        )}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Full Name')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.fallback_contact}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Mobile')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.fallback_number}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('modals.Other')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Friend 1')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.friend_1}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Friend 2')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.friend_2}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Sibling In Same Tent')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.siblings_in_same_tent}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('modals.Activity Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Swimming Allowed')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.swimming_allowed}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Swimming Capability'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.swimming_capability}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Swimming Badge')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.swimming_badge}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Tools Allowed')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.tools_allowed}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border rounded-lg p-5 mt-5">
                                    <Typography
                                        sx={{
                                            color: '#FF6723',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {t('modals.Health Information')}
                                    </Typography>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Vaccination Tetanus'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.vaccination_tetanus}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Vaccination Tetanus Year'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.vaccination_tetanus_year}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Vaccination FSME')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.vaccination_fsme}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Vaccination FSME Year'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.vaccination_fsme_year}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t(
                                                    'modals.Tick Removal Allowed'
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.tick_removal_allowed}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Allergies')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.allergies}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Vegetarian')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.vegetarian}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Food Intolerance')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.food_intolerance}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Medication Allowed')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.medication_allowed}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Medication')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.medication}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5 mt-4">
                                        <div className="flex flex-col w-full">
                                            <label className="font-medium text-[14px]">
                                                {t('modals.Additional Details')}
                                            </label>
                                            <input
                                                type="text"
                                                value={`${participant.additional_details}`}
                                                className=" px-4 mt-1 py-2 w-full text-gray-500 rounded-lg bg-[#F9FAFB] outline-none"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}
