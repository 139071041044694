import { loadState } from '../utils/localstorage';

export const States = [
    {
        reducerName: 'initialReducer',
        initialState: {
            status: '',
            data: {},
        },
    },
    {
        reducerName: 'loginReducer',
        initialState: {
            status: '',
            data: {},
        },
    },
    {
        reducerName: 'keyInputReducer',
        initialState: {
            status: '',
            data: [],
        },
    },
    {
        reducerName: 'dashboardReducer',
        initialState: {
            testingUpdateData: [],
            status: '',
            participantArchiveStatus: '',
            participantArchiveSuccessData: {
                participantData: {},
                totalActiveParticipants: '',
                totalArchivedParticipants: '',
                archiveCurrentData: [],
                participantCurrentData: [],
            },
            participantDeleteStatus: '',

            participantUpdateStatus: '',
            participantUpdateData: {},

            enrollmentUpdateStatus: '',
            enrollmentUpdateData: {},

            mediaUpdateStatus: '',
            mediaUpdateData: {},

            medicalUpdateStatus: '',
            medicalUpdateData: {},

            kitchenUpdateStatus: '',
            kitchenUpdateData: {},

            participantUpdateSuccessData: {
                participantUpdateData: {},
            },
            enrollmentUpdateSuccessData: {
                enrollmentUpdateData: {},
            },
            mediaUpdateSuccessData: {
                mediaUpdateData: {},
            },
            medicalUpdateSuccessData: {
                medicalUpdateData: {},
            },
            kitchenUpdateSuccessData: {
                kitchenUpdateData: {},
            },
            participantCreateStatus: '',
            allParticipantData: [],
            allParticipantArchiveData: [],
            singleParticipantData: {},
            participantCreateData: {},
            participantUpdateData: {},
            participantDeleteData: {},
            archiveOperationInfo: {
                totalParticipants: '',
                archiveCurrentData: [],
                participantCurrentData: [],
            },
        },
    },
    {
        reducerName: 'userReducer',
        initialState: {
            status: '',
            allUserData: [],
            singleUserData: {},
            userCreateData: {},
            userUpdateData: {},
            userDeleteData: {},
        },
    },
    {
        reducerName: 'sidebarReducer',
        initialState: loadState('sidebarState') || { isOpen: true },
    },
];
