import React, { useState, useEffect } from 'react';
import i18n from '../../i8n/config';
import EngFlag from '../../images/flag-uk.png';
import DeFlag from '../../images/flag-de.png';

function LanguageSwitch({ bgColor }) {
    const [language, setLanguage] = useState(i18n.language);

    // Update the language state when i18n.language changes
    useEffect(() => {
        const handleLanguageChange = (lng) => {
            setLanguage(lng);
        };

        // Subscribe to language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup on unmount
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    // Handle language switch
    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        //set new language in localstorage::
        localStorage.setItem('language', newLanguage);
    };

    return (
        <div className="w-[80%] mt-2">
            <div className="flex gap-3 items-center">
                <div
                    className={`flex items-center cursor-pointer gap-1 px-3 py-2 rounded-lg ${
                        language === 'de'
                            ? `bg-${bgColor}`
                            : `hover:bg-${bgColor}`
                    }`}
                    onClick={() => changeLanguage('de')}
                >
                    <img
                        src={DeFlag}
                        alt="German"
                        className="w-6 h-6 rounded-full"
                    />
                    <h1 className="font-medium">DE</h1>
                </div>
                <div
                    className={`flex items-center cursor-pointer gap-1 px-3 py-2 rounded-lg ${
                        language === 'en'
                            ? `bg-${bgColor}`
                            : `hover:bg-${bgColor}`
                    }`}
                    onClick={() => changeLanguage('en')}
                >
                    <img
                        src={EngFlag}
                        alt="English"
                        className="w-6 h-6 rounded-full"
                    />
                    <h1 className="font-medium">EN</h1>
                </div>
            </div>
        </div>
    );
}

export default LanguageSwitch;
