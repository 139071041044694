import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './locales/en/translations.json';
import germanTranslations from './locales/de/translations.json';

const savedLanguage = localStorage.getItem('language') || 'de';

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: 'en',
    lng: savedLanguage,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translations: englishTranslations,
        },
        de: {
            translations: germanTranslations,
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
});

i18n.languages = ['en', 'de'];

export default i18n;
