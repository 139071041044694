import React from 'react'
import Card from '../ParticipantCard';

const PrinableParticipantCard = React.forwardRef((props, ref) => {
    const { data } = props;
  
    return (
      <div ref={ref}>
        <div className='mt-10 px-4 grid grid-cols-2 auto-rows-fr gap-6 items-center '>
          {data && data.map((cardData, index) => (
            <Card {...cardData} key={index} />
          ))}
        </div>
      </div>
    );
  });
  
  export default PrinableParticipantCard;