import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Card from '../components/cards/Users';
// import ClosedSidebar from '../components/closedSidebar'
// import OpenedSidebar from "../components/openedSidebar"
import Pagination from '../components/Pagination';
import { userActions } from '../redux-utils/actions/user-actions';
import { useAuth } from '../custom-hooks/useAuth';
import AddUserModal from '../components/modals/AddUserModal';
import ResetPasswordModal from '../components/modals/ResetPasswordModal';
import EmptyState from '../components/EmptyState';
import Loader from '../components/Loader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const { 
  allUserAction, 
  singleUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction
} = userActions;



function UserData({allUserDispatchAction, deleteUserDispatchAction, userResult}) {
  const data = userResult?.allUserData || [];

 const [isOpened, setIsOpened] = useState(true); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredParticipants, setFilteredParticipants] = useState(data);
  const [currentData, setCurrentData] = useState([]);
  const [userInfo, setUserInfo] = useState({
    updateId: "",
    name: "",
    email: "",
    username: ""
  });

  const handlePerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
  const lowercasedFilter = searchTerm.toLowerCase();
  const filteredData = data.filter(data =>
    data.name.toLowerCase().includes(lowercasedFilter) 
  );
  setFilteredParticipants(filteredData);

  setCurrentPage(1);
}, [searchTerm, data]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = filteredParticipants.slice(startIndex, endIndex);
    setCurrentData(currentPageData);
  }, [currentPage, itemsPerPage, filteredParticipants]);

  const totalItems = filteredParticipants.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  // Add user modal
  const handleAddUserModalOpen = () => {
    setAddUserModalOpen(true)
  }

  const handleAddUserModalClose = () => {
    setAddUserModalOpen(false)
  }

  // Update user modal
  const handleUpdateUserModalOpen = (userInfo) => {
    setUpdateUserModalOpen(true)
    setUserInfo({...userInfo})
  }

  const handleUpdateUserModalClose = () => {
    setUpdateUserModalOpen(false)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 500,
    bgcolor: 'background.paper',
    p: 4,
  };

  const updateStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 500,
    bgcolor: 'background.paper',
    p: 4,
  };


  useEffect(() => {

    const allParam = {
      token: loginStorage && loginStorage.loginData.data.token
    }
    allUserDispatchAction(allParam);

  }, [])


  const { getLogin } = useAuth();
  const loginStorage = JSON.parse(getLogin());

  
  if(userResult.allUserData && userResult.allUserData.length > 0) {
    return (
      <main className="bg-[#F9F9F9] font-sans flex">
        {/* {isOpened ? (
          <OpenedSidebar toggleSidebar={toggleSidebar} />
        ) : (
          <ClosedSidebar toggleSidebar={toggleSidebar} />
        )} */}
          <section className="w-full bg-[#F9F9F9] px-20 pt-10" >
              <h1 className='text-2xl text-tertiary font-medium'>Users</h1>
              <div className='w-full h-20 bg-white mt-10 rounded-lg flex items-center justify-between px-5'>
                  <div className='relative w-[30%]'>
                      <svg className='absolute right-5 top-3' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                      <path d="M14.1668 14.1667L17.5001 17.5M2.50012 9.16667C2.50012 10.9348 3.2025 12.6305 4.45274 13.8807C5.70299 15.131 7.39868 15.8333 9.16679 15.8333C10.9349 15.8333 12.6306 15.131 13.8808 13.8807C15.1311 12.6305 15.8335 10.9348 15.8335 9.16667C15.8335 7.39856 15.1311 5.70286 13.8808 4.45262C12.6306 3.20238 10.9349 2.5 9.16679 2.5C7.39868 2.5 5.70299 3.20238 4.45274 4.45262C3.2025 5.70286 2.50012 7.39856 2.50012 9.16667Z" stroke="#2F4858" stroke-opacity="0.6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <input 
                        type='text' 
                        placeholder='Search...'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className='outline-primary text-secondary bg-[#FFFFFF] border-2 border-[#EFEFEF] h-10 pl-3 rounded-full w-full' />
                  </div>
                  <button className='text-white bg-primary rounded-full w-48 font-medium h-10' onClick={handleAddUserModalOpen}>
                      Add new User
                  </button>
              </div>
              {currentData && currentData.length > 0 ? (
                <>
                  <div className='mt-10 grid grid-cols-3 auto-rows-fr gap-4 '>
                    {currentData.map((dat, index) => (
                        <Card 
                          key={index} 
                          {...dat} 
                          deleteUserDispatchAction={deleteUserDispatchAction}
                          handleUpdateUserModalOpen={handleUpdateUserModalOpen}
                        />
                    ))}
                  </div>
                  {addUserModalOpen && (
                    <AddUserModal
                      handleAddUserModalClose={handleAddUserModalClose}
                      addUserModalOpen={addUserModalOpen}
                      setAddUserModalOpen={setAddUserModalOpen}
                      style={style}
                    />
                  )}
                  {updateUserModalOpen && (
                    <ResetPasswordModal
                      handleUpdateUserModalClose={handleUpdateUserModalClose}
                      updateUserModalOpen={updateUserModalOpen}
                      setUpdateUserModalOpen={setUpdateUserModalOpen}
                      userInfo={userInfo}
                      style={updateStyle}
                    />
                  )}
                  <Pagination 
                    handlePageChange={handlePageChange}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    handlePerPageChange={handlePerPageChange}
                  />
                </>
                ) : (
                  <EmptyState />
              )}
          </section>
      </main>
    )
  }

  return (
    <>
      {/* {isOpened ? (
        <OpenedSidebar toggleSidebar={toggleSidebar} />
      ) : (
        <ClosedSidebar toggleSidebar={toggleSidebar} />
      )} */}
      <section className="w-full bg-[#F9F9F9]" >
        {(userResult.status === "" || userResult.status === "all_participant_fetch_start") ? (
          <Loader />
        ) :
        (
        <main className="bg-[#F9F9F9] font-sans flex">
        <section className="w-full bg-[#F9F9F9] px-20 pt-10" >
            <h1 className='text-2xl text-tertiary font-medium'>Users</h1>
            <div className='w-full h-20 bg-white mt-10 rounded-lg flex items-center justify-between px-5'>
                <div className='relative w-[30%]'>
                    <svg className='absolute right-5 top-3' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                    <path d="M14.1668 14.1667L17.5001 17.5M2.50012 9.16667C2.50012 10.9348 3.2025 12.6305 4.45274 13.8807C5.70299 15.131 7.39868 15.8333 9.16679 15.8333C10.9349 15.8333 12.6306 15.131 13.8808 13.8807C15.1311 12.6305 15.8335 10.9348 15.8335 9.16667C15.8335 7.39856 15.1311 5.70286 13.8808 4.45262C12.6306 3.20238 10.9349 2.5 9.16679 2.5C7.39868 2.5 5.70299 3.20238 4.45274 4.45262C3.2025 5.70286 2.50012 7.39856 2.50012 9.16667Z" stroke="#2F4858" stroke-opacity="0.6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input type='text' placeholder='Search...' className='outline-primary text-secondary bg-[#FFFFFF] border-2 border-[#EFEFEF] h-10 pl-3 rounded-full w-full' />
                </div>
                <button className='text-white bg-primary rounded-full w-48 font-medium h-10' onClick={handleAddUserModalOpen}>
                    Add new User
                </button>
            </div>
              {currentData && currentData.length > 0 ? (
                <>
                  <div className='mt-10 grid grid-cols-3 auto-rows-fr gap-4 '>
                    {currentData.map((dat, index) => (
                        <Card 
                          key={index} 
                          {...dat} 
                          deleteUserDispatchAction={deleteUserDispatchAction}
                          handleUpdateUserModalOpen={handleUpdateUserModalOpen}
                        />
                    ))}
                  </div>
                  {addUserModalOpen && (
                    <AddUserModal
                      handleAddUserModalClose={handleAddUserModalClose}
                      addUserModalOpen={addUserModalOpen}
                      setAddUserModalOpen={setAddUserModalOpen}
                      style={style}
                    />
                  )}
                  {addUserModalOpen && (
                    <ResetPasswordModal
                      handleUpdateUserModalClose={handleUpdateUserModalClose}
                      updateUserModalOpen={updateUserModalOpen}
                      setUpdateUserModalOpen={setUpdateUserModalOpen}
                      userInfo={userInfo}
                      style={updateStyle}
                    />
                  )}
                  <Pagination 
                    handlePageChange={handlePageChange}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    handlePerPageChange={handlePerPageChange}
                  />
                </>
                ) : (
                  <EmptyState />
              )}
        </section>
        </main>
        )}
      </section>
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.userResult.allUserData === nextProps.userResult.allUserData
};
const UserMemo = React.memo(UserData, areEqual)


function Users(props) {

  const { 
    allUserDispatchAction,
    singleUserDispatchAction,
    createUserDispatchAction,
    updateUserDispatchAction,
    deleteUserDispatchAction,
    userResult
   } = props;

   const ReactSwal = withReactContent(Swal);

   useEffect(() => {

    if(userResult.status === "user_delete_success") {
      ReactSwal.fire({
          title: 'Success!',
          text: 'You have succesfully removed a user',
          icon: 'success',
          confirmButtonText: 'OK'
      })
      .then(result => result.isConfirmed && window.location.assign("/users"))
    }

   }, [userResult.status])
  return (
    <>
      <UserMemo 
        allUserDispatchAction={allUserDispatchAction}
        deleteUserDispatchAction={deleteUserDispatchAction}
        userResult={userResult}
      />
    </>
  )
}

const mapStateToProps = state => ({
  userResult: state.userState,
})

const mapDispatchToProps = dispatch => ({
  allUserDispatchAction: (param) => dispatch(allUserAction(param)),
  singleUserDispatchAction: (params) => dispatch(singleUserAction(params)),
  createUserDispatchAction: (params) => dispatch(createUserAction(params)),
  updateUserDispatchAction: (params) => dispatch(updateUserAction(params)),
  deleteUserDispatchAction: (params) => dispatch(deleteUserAction(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)