import { Types as TYPES } from "../types";
import { Endpoints as ENDPOINTS } from "../endpoints";
import { useAuth as authUtil} from "../../custom-hooks/useAuth";

const actionType = TYPES.find(type => type.actionName === "loginAction");
const { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_STOP} = actionType.types;

const { baseUrl, routes } = ENDPOINTS;

const { setLogin } = authUtil();

export const loginAction = (params) => dispatch => {
    dispatch({
        type: LOGIN_START
    })

    const loginUrl = `${baseUrl}${routes.post.login}`;

    fetch(loginUrl, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(resp => resp.json())
    .then(res => {
        const loginParams = {
            isLogin: true,
            isKeyVerified: false,
            loginData: res
        }
        setLogin(loginParams);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res
        })
    })
    .catch(e => {
        dispatch({
            type: LOGIN_FAILED
        })
    })
}