export const Endpoints = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    routes: {
        get: {
            participant: '/participant',
            user: '/user',
        },
        post: {
            login: '/login',
            participant: '/participant',
            user: '/user',
        },
        update: {
            user: '/update-user',
            participant: '/participant',
        },
        delete: {
            user: '/user',
            participant: '/participant',
        },
        others: {
            archive: '/archive',
        },
    },
};
