import React from 'react';
import Login from './login';
import Dashboard from './dashboard';
import KeyInput from './key-input';
import Registration from './registration';
import Users from './users';
import DashboardPages from './dashboard/';

export default function Pages(props) {

    return {
        Login,
        KeyInput,
        Dashboard,
        Registration,
        Users,
        DashboardPages
    }
}