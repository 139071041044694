import { Types as TYPES } from "../types";
import { States as STATES } from "../states";

const actionType = TYPES.find(type => type.actionName === "userAction");
const { 
    ALL_USER_FETCH_START, 
    ALL_USER_FETCH_SUCCESS, 
    ALL_USER_FETCH_FAILED, 
    ALL_USER_FETCH_STOP,

    SINGLE_USER_FETCH_START, 
    SINGLE_USER_FETCH_SUCCESS, 
    SINGLE_USER_FETCH_FAILED, 
    SINGLE_USER_FETCH_STOP,

    USER_CREATE_START, 
    USER_CREATE_SUCCESS, 
    USER_CREATE_FAILED, 
    USER_CREATE_STOP,

    USER_UPDATE_START, 
    USER_UPDATE_SUCCESS, 
    USER_UPDATE_FAILED, 
    USER_UPDATE_STOP,

    USER_DELETE_START, 
    USER_DELETE_SUCCESS, 
    USER_DELETE_FAILED, 
    USER_DELETE_STOP
} = actionType.types;

const reducerState = STATES.find(state => state.reducerName === "userReducer");
const { initialState } = reducerState;

export default function userReducer(state = initialState, action) {

    switch(action.type) {
        case ALL_USER_FETCH_START:
            return {
                ...state,
                status: "all_user_fetch_start"
            }
        case ALL_USER_FETCH_SUCCESS:
            return {
                ...state,
                status: "all_user_fetch_success",
                allUserData: action.payload
            }
        case ALL_USER_FETCH_FAILED:
            return {
                ...state,
                status: "all_user_fetch_failed"
            }
        case SINGLE_USER_FETCH_START:
            return {
                ...state,
                status: "single_user_fetch_start"
            }
        case SINGLE_USER_FETCH_SUCCESS:
            return {
                ...state,
                status: "single_user_fetch_success",
                singleUserData: action.payload
            }
        case SINGLE_USER_FETCH_FAILED:
            return {
                ...state,
                status: "single_user_fetch_failed"
            }
        case USER_CREATE_START:
            return {
                ...state,
                status: "user_create_start"
            }
        case USER_CREATE_SUCCESS:
            return {
                ...state,
                status: "user_create_success",
                userCreateData: action.payload
            }
        case USER_CREATE_FAILED:
            return {
                ...state,
                status: "user_create_failed"
            }
        case USER_UPDATE_START:
            return {
                ...state,
                status: "user_update_start"
            }
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                status: "user_update_success",
                userUpdateData: action.payload
            }
        case USER_CREATE_FAILED:
            return {
                ...state,
                status: "user_create_failed"
            }
        case USER_DELETE_START:
            return {
                ...state,
                status: "user_delete_start"
            }
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                status: "user_delete_success",
                userDeleteData: action.payload
            }
        case USER_DELETE_FAILED:
            return {
                ...state,
                status: "user_delete_failed"
            }
        default:
            return {
                ...state
            }
    }
}