import React from 'react';
import Card from '../MedicalCard';

const PrintableMedicalCard = React.forwardRef((props, ref) => {
  const { data } = props;

  return (
    <div ref={ref}>
      <div className='mt-10 px-4 grid grid-cols-1 auto-rows-fr gap-6 '>
        {data.map((cardData, index) => (
          <Card {...cardData} key={index} />
        ))}
      </div>
    </div>
  );
});

export default PrintableMedicalCard;
