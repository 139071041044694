import React from 'react'
import { FadeLoader } from 'react-spinners'

function Loader(props) {

  const { componentType } = props;

  if(componentType === "edit-participant-modal") {
    return (
      <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' style={{borderRadius: "20px"}}>
        <FadeLoader color={"#FDB315"} loading={true} size={100} />
      </div>
    )
  }

  if (componentType === "archive_participant_modal") {
    return (
      <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
        <FadeLoader color={"#FDB315"} loading={true} size={150} />
      </div>
    );
  }
  
  return (
    <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <FadeLoader color={"#FDB315"} loading={true} size={150} />
    </div>
  )
}

export default Loader
